import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const EditButton = styled.button`
	${OptionsButton}
	border: 1px solid #929292;
	background: #ffffff;
	width: 20rem;
	border-radius: 5rem;
	color: #393939;
`;

export const CancelOption = styled.button`
	${OptionsButton}
	background-color: var(--white);
	align-self: flex-end;
	width: 25rem;
	margin-top: 2rem;
	height: 4.6rem;
	border-radius: 5rem;
	padding: 12px 24px 12px 24px;
	gap: 10px;
	border: 1px solid #929292;
	color: #393939;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 80rem;
	height: 52rem;
	gap: 1rem;
	border-top: 0.2rem solid var(--light-gray);
	overflow: hidden;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10rem;
`;

export const ButtonModal = styled.button`
	width: 25rem;
	height: 4.5rem;
	padding: 12px 32px 12px 32px;
	gap: 12px;
	border-radius: 50rem;
	opacity: 0px;
	border: 1px solid #191919;
	background: #fff;
	color: #393939;
	font-weight: 600;
`;
