import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const TogglePartnerStatusBtn = styled.button`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: var(--primary-blue);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	margin: 0 auto;
	& > svg {
		color: var(--white);
		width: 100%;
		height: 100%;
	}
`;

export const PartnerStatsLink = styled(Link)`
	${OptionsButton}
	font-size: 1.4rem;
`;
