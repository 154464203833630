import Loader from '../../../../../components/Loader';
import { FilterParams } from '../../../../../components/Filter';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
	FundsOutListReturn,
	getFundsOutToReport,
} from '../../../../../services/queries/FundsOut';
import { SortColumn } from '../../../../../hooks/useSortColumnHook';
import {
	Document,
	Page,
	Text,
	View,
	Font,
	Image,
	pdf,
} from '@react-pdf/renderer';
import { fundsOutReportStyle } from './styles';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import BountyLogo from '../../../../../assets/reports/FlexibleBenefitsLogo.png';
import ReportIcon from '../../../../../assets/reports/ReportIcon.png';
import { parseFundsOutStatus } from '../utils/parseFundsOutData';
import * as S from '../styles';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

interface ReportProps {
	filterParams: FilterParams[];
	sortColumn: SortColumn | null;
}

export function ReportGeneratorFundsOutPDF({
	filterParams,
	sortColumn,
}: ReportProps) {
	const fetchFundsOutToReportPDFQuery = useQuery<FundsOutListReturn>(
		['FundsoutListToReportPDF', filterParams],
		() => {
			return getFundsOutToReport(filterParams, sortColumn);
		},
		{
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'relatorio_de_teds.pdf';

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchFundsOutToReportPDFQuery.refetch();
		const { fundsOut, total_fundsOut, total_reports } = reportFetching.data!;

		let dateMessage = 'Todo o período';

		const dateFilter = filterParams.find(
			(filter) => filter.name === 'Intervalo de datas' && filter.selected
		);

		if (dateFilter) {
			const formattedStartDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.value.replace(/-/g, '/')));

			const formattedEndDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.second_value!.replace(/-/g, '/')));

			dateMessage = `${formattedStartDate} - ${formattedEndDate}`;
		}

		return (
			<Document>
				<Page
					size='A4'
					style={fundsOutReportStyle.page}
					orientation='landscape'
				>
					<View style={fundsOutReportStyle.header} fixed>
						<Image src={BountyLogo} style={fundsOutReportStyle.logo} />
						<View style={fundsOutReportStyle.headerInfo}>
							<Text style={fundsOutReportStyle.headerInfoLabel}>
								N° Página:
							</Text>
							<Text
								style={fundsOutReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={fundsOutReportStyle.headerInfo}>
							<Text style={fundsOutReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={fundsOutReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								}).format(new Date())}
							</Text>
						</View>
						<View style={{ ...fundsOutReportStyle.headerInfo, width: '120%' }}>
							<Text style={fundsOutReportStyle.headerInfoLabel}>
								Intervalo de busca:
							</Text>
							<Text style={fundsOutReportStyle.headerInfoData}>
								{dateMessage}
							</Text>
						</View>
						<View style={{ ...fundsOutReportStyle.headerInfo, width: '60%' }}>
							<Text style={fundsOutReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={fundsOutReportStyle.headerInfoData}>
								{String(total_reports).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={fundsOutReportStyle.subHeader}>
						<View style={fundsOutReportStyle.titleContainer}>
							<Image src={ReportIcon} style={fundsOutReportStyle.reportLogo} />
							<View style={fundsOutReportStyle.reportTitleContainer}>
								<Text
									style={{
										...fundsOutReportStyle.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={fundsOutReportStyle.reportTitle}>TEDs</Text>
							</View>
						</View>

						<View style={fundsOutReportStyle.subheaderDataContainer}>
							<View style={{ ...fundsOutReportStyle.subheaderInfo }}>
								<Text style={fundsOutReportStyle.subheaderLabel}>
									Total de registros:
								</Text>
								<Text style={fundsOutReportStyle.subheaderData}>
									{total_fundsOut}
								</Text>
							</View>
						</View>
					</View>
					<View style={fundsOutReportStyle.tableContainer}>
						<View style={fundsOutReportStyle.tableHeader}>
							<View
								style={{
									...fundsOutReportStyle.columnTitleContainer,
									width: '20%',
								}}
							>
								<Text style={fundsOutReportStyle.columnTitle}>Nº</Text>
							</View>

							<View
								style={{
									...fundsOutReportStyle.columnTitleContainer,
									width: '80%',
								}}
							>
								<Text style={fundsOutReportStyle.columnTitle}>Data</Text>
							</View>

							<View
								style={{
									...fundsOutReportStyle.columnTitleContainer,
									width: '170%',
								}}
							>
								<Text style={fundsOutReportStyle.columnTitle}>Empresa</Text>
							</View>

							<View style={fundsOutReportStyle.columnTitleContainer}>
								<Text style={fundsOutReportStyle.columnTitle}>DESCRIÇÃO</Text>
							</View>

							<View style={fundsOutReportStyle.columnTitleContainer}>
								<Text style={fundsOutReportStyle.columnTitle}>
									BENEFICIÁRIO
								</Text>
							</View>

							<View style={fundsOutReportStyle.columnTitleContainer}>
								<Text style={fundsOutReportStyle.columnTitle}>BANCO</Text>
							</View>

							<View
								style={{
									...fundsOutReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={fundsOutReportStyle.columnTitle}>AGÊNCIA</Text>
							</View>

							<View style={fundsOutReportStyle.columnTitleContainer}>
								<Text style={fundsOutReportStyle.columnTitle}>CONTA</Text>
							</View>
							<View
								style={{
									...fundsOutReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={fundsOutReportStyle.columnTitle}>Valor</Text>
							</View>

							<View
								style={{
									...fundsOutReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={fundsOutReportStyle.columnTitle}>Status</Text>
							</View>
						</View>

						{fundsOut.map((fundOut, index) => (
							<View
								style={fundsOutReportStyle.tableRow}
								key={fundOut.id}
								wrap={false}
							>
								<Text
									style={{
										...fundsOutReportStyle.columnData,
										width: '20%',
									}}
								>
									{index + 1}
								</Text>

								<Text
									style={{
										...fundsOutReportStyle.columnData,
										width: '80%',
									}}
								>
									{new Date(fundOut.created_at).toLocaleDateString()}
								</Text>

								<Text
									style={{ ...fundsOutReportStyle.columnData, width: '170%' }}
								>
									{fundOut.company?.name ?? 'N/A'}
								</Text>

								<Text style={fundsOutReportStyle.columnData}>
									{fundOut.description}
								</Text>

								<Text style={fundsOutReportStyle.columnData}>
									{fundOut.beneficiary.name}
								</Text>
								<Text style={fundsOutReportStyle.columnData}>
									{fundOut.beneficiary.bank_code} -{' '}
									{fundOut.beneficiary.bank_name}
								</Text>
								<Text
									style={{ ...fundsOutReportStyle.columnData, width: '70%' }}
								>
									{fundOut.beneficiary.branch}
								</Text>
								<Text style={fundsOutReportStyle.columnData}>
									{fundOut.beneficiary.account_number}
								</Text>

								<Text
									style={{
										...fundsOutReportStyle.columnData,
										fontWeight: 600,
										width: '70%',
									}}
								>
									{convertCentsToReais(Number(fundOut.amount))}
								</Text>

								<Text
									style={{
										...fundsOutReportStyle.columnData,
										fontWeight: 600,
										width: '70%',
									}}
								>
									{parseFundsOutStatus(fundOut.status)}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<S.ReportButton onClick={handleGeneratePDF}>
				{fetchFundsOutToReportPDFQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório'
				)}
			</S.ReportButton>
		</>
	);
}
