import styled from 'styled-components';
import { PreRegisterTimeline, Register } from '../../../../@types';
import { formatDateWithHours } from '../../../../utils/parseDate';
import { preRegisterStatusToText } from '../../../../utils/preRegister';
import { OptionsButton } from '../../../../components/Button';
import { MdArrowBackIos } from "react-icons/md";
import { HiOutlineInformationCircle } from 'react-icons/hi';

export default function History({ register, timeline, isVisible, setIsVisible }: {
	register?: Register,
	isVisible: boolean,
	setIsVisible: (value: boolean) => void,
	timeline?: PreRegisterTimeline[],
}) {

	return <Container style={{ display: isVisible ? 'block' : 'none' }}>
		<div className={'header'}>
			<div style={{display: "flex", alignItems: "center"}}>
				<a onClick={() => setIsVisible(false)}>
					<MdArrowBackIos size={25} color={'white'} />
				</a>
				<h2>Timeline - {register?.legal_name}</h2>
			</div>
			<p>
				<span style={{ fontSize: '1.3rem' }}>Última modificação em:</span>
				<br />
				<span style={{ fontSize: '1.3rem' }}>{formatDateWithHours((timeline && timeline.length >= 1 )? timeline[0].created_at : register?.created_at ?? "")}</span>
			</p>
		</div>
		<div className={'timeline'}>
			{
				timeline?.map((item, index) => {
					const status = preRegisterStatusToText(item.status_log);
					return (
						<ItemWrapper key={item.id} lastItem={index === timeline.length - 1}>
							<div className={'dot'} />
							<div className={'item-content'}>
								<div>
									<h2>
										Etapa {status.step} - &nbsp;
										{status.message}
									</h2>
									<br />
									<h3>
										{formatDateWithHours(item.created_at)}
										<HiOutlineInformationCircle size={15} style={{ marginLeft: "0.5rem" }} data-rh={status.description} />
									</h3>
								</div>
							</div>
						</ItemWrapper>
					);
					},
				)
			}
			<ItemWrapper lastItem={true}>
				<div className={'dot'} />
				<div className={'item-content'}>
					<div>
						<h2>Etapa 1 - Solicitação de pré registro criada</h2>
						<br />
						<h3>{formatDateWithHours(register?.created_at ?? "")}</h3>
					</div>
				</div>
			</ItemWrapper>
		</div>

	</Container>;
}


const Container = styled.div`
    max-width: 33rem;

    .header {
        background: var(--primary-blue);
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        color: white;
        gap: 2rem;
        margin-top: 4rem;
    }

    .timeline {
        padding-left: 2rem;
        padding-right: 0.5rem;
        max-height: 60rem;
        overflow: auto;

        .dot {
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
            min-height: 3rem;
            background: #D1D6E6;
            border-radius: 2rem;
            transform: translateX(-15px);
        }

        .item-content {
            padding: 2rem;
            margin: 1rem 1rem 1rem 0;
            display: flex;
            gap: 1rem;
            border-radius: 2rem;
            -webkit-box-shadow: 7px 10px 28px 1px rgba(224, 221, 224, 1);
            -moz-box-shadow: 7px 10px 28px 1px rgba(224, 221, 224, 1);
            box-shadow: 7px 10px 28px 1px rgba(224, 221, 224, 1);
            width: 100%;
            height: 12rem;
            align-items: center;
        }
    }
`;

export const CloseModalButton = styled.button`
    ${OptionsButton}
    padding: 1.2rem 3rem;
    background-color: var(--white);
    color: var(--dark-gray);
    border: 0.2rem solid var(--dark-gray);
`;

const ItemWrapper = styled.div<{ lastItem: boolean }>`
    border-left: #D1D6E6 4px solid;
    min-height: 7.5rem;
    align-items: center;
    display: flex;
`;
