import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	height: 30rem;
	border-radius: 2rem;
	padding: 4rem;
	padding-top: 1rem;
	gap: 1rem;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 6rem;
	height: 6rem;
	background-color: #ffebf0;
	border-radius: 50px;
`;

export const SubContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 17rem;
`;

export const FieldGroup = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const Label = styled.label`
	font-family: 'Poppins', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.4rem;
	text-align: center;
`;

export const Title = styled.h1`
	font-family: 'Poppins', sans-serif;
	font-size: 2.4rem;
	font-weight: 600;
	line-height: 3rem;
	text-align: center;
`;

export const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	background-color: var(--white);
	padding-top: 2rem;
`;

export const CardInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	& div:last-child {
		align-items: flex-end;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	/* column-gap: 1rem; */
`;

export const CancelOption = styled.button`
	${OptionsButton}
	border: 1px solid #929292;
	background: #ffffff;
	width: 16rem;
	border-radius: 5rem;
	color: #393939;
	text-align: center;
`;
export const TransactionButton = styled.button`
	${OptionsButton}
	width: 16rem;
	margin-right: 0;
	margin-left: auto;
	border-radius: 5rem;
	background: #ff0037;
	border: 1px solid #929292;
	text-align: center;
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	align-self: flex-start;
	width: 16rem;
`;
