import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	overflow: auto;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-left: 6rem;
		margin-right: 1rem;
	}
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
`;
export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	margin-bottom: 3rem;
	max-width: 80%;
	width: fit-content;
	overflow: auto;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const BeneficiaryTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--dark-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const DetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;

export const BeneficiaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;
