import { FormEvent, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import {
	cnpjMask,
	cpfMask,
	parseMaskedCPFToRaw,
	parseMaskedCnpjToRaw,
} from '../../utils/masks';
import {
	getMaxMonthValue,
	getMinMonthValue,
	parseInputToDateMMDD,
} from '../../utils/parseDate';
import * as FormStyles from '../Form/FormStyles';
import * as S from './styles';
import { convertReaisToCents } from '../../utils/CurrencyConvert';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { getCompaniesSelect } from '../../services/queries/Companies';
import { getGroupCompaniesSelect } from '../../services/queries/GroupCompany';
import { getLastDayOfMonth } from './utils/getLastDayOfMonth';
import { toast } from 'react-toastify';

export interface SelectOption {
	value: string;
	text: string;
}

export type FilterParams = {
	filter: string;
	name: string;
	value: string;
	second_value?: string;
	type:
		| 'text'
		| 'cpf'
		| 'cnpj'
		| 'document'
		| 'currency'
		| 'date_interval'
		| 'month_interval'
		| 'email'
		| 'select'
		| 'company'
		| 'company_id'
		| 'group_company'
		| 'group_company_id';
	selectOptions?: SelectOption[]; // used when type === 'select'
	selected: boolean;
	alwaysFilled?: boolean;
};

interface FilterProps {
	filterParams: FilterParams[];
	onFiltersChanged: (updatedFilters: FilterParams[]) => void;
}

export function parseFilterParamsToParams(filterParams: FilterParams[]) {
	const params: any = {};
	filterParams
		.filter((f) => !!f.value)
		.forEach((filter) => {
			if (filter.type === 'currency') {
				const value = Number(filter.value.replace(',', '.'));
				params[filter.filter] = convertReaisToCents(value);
			} else if (filter.type === 'date_interval') {
				params['start_date'] = parseInputToDateMMDD(filter.value);
				params['end_date'] = parseInputToDateMMDD(filter.second_value!);
			} else if (filter.type === 'month_interval') {
				const firstDayOfStartMonth = '01';
				const lastDayOfEndDateMonth = getLastDayOfMonth(filter.second_value!);

				params['start_date'] = parseInputToDateMMDD(
					filter.value + '-' + firstDayOfStartMonth // passing full dates like: yyyy-mm-dd
				);
				params['end_date'] = parseInputToDateMMDD(
					filter.second_value! + '-' + lastDayOfEndDateMonth // passing full dates like: yyyy-mm-dd
				);
			} else {
				params[filter.filter] = filter.value;
			}
		});
	return params;
}

export function Filter({ filterParams, onFiltersChanged }: FilterProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [filters, setFilters] = useState(filterParams);

	const fetchCompaniesQuery = useQuery(
		['companiesSelect'],
		() => {
			return getCompaniesSelect();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar as empresas. '
				);
			},
			enabled: !!filterParams.find(
				(f) => f.type === 'company' || f.type === 'company_id'
			),
			refetchOnWindowFocus: false,
		}
	);

	const fetchGroupCompaniesQuery = useQuery(
		['grouCompaniesSelect'],
		() => {
			return getGroupCompaniesSelect();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os grupos de empresas. '
				);
			},
			enabled: !!filterParams.find(
				(f) => f.type === 'group_company' || f.type === 'group_company_id'
			),
			refetchOnWindowFocus: false,
		}
	);

	function toggleFilterSelection(filter: string) {
		setFilters(
			filters.map((f) => {
				if (f.alwaysFilled) return f;

				if (f.filter === filter) {
					if (f.type === 'date_interval') {
						return { ...f, selected: !f.selected, value: '', second_value: '' };
					}
					return { ...f, selected: !f.selected, value: '' };
				}

				return f;
			})
		);
	}

	function handleFilterChangeValue(
		filter: string,
		value: string,
		start_date?: boolean
	) {
		setFilters(
			filters.map((f) => {
				if (f.filter === filter) {
					if (f.type === 'date_interval' || f.type === 'month_interval') {
						if (start_date) return { ...f, value };
						return { ...f, second_value: value };
					}
					return { ...f, value };
				}
				return f;
			})
		);
	}

	function validateDateInterval(startDate?: string, endDate?: string) {
		if (!startDate || !endDate) return true;

		return new Date(startDate) <= new Date(endDate);
	}

	function renderProperInput(filter: FilterParams) {
		if (filter.type === 'select') {
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione um tipo
					</option>
					{filter.selectOptions?.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
				</FormStyles.SelectInput>
			);
		}

		if (filter.type === 'currency')
			return (
				<FormStyles.FormCurrencyInput
					value={filter.value}
					onValueChange={({ value }) =>
						handleFilterChangeValue(filter.filter, value)
					}
				/>
			);

		if (filter.type === 'cpf')
			return (
				<FormStyles.SearchInput
					value={cpfMask(filter.value)}
					onChange={(e) => {
						const { value } = e.target;
						handleFilterChangeValue(filter.filter, parseMaskedCPFToRaw(value));
					}}
					placeholder={`${filter.name.toUpperCase()}`}
				/>
			);

		if (filter.type === 'cnpj')
			return (
				<FormStyles.SearchInput
					value={cnpjMask(filter.value)}
					onChange={(e) => {
						const { value } = e.target;
						handleFilterChangeValue(filter.filter, parseMaskedCnpjToRaw(value));
					}}
					placeholder={`${filter.name.toUpperCase()}`}
				/>
			);

		if (filter.type === 'document')
			return (
				<FormStyles.SearchInput
					value={
						filter.value.length <= 11
							? cpfMask(filter.value)
							: cnpjMask(filter.value)
					}
					onChange={(e) => {
						const { value } = e.target;
						const rawValue =
							value.length <= 11
								? parseMaskedCPFToRaw(value)
								: parseMaskedCnpjToRaw(value);

						handleFilterChangeValue(filter.filter, rawValue);
					}}
					placeholder={`${filter.name}`}
				/>
			);

		if (filter.type === 'date_interval')
			return (
				<div style={{ marginTop: '5px' }}>
					<S.InputLabel>Data inicial</S.InputLabel>
					<FormStyles.Input
						type={'date'}
						value={filter.value}
						onChange={(e) => {
							if (!validateDateInterval(e.target.value, filter.second_value)) {
								toast.error('Data inicial não pode ser após a data final!');
								return;
							}

							handleFilterChangeValue(filter.filter, e.target.value, true);
						}}
						data-testid='start_date_input'
						required
					/>
					<div style={{ marginTop: '3px' }}></div>
					<S.InputLabel>Data final</S.InputLabel>
					<FormStyles.Input
						type={'date'}
						value={filter.second_value}
						onChange={(e) => {
							if (!validateDateInterval(filter.value, e.target.value)) {
								toast.error('Data final não pode ser anterior a data inicial!');
								return;
							}

							handleFilterChangeValue(filter.filter, e.target.value, false);
						}}
						data-testid='end_date_input'
						required
					/>
				</div>
			);

		if (filter.type === 'month_interval')
			return (
				<div style={{ marginTop: '5px' }}>
					<S.InputLabel>Mês inicial</S.InputLabel>
					<FormStyles.Input
						type={'month'}
						min={getMinMonthValue()}
						max={getMaxMonthValue()}
						value={filter.value}
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, true);
						}}
						data-testid='start_month_input'
						required
					/>
					<div style={{ marginTop: '3px' }}></div>
					<S.InputLabel>Mês final</S.InputLabel>
					<FormStyles.Input
						type={'month'}
						min={getMinMonthValue()}
						max={getMaxMonthValue()}
						value={filter.second_value}
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, false);
						}}
						data-testid='end_month_input'
						required
					/>
				</div>
			);

		if (filter.type === 'company' || filter.type === 'company_id')
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione uma empresa
					</option>
					{fetchCompaniesQuery.data?.map((company) => (
						<option
							key={company.id}
							value={filter.type === 'company' ? company.name : company.id}
						>
							{company.name}
						</option>
					))}
				</FormStyles.SelectInput>
			);

		if (filter.type === 'group_company' || filter.type === 'group_company_id')
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione um grupo corporativo
					</option>
					{fetchGroupCompaniesQuery.data?.map((group_company) => (
						<option
							key={group_company.id}
							value={
								filter.type === 'group_company'
									? group_company.name
									: group_company.id
							}
						>
							{group_company.name}
						</option>
					))}
				</FormStyles.SelectInput>
			);

		return (
			<FormStyles.SearchInput
				value={filter.value}
				onChange={(e) => {
					handleFilterChangeValue(filter.filter, e.target.value);
				}}
				placeholder={filter.name}
				// those params change based on filter.type as well
				type={filter.type === 'email' ? 'email' : 'text'}
			/>
		);
	}

	function handleClearFilters() {
		const clearedFilters = filters.map((f) => {
			if (f.alwaysFilled) return f;

			return { ...f, selected: false, value: '' };
		});
		setFilters(clearedFilters);
		onFiltersChanged(clearedFilters);
	}

	function handleSubmitFilter(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		onFiltersChanged(filters);
	}

	return (
		<Popover
			isOpen={isOpen}
			onClickOutside={() => setIsOpen(false)}
			positions={['right', 'bottom', 'top', 'left']} // preferred positions by priority
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={'var(--light-gray)'}
					arrowSize={6}
					arrowStyle={{ opacity: 0.7 }}
				>
					<S.Container>
						<S.Section>
							<S.SectionTitle>Adicionar filtros</S.SectionTitle>
							<S.FiltersContainer>
								{filters.map((filter) => (
									<S.SelectFilter
										onClick={() => toggleFilterSelection(filter.filter)}
										selected={filter.selected}
										key={filter.filter}
									>
										{filter.name}
									</S.SelectFilter>
								))}
							</S.FiltersContainer>
						</S.Section>

						<form onSubmit={(e) => handleSubmitFilter(e)}>
							<S.Section>
								<S.SectionTitle>Filtros</S.SectionTitle>

								<S.FiltersInputContainer>
									{filters
										.filter((f) => f.selected)
										.map((filter) => (
											<div
												key={filter.filter}
												style={{ display: 'flex', flexDirection: 'column' }}
											>
												<S.InputLabel>{filter.name}</S.InputLabel>
												{renderProperInput(filter)}
											</div>
										))}
								</S.FiltersInputContainer>
							</S.Section>

							<S.FilterButton type='submit'>FILTRAR</S.FilterButton>
						</form>
					</S.Container>
				</ArrowContainer>
			)}
		>
			<S.OpenFilterPopoverContainer>
				<S.OpenFilterButton
					onClick={() => setIsOpen(!isOpen)}
					data-testid='Filter-container-popover'
				>
					<FaFilter />
					<span>Filtros</span>
				</S.OpenFilterButton>
				{!!filterParams.filter((f) => !!f.value).length && (
					<S.FilterIndicator
						onClick={handleClearFilters}
						data-rh='Limpar filtros'
						data-testid='clear-filters-btn'
					>
						<span>{filterParams.filter((f) => !!f.value).length}</span>
						<RiCloseLine />
					</S.FilterIndicator>
				)}
			</S.OpenFilterPopoverContainer>
		</Popover>
	);
}
