import { useEffect, useState } from 'react';
import * as S from './styles';
import * as Styles from '../../CreateDispute/styles';
import Modal from '../../../../../../components/Modal';
import PageTitle from '../../../../../../components/PageTitle';
import { SelectCardModal } from '../../CreateDispute/SelectCardModal';
import { ObrigatoryIndicator } from '../../../../../../components/ObrigatoryFieldsIndicator';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LocalProductOrigin } from '../../CreateDispute';
import { maskCardNumber } from '../../../../../../utils/masks';
import { useMutation } from 'react-query';
import { updateDispute } from '../../../../../../services/queries/Disputes';
import {
	convertCentsToReais,
	convertReaisToCents,
} from '../../../../../../utils/CurrencyConvert';
import { useNavigate } from 'react-router-dom';
import { ConfirmationSwapRequiredModal } from '../../CreateDispute/ConfirmationSwapRequiredModal';
import ForbiddenModal from '../../ForbiddenModal';
import Loader from '../../../../../../components/Loader';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';

interface DisputeUpdateProps {
	id: string;
	amount: number;
	product: LocalProductOrigin;
	start_date_dispute: string;
	card: {
		id: string;
		pan: string;
	};
	dispute_description: string;
	transaction_id: string;
	company_id: string;
	dispute_value: number | null;
	swap_required: boolean | undefined;
	availableProducts: {
		product_flexible_benefits_enabled: boolean;
		product_corporate_expenses_enabled: boolean;
	};
}

interface CardTransactionDisputeUpdateDTO {
	product: LocalProductOrigin;
	card_id: string;
	amount: number;
	start_date_dispute: string;
	dispute_description: string;
	transaction_id: string;
	swap_required: boolean | undefined;
}

export default function UpdateDisputeModal({
	id,
	amount,
	product,
	start_date_dispute,
	card,
	dispute_description,
	transaction_id,
	swap_required,
	company_id,
	dispute_value,
	availableProducts,
}: DisputeUpdateProps) {
	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [value, setInputValue] = useState(amount / 100);
	const [selectedProduct, setSelectedProduct] =
		useState<LocalProductOrigin>(product);
	const [showForbiddenModal, setShowForbiddenModal] = useState(false);
	const navigate = useNavigate();

	const products = Object.entries(availableProducts)
		.filter(([_, isEnabled]) => isEnabled)
		.map(([key]) =>
			key === 'product_flexible_benefits_enabled' ? 'multiflex' : 'corpway'
		);

	const [isSelectCardModalOpen, setSelectCardModalOpen] = useState(false);
	const [confirmationSwapRequiredModal, setConfirmationSwapRequiredModal] =
		useState(false);

	const [selectedCard, setSelectedCard] = useState<{
		id: string;
		pan: string;
	} | null>(card);

	const { register, setValue, handleSubmit, watch, getValues, reset } =
		useForm<CardTransactionDisputeUpdateDTO>({
			defaultValues: {
				product: product,
				card_id: card.id || '',
				amount: amount,
				start_date_dispute: start_date_dispute
					? formatDateToInput(start_date_dispute)
					: '',
				dispute_description: dispute_description,
				transaction_id: transaction_id || '',
				swap_required: swap_required,
			},
		});

	const handleCloseModal = () => {
		reset({
			product: product,
			card_id: card.id || '',
			amount: amount,
			start_date_dispute: start_date_dispute
				? formatDateToInput(start_date_dispute)
				: '',
			dispute_description: dispute_description,
			transaction_id: transaction_id || '',
			swap_required: swap_required,
		});
		setConfirmationSwapRequiredModal(false);
		setUpdateModalOpen(false);
		setSelectedProduct(product);
		setSelectedCard(card);
		setInputValue(amount / 100);
	};

	const swapRequired = watch('swap_required');

	const onSelectCard = (card: { id: string; pan: string }) => {
		setValue('card_id', card.id);
		setSelectedCard(card);
		toast.success(`Cartão com PAN ****${card.pan} selecionado com sucesso!`);
	};

	useEffect(() => {
		if (selectedProduct && selectedProduct !== product) {
			setValue('product', selectedProduct);
		}
	}, [selectedProduct, product, setValue]);

	useEffect(() => {
		if (value !== amount) {
			setValue('amount', value);
		}
	}, [value, amount, setValue]);

	useEffect(() => {
		if (selectedProduct !== product) {
			setSelectedCard(null);
			setValue('card_id', '');
		}
	}, [selectedProduct, product, setValue]);

	function formatDateToDDMMYYYY(date: string): string {
		const [year, month, day] = date.split('-');
		return `${day}/${month}/${year}`;
	}

	function formatDateToInput(date: string): string {
		const parts = date.split('/');
		const [year, month, day] = parts;
		return `${day}-${month}-${year}`;
	}

	const mutation = useMutation(updateDispute, {
		onSuccess: () => {
			toast.success('Chargeback atualizada com sucesso!');
			navigate(0);
		},
		onError: (error: any) => {
			showErrorMessage(error as Error, `Erro ao criar chargeback. `);
		},
	});

	const onSubmit = (data: CardTransactionDisputeUpdateDTO) => {
		if (swapRequired === false && data.amount > 35) {
			setConfirmationSwapRequiredModal(true);
		} else if (
			dispute_value !== null &&
			Number(convertReaisToCents(data.amount)) > Number(dispute_value)
		) {
			setShowForbiddenModal(true);
		} else {
			submitForm(data);
		}
	};

	const submitForm = (data: CardTransactionDisputeUpdateDTO) => {
		if (data.swap_required === true && !data.transaction_id) {
			toast.error('Por favor, insira o ID da transação.');
			return;
		}

		const disputeData = {
			id: id,
			product: data.product,
			amount: convertReaisToCents(data.amount),
			dispute_description: data.dispute_description,
			card_id: data.card_id || null,
			start_date_dispute: data.start_date_dispute
				? formatDateToDDMMYYYY(data.start_date_dispute)
				: null,
			transaction_id: data.transaction_id || null,
			swap_required: swapRequired,
		};

		mutation.mutate(disputeData);
	};

	return (
		<>
			<S.EditButton onClick={() => setUpdateModalOpen(true)}>
				Editar
			</S.EditButton>

			<Modal
				isOpen={isUpdateModalOpen}
				onRequestClose={handleCloseModal}
				enableClose={false}
				key={Date.now()}
			>
				<PageTitle title='Editar chargeback' />
				<S.Container>
					<Styles.Form
						onSubmit={handleSubmit(onSubmit)}
						style={{ borderTop: 'none' }}
					>
						<Styles.FormContainer>
							<Styles.FieldSet>
								<Styles.FieldGroup>
									<Styles.Field>
										<Styles.Label htmlFor='product'>
											Produto
											<ObrigatoryIndicator />
										</Styles.Label>
										<Styles.SelectInput
											id='product'
											value={selectedProduct}
											{...register('product')}
											onChange={(e) =>
												setSelectedProduct(e.target.value as LocalProductOrigin)
											}
										>
											{products.map((prod) => (
												<option key={prod} value={prod}>
													{prod === 'multiflex'
														? 'Benefícios Flexíveis'
														: 'Bounty Control'}
												</option>
											))}
										</Styles.SelectInput>
									</Styles.Field>

									<Styles.Field
										style={{
											marginLeft: '19rem',
										}}
									>
										<Styles.Label>Cartão</Styles.Label>
										<S.ButtonModal onClick={() => setSelectCardModalOpen(true)}>
											{selectedCard
												? `${maskCardNumber(selectedCard.pan)}`
												: 'Selecionar cartão'}
										</S.ButtonModal>
										{isSelectCardModalOpen && (
											<SelectCardModal
												product={selectedProduct}
												onSelectCard={onSelectCard}
												selectedCard={selectedCard}
												company_id={company_id}
												isOpenModal={isSelectCardModalOpen}
												onClose={() => setSelectCardModalOpen(false)}
											/>
										)}
									</Styles.Field>
								</Styles.FieldGroup>

								<Styles.FieldGroup
									style={{
										maxWidth: '100%',
										gap: '9rem',
									}}
								>
									<Styles.Field style={{ width: '50%' }}>
										<Styles.Label>
											Descrição do chargeback <ObrigatoryIndicator />
										</Styles.Label>
										<Styles.InputTextArea
											{...register('dispute_description')}
											name='dispute_description'
											required
										/>
									</Styles.Field>
									<div
										style={{
											flexDirection: 'column',
											width: '50%',
											padding: 0,
										}}
									>
										<Styles.Field
											style={{ width: '70%', margin: '0 0.5rem 1.2rem 0.5rem' }}
										>
											<Styles.Label htmlFor='swap_required'>
												Vale a disputa
												<ObrigatoryIndicator />
											</Styles.Label>
											<Styles.SelectInput
												id='swap_required'
												value={
													swapRequired === undefined
														? ''
														: swapRequired
														? 'true'
														: 'false'
												}
												{...register('swap_required')}
												onChange={(e) =>
													setValue(
														'swap_required',
														e.target.value === 'true'
															? true
															: e.target.value === 'false'
															? false
															: undefined
													)
												}
											>
												<option value=''>Selecione uma opção</option>
												<option value='true'>Sim</option>
												<option value='false'>Não</option>
											</Styles.SelectInput>
										</Styles.Field>
										<Styles.Field
											style={{ width: '70%', margin: '0 0.5rem 1.2rem 0.5rem' }}
										>
											<Styles.Label htmlFor='start_date_dispute'>
												Data do início do chargeback
											</Styles.Label>
											<Styles.Input
												type='date'
												{...register('start_date_dispute')}
												name='start_date_dispute'
												max={new Date().toISOString().split('T')[0]}
												id='start_date_dispute'
												data-testid='startDateInput_test_id'
											/>
										</Styles.Field>
										<Styles.Field style={{ width: '70%' }}>
											<Styles.Label htmlFor='value'>
												Valor <ObrigatoryIndicator />
											</Styles.Label>
											<Styles.CurrencyInputStyled
												value={value}
												onValueChange={({ floatValue }) => {
													setInputValue(floatValue);
												}}
												showCurrencySymbol={false}
												autoFocus
												required
											/>
										</Styles.Field>
										<Styles.Field style={{ width: '70%' }}>
											<Styles.Label htmlFor='transaction_id'>
												ID da transação{' '}
												{swapRequired === true && <ObrigatoryIndicator />}
											</Styles.Label>
											<Styles.Input
												type='text'
												{...register('transaction_id')}
												id='transaction_id'
											/>
										</Styles.Field>
									</div>
								</Styles.FieldGroup>
								<Styles.FieldGroup
									style={{
										justifyContent: 'center',
										gap: '10rem',
									}}
								>
									<S.CancelOption onClick={handleCloseModal}>
										Cancelar
									</S.CancelOption>
									<Styles.SubmitButton
										type='submit'
										disabled={mutation.isLoading}
									>
										{mutation.isLoading ? <Loader size={16} /> : 'Atualizar'}
									</Styles.SubmitButton>
								</Styles.FieldGroup>
							</Styles.FieldSet>
						</Styles.FormContainer>
					</Styles.Form>
				</S.Container>

				<ForbiddenModal
					isOpen={showForbiddenModal}
					onClose={() => setShowForbiddenModal(false)}
					message={`O valor do chargeback não pode ser superior ao valor da transação. (${convertCentsToReais(
						dispute_value!
					)}).`}
				/>
			</Modal>
			{confirmationSwapRequiredModal && (
				<ConfirmationSwapRequiredModal
					onConfirm={() => submitForm(getValues())}
					onCancel={() => setConfirmationSwapRequiredModal(false)}
				/>
			)}
		</>
	);
}
