import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
  width: 100%;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	gap: 3rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 1.2rem;
	row-gap: 0.5rem;
	margin-top: 2rem;	
	margin-bottom: 2rem;
	& > h1 {
			margin-bottom: 1rem;
			font-size: 20px;
	}
`
