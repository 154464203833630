import { FundsOut } from '../../../../../@types';

export function parseFundsOutStatus(status: FundsOut['status']) {
	switch (status) {
		case 'paid':
			return 'Pago';
		case 'processing':
			return 'Processando';
		case 'error':
			return 'Não concluído';
		case 'cancelled':
			return 'Cancelado';
		case 'pending':
			return 'Pendente';
		case 'scheduled':
			return 'Agendado';
	}
}
