export function preRegisterStatusToText(status: string) {
	switch (status) {
		case 'step-one':
			return {
				message: 'Dados recebidos',
				description: 'Aguardando análise interna da etapa 1',
				color: 'var(--primary-blue)',
				step: 1,
			};
		case 'step-one-approved':
			return {
				message: 'Dados aprovados',
				description: 'Aguardando recebimento da etapa 2',
				color: 'green',
				step: 1,
			};
		case 'step-one-reproved':
			return {
				message: 'Dados reprovados',
				description: 'Aguardando revisão e reenvio dos dados',
				color: 'var(--primary-red)',
				step: 1,
			};
		case 'step-two':
			return {
				message: 'Dados recebidos',
				description: 'Aguardando validação interna da etapa 2',
				color: 'var(--primary-blue)',
				step: 2,
			};
		case 'step-two-approved':
			return {
				message: 'Dados aprovados',
				description: 'A empresa será encaminhada para a análise de KYC',
				color: 'green',
				step: 2,
			};
		case 'step-two-reproved':
			return {
				message: 'Dados reprovados',
				description: 'A empresa deverá recomeçar o processo cadastral',
				color: 'var(--primary-red)',
				step: 2,
			};
		case 'registration-completed':
			return {
				message: 'Cadastro finalizado',
				description: 'Análise de KYC aprovada',
				color: 'green',
				step: 2,
			};
		case 'send-swap':
			return {
				message: 'Em análise de KYC',
				description: 'Cadastro enviado para análise de KYC',
				color: 'var(--primary-blue)',
				step: 2,
			};
		case 'kyc-reproved':
			return {
				message: 'Cadastro reprovado',
				description:
					'Análise de KYC reprovada, aguardando revisão e reenvio dos dados',
				color: 'var(--primary-red)',
				step: 2,
			};
		case 'step-two-resent':
			return {
				message: 'Reenvio de dados',
				description: 'Realizado reenvio dos dados',
				color: 'var(--primary-blue)',
				step: 2,
			};
		default:
			return {
				message: status,
				color: 'var(--primary-blue)',
				description: '',
				step: 1,
			};
	}
}

export function userTypeToText(type: string) {
	switch (type) {
		case 'partner':
			return 'Sócio';
		case 'proxyholder':
			return 'Procurador';
		case 'legal_representative':
			return 'Representante legal';
		case 'other':
			return 'Outro';
		default:
			return type;
	}
}

export const primaryFunctions = [
	{ label: 'Sócio/Fundador', value: 'socio_fundador' },
	{ label: 'Procurador responsável', value: 'procurador_responsavel' },
	{ label: 'Responsável financeiro', value: 'responsavel_financeiro' },
	{ label: 'Time financeiro', value: 'time_financeiro' },
	{ label: 'Gestor de área', value: 'gestor_area' },
	{ label: 'Colaborador', value: 'colaborador' },
	{ label: 'Outro', value: 'outro' },
];

export function primaryFunctionsToText(value: string) {
	switch (value) {
		case 'socio_fundador':
			return 'Sócio/Fundador';
		case 'procurador_responsavel':
			return 'Procurador responsável';
		case 'responsavel_financeiro':
			return 'Responsável financeiro';
		case 'time_financeiro':
			return 'Time financeiro';
		case 'gestor_area':
			return 'Gestor de área';
		case 'colaborador':
			return 'Colaborador';
		case 'outro':
			return 'Outro';
		default:
			return value;
	}
}
