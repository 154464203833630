import PageTitle from '../../../../components/PageTitle';
import * as S from './styles';
import * as FormStyle from '../../../../components/Form/FormStyles';
import { cnpjPattern, phonePattern } from '../../../../utils/patterns';
import { cnpjMask, phoneMask } from '../../../../utils/masks';
import {
	approveRegister,
	showPreRegister,
} from '../../../../services/queries/PreRegister';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { Register } from '../../../../@types';
import Loader from '../../../../components/Loader';
import ApproveModal from './ApproveModal';
import { toast } from 'react-toastify';
import {
	preRegisterStatusToText,
	primaryFunctionsToText,
} from '../../../../utils/preRegister';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { ObrigatoryIndicator } from '../../../../components/ObrigatoryFieldsIndicator';
import ShareholdersModal from './ShareholdersModal';
import { useState } from 'react';
import Modal from '../../../../components/Modal';
import History from './History';
import { FormButton } from '../../../../components/Form/FormStyles';

export function PreRegisterCompanyDetails() {
	const { id } = useParams();
	const [fileModalVisible, setFileModalVisible] = useState(false);
	const [isHistoryVisible, setIsHistoryVisible] = useState(false);

	const getShowPreRegister = useQuery<Register, Error>(
		['pre-register', id],
		() => {
			return showPreRegister(id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar informações do pré-registro. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);
	const navigate = useNavigate();

	const reproveRegisterMutation = useMutation(
		['aprove-register', getShowPreRegister.data?.id],
		() => approveRegister(getShowPreRegister.data!.id, { approved: false }),
		{
			onSuccess: () => {
				navigate(-1);
				toast.success('Pré-registro reprovado com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível reprovar este pré-registro. '
				);
			},
		}
	);

	if (getShowPreRegister.isLoading || !getShowPreRegister.data) {
		return (
			<S.Container>
				<PageTitle title='Cadastro de empresas' />
				<Loader />
			</S.Container>
		);
	}

	const preRegisterStatus = preRegisterStatusToText(
		getShowPreRegister.data.registration_status
	);
	const timeline = getShowPreRegister?.data.history?.sort((a, b) => {
		return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
	});

	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<S.Container
				style={{
					padding: isHistoryVisible
						? '4rem 2rem 2rem 7rem'
						: '4rem 7rem 2rem 7rem',
				}}
			>
				<S.HeaderContainer>
					<PageTitle title='Dados da empresa' />
					<div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
						<h2 style={{ color: preRegisterStatus.color }}>
							Etapa {preRegisterStatus.step} - &nbsp;
							{preRegisterStatus.message}
						</h2>
						<FormButton onClick={() => setIsHistoryVisible(!isHistoryVisible)}>
							Histórico
						</FormButton>
					</div>
				</S.HeaderContainer>

				<Modal
					isOpen={fileModalVisible}
					enableClose
					onRequestClose={() => setFileModalVisible(false)}
				>
					<div style={{ width: '90rem' }}>
						<embed
							src={getShowPreRegister.data.social_contract_file?.url}
							type='application/pdf'
							width='100%'
							height='700px'
							data-testid='embed-pdf'
						/>
					</div>
				</Modal>

				<div
					style={{
						width: '100%',
						height: '0.1rem',
						background: 'var(--light-gray)',
						margin: '1rem 0 1.5rem 0',
					}}
				/>

				<FormStyle.FormContainer style={{ flexGrow: 0 }}>
					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							{getShowPreRegister.data.name && (
								<FormStyle.Field>
									<FormStyle.Label htmlFor='corporate_name'>
										Nome da empresa
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={255}
										type='text'
										name='name'
										id='name'
										data-testid='nameInput_test_id'
										required
										readOnly
										value={getShowPreRegister.data?.name}
									/>
								</FormStyle.Field>
							)}
							{getShowPreRegister.data.legal_name && (
								<FormStyle.Field>
									<FormStyle.Label htmlFor='corporate_name'>
										Razão Social
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={255}
										type='text'
										name='name'
										id='name'
										data-testid='nameInput_test_id'
										required
										readOnly
										value={getShowPreRegister.data?.legal_name}
									/>
								</FormStyle.Field>
							)}
							{getShowPreRegister.data.document && (
								<FormStyle.Field>
									<FormStyle.Label htmlFor='cnpj'>CNPJ</FormStyle.Label>
									<FormStyle.Input
										type='text'
										name='cnpj'
										pattern={cnpjPattern}
										id='cnpj'
										required
										readOnly
										value={cnpjMask(String(getShowPreRegister.data?.document))}
										onChange={(event) => {
											const { value } = event.target;
											event.target.value = cnpjMask(value);
										}}
										data-testid='cnpjInput_test_id'
									/>
								</FormStyle.Field>
							)}
						</FormStyle.FieldGroup>

						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='business_segment'>
									Segmento Comercial
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									name='business_segment'
									id='business_segment'
									data-testid='businessInput_test_id'
									required
									readOnly
									value={getShowPreRegister.data?.business_type}
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='number_of_collaborators'>
									Quantidade de funcionários
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									name='number_of_collaborators'
									id='number_of_collaborators'
									data-testid='collaboratorsInput_test_id'
									required
									readOnly
									value={getShowPreRegister.data?.number_of_collaborators}
								/>
							</FormStyle.Field>
							{getShowPreRegister.data.social_contract_file?.url && (
								<FormStyle.FormButton
									style={{ width: '30rem', marginTop: '1rem' }}
									onClick={() => setFileModalVisible(true)}
								>
									Contrato social
								</FormStyle.FormButton>
							)}
						</FormStyle.FieldGroup>
						{getShowPreRegister.data.registration_status ===
							'registration-completed' && (
							<>
								<FormStyle.FieldGroup>
									<FormStyle.Field>
										<FormStyle.Label htmlFor='trading_name'>
											Nome fantasia <ObrigatoryIndicator />
										</FormStyle.Label>
										<FormStyle.Input
											type='text'
											name={'trading_name'}
											value={getShowPreRegister.data?.trading_name}
											readOnly
										/>
									</FormStyle.Field>
								</FormStyle.FieldGroup>

								<FormStyle.FieldGroup>
									<FormStyle.Field>
										<FormStyle.Label htmlFor='founding_date'>
											Data de Fundação <ObrigatoryIndicator />
										</FormStyle.Label>
										<FormStyle.Input
											type='date'
											name={'founding_date'}
											value={getShowPreRegister.data?.founding_date}
											readOnly
										/>
									</FormStyle.Field>
									<FormStyle.Field>
										<FormStyle.Label htmlFor='main_activity'>
											Número do CNAE <ObrigatoryIndicator />
										</FormStyle.Label>
										<FormStyle.Input
											type='text'
											value={getShowPreRegister.data?.main_activity}
											name={'main_activity'}
											readOnly
										/>
									</FormStyle.Field>
								</FormStyle.FieldGroup>
							</>
						)}
					</FormStyle.FieldSet>

					<PageTitle title='Dados do contato' />

					<div
						style={{
							width: '100%',
							height: '0.1rem',
							background: 'var(--light-gray)',
							margin: '1rem 0 1.5rem 0',
						}}
					/>

					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='fullname'>
									Nome Completo
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									name='fullname'
									id='fullname'
									data-testid='fullnameInput_test_id'
									required
									readOnly
									value={getShowPreRegister.data?.contact.full_name}
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='corporative_email'>
									Email corporativo
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									name='corporative_email'
									id='corporative_email'
									data-testid='corporative_emailInput_test_id'
									required
									readOnly
									value={getShowPreRegister.data?.contact.email}
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>

						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='phone'>Telefone</FormStyle.Label>
								<FormStyle.Input
									type='tel'
									placeholder='Ex: +99 99 99999-9999'
									pattern={phonePattern}
									name='first_phone'
									id='first_phone'
									required
									data-testid='firstPhoneInput_test_id'
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = phoneMask(value);
									}}
									readOnly
									value={phoneMask(
										String(getShowPreRegister.data?.contact.phone)
									)}
								/>
							</FormStyle.Field>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='primary_function_company'>
									Principal função na empresa
								</FormStyle.Label>
								<FormStyle.Input
									maxLength={255}
									type='text'
									name='primary_function_company'
									id='primary_function_company'
									data-testid='primary_function_companyInput_test_id'
									required
									readOnly
									value={primaryFunctionsToText(
										getShowPreRegister.data?.contact.position_in_the_company
									)}
								/>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>

					{getShowPreRegister.data.registration_status !== 'step-one' &&
						getShowPreRegister.data.registration_status !==
							'step-one-approved' &&
						getShowPreRegister.data.registration_status !==
							'step-one-reproved' && (
							<S.Details>
								<summary>
									<S.SectionHeader>
										<h1>Endereço da empresa</h1>
										<MdOutlineKeyboardArrowDown />
									</S.SectionHeader>
								</summary>
								<FormStyle.FormContainer>
									<FormStyle.FieldSet>
										<FormStyle.FieldGroup>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.postal_code'>
													CEP
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.postal_code'
													readOnly
													value={getShowPreRegister.data?.address.postal_code}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.neighborhood'>
													Bairro
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.neighborhood'
													readOnly
													value={getShowPreRegister.data?.address.neighborhood}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.street'>
													Endereço
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.street'
													readOnly
													value={getShowPreRegister.data?.address.street}
												/>
											</FormStyle.Field>
										</FormStyle.FieldGroup>
										<FormStyle.FieldGroup>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.number'>
													Número
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.number'
													readOnly
													value={getShowPreRegister.data?.address.number}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.complement'>
													Complemento
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.complement'
													readOnly
													value={getShowPreRegister.data?.address.complement}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.city'>
													Cidade
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.city'
													readOnly
													value={getShowPreRegister.data?.address.city}
												/>
											</FormStyle.Field>
										</FormStyle.FieldGroup>
										<FormStyle.FieldGroup>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.state'>
													Estado
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.state'
													readOnly
													value={getShowPreRegister.data?.address.state}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.reference'>
													Referência
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.reference'
													readOnly
													value={getShowPreRegister.data?.address.reference}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='address.phone_number'>
													Telefone
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='address.phone_number'
													readOnly
													value={getShowPreRegister.data?.phone_number}
												/>
											</FormStyle.Field>
										</FormStyle.FieldGroup>
										<FormStyle.FieldGroup>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='email'>Email</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='email'
													readOnly
													value={getShowPreRegister.data?.email}
												/>
											</FormStyle.Field>
											<FormStyle.Field>
												<FormStyle.Label htmlFor='website'>
													Website
												</FormStyle.Label>
												<FormStyle.Input
													type='text'
													name='website'
													readOnly
													value={getShowPreRegister.data?.website}
												/>
											</FormStyle.Field>
										</FormStyle.FieldGroup>
									</FormStyle.FieldSet>
								</FormStyle.FormContainer>
							</S.Details>
						)}
				</FormStyle.FormContainer>

				<FormStyle.ButtonsContainer>
					<FormStyle.LeftButtonsContainer>
						<ApproveModal
							step={getShowPreRegister.data.registration_status ?? ''}
						/>
					</FormStyle.LeftButtonsContainer>
					{(getShowPreRegister.data.registration_status === 'step-two' ||
						getShowPreRegister.data.registration_status === 'step-one') && (
						<FormStyle.LeftButtonsContainer>
							<FormStyle.FormButton
								type='button'
								style={{
									background: 'var(--primary-red)',
									borderColor: 'var(--primary-red)',
								}}
								onClick={() => {
									reproveRegisterMutation.mutate();
								}}
							>
								Reprovar
							</FormStyle.FormButton>
						</FormStyle.LeftButtonsContainer>
					)}
					{getShowPreRegister.data.shareholders.length > 0 && (
						<ShareholdersModal
							shareholders={getShowPreRegister.data.shareholders}
						/>
					)}
				</FormStyle.ButtonsContainer>

				<S.DescriptionContainer>
					<h2>Legenda</h2>

					{preRegisterStatus.step === 1 && (
						<>
							<span>
								<b>1. Dados recebidos</b>: aguardando análise interna da etapa
								1.
							</span>
							<span>
								<b>2. Dados aprovados</b>: aguardando recebimento da etapa 2.
							</span>
							<span>
								<b>3. Dados reprovados</b>: aguardando revisão e reenvio dos
								dados.
							</span>
						</>
					)}
					{preRegisterStatus.step === 2 && (
						<>
							<span>
								<b>1. Dados recebidos</b>: aguardando validação interna da etapa
								2.
							</span>
							<span>
								<b>2. Dados aprovados</b>: a empresa será encaminhada para a
								análise de KYC.
							</span>
							<span>
								<b>3. Dados reprovados</b>: a empresa deverá recomeçar o
								processo cadastral.
							</span>
							<span>
								<b>4. Em análise de KYC</b>: cadastro enviado para análise de
								KYC.
							</span>
							<span>
								<b>5. Cadastro finalizado</b>: análise de KYC aprovada!
							</span>
							<span>
								<b>6. Cadastro reprovado</b>: análise de KYC reprovada,
								aguardando revisão e reenvio dos dados.
							</span>
						</>
					)}
				</S.DescriptionContainer>
			</S.Container>
			<History
				register={getShowPreRegister.data}
				timeline={timeline}
				isVisible={isHistoryVisible}
				setIsVisible={setIsHistoryVisible}
			/>
		</div>
	);
}
