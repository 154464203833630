import { useEffect, useState } from 'react';
import { OptionButton } from '../../../../components/Table/TableStyles';
import { FiUsers } from 'react-icons/fi';
import Modal from '../../../../components/Modal';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	getAllOperators,
	setOperatorsAccess,
	AllOperatorsResponse,
} from '../../../../services/queries/Users';
import { useOperatorsListStore } from '../../../../stores/useOperatorsListStore';
import * as S from './styles';
import * as TableStyle from '../../../../components/Table/TableStyles';
import PageTitle from '../../../../components/PageTitle';
import { Filter } from '../../../../components/Filter';
import { Pagination } from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import { EmptyContent } from '../../../../components/EmptyContent';
import { useForm } from 'react-hook-form';
import { CreateOperatorModal } from './CreateOperatorModal';
import { toast } from 'react-toastify';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { GroupCompany } from '../../../../@types';
import UserAccess from './UserAccess';

export interface AccessInfo {
	id: string;
	corporate_expenses: boolean;
	flexible_benefits: boolean;
	access_level?: string;
}

interface Props {
	groupCompany: GroupCompany;
}

interface Operator {
	flexible_benefits_access: boolean;
	corporate_expenses_access: boolean;
	corpway_access_level?: string;
	access_level?: string;
}

export interface OperatorAccessForm {
	operators: Record<string, Operator>;
}

export function ManageOperatorsAccessModal({ groupCompany }: Props) {
	const queryClient = useQueryClient();

	const { openOptionsDialog } = useDialogModal();

	const { register, handleSubmit, formState, reset, setValue, watch } =
		useForm<OperatorAccessForm>();
	const { isDirty } = formState;

	const [isOpen, setIsOpen] = useState(false);
	const [operatorsCurrentPage, setOperatorsCurrentPage] = useState(1);
	const [filtersParams, setFiltersParams, resetFilters] = useOperatorsListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	const setOperatorsAccessMutation = useMutation(
		(operatorsAccess: AccessInfo[]) => setOperatorsAccess(operatorsAccess),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries('listOperators');
				await queryClient.invalidateQueries('fetchGroupCompany');
				toast.info('Acesso dos operadores atualizado com sucesso.');
				resetModal();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar o acesso dos operadores. '
				);
			},
		}
	);

	const allOperatorsQuery = useQuery<AllOperatorsResponse, Error>(
		['listOperators', groupCompany.id, operatorsCurrentPage, filtersParams],
		() => {
			return getAllOperators(
				groupCompany.id,
				filtersParams,
				operatorsCurrentPage
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			enabled: isOpen,
			keepPreviousData: true,
		}
	);

	const hasFlexAdmin =
		(
			allOperatorsQuery.data?.operators?.filter(
				(e) => e.access_level === 'admin' && e.flexible_benefits_access
			) || []
		).length > 0;
	const hasControlAdmin =
		(
			allOperatorsQuery.data?.operators?.filter(
				(e) =>
					e.corpway_access_level === 'general_admin' &&
					e.corporate_expenses_access
			) || []
		).length > 0;

	useEffect(() => {
		allOperatorsQuery.data?.operators.forEach((operator) => {
			setValue(`operators.${operator.id}`, {
				flexible_benefits_access: operator.flexible_benefits_access || false,
				corporate_expenses_access: operator.corporate_expenses_access || false,
				corpway_access_level: operator.corpway_access_level,
			});
		});
	}, [allOperatorsQuery.data?.operators, setValue, isOpen]);

	async function submit(data: OperatorAccessForm) {
		let accesses: AccessInfo[] = Object.entries(data.operators).map(
			([key, value]) => {
				const obj: AccessInfo = {
					id: key,
					corporate_expenses: value.corporate_expenses_access,
					flexible_benefits: value.flexible_benefits_access,
					access_level:
						value.corpway_access_level === 'general_admin' ||
						value.corpway_access_level == null
							? undefined
							: value.corpway_access_level,
				};
				return obj;
			}
		);

		setOperatorsAccessMutation.mutate(accesses);
	}

	function handleClose() {
		if (!isDirty) {
			setIsOpen(false);
			resetFilters();
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => {
				resetModal();
			},
			'Cancelar',
			() => {}
		);
	}

	function resetModal() {
		setOperatorsCurrentPage(1);
		setFiltersParams([]);
		reset();
		setIsOpen(false);
	}

	if (allOperatorsQuery.isLoading || !allOperatorsQuery.data) {
		return (
			<>
				<OptionButton
					type='button'
					data-rh='Gerenciar acesso de operadores'
					onClick={() => {
						setIsOpen(true);
					}}
				>
					<FiUsers />
				</OptionButton>

				<Modal isOpen={isOpen} onRequestClose={handleClose} enableClose>
					<S.Container>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Acesso dos operadores' />
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setOperatorsCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
							<Pagination
								onPageChange={(page) => setOperatorsCurrentPage(page)}
								currentPage={operatorsCurrentPage}
								totalCount={0}
							/>
						</TableStyle.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}

	if (!allOperatorsQuery.data.operators.length) {
		return (
			<>
				<OptionButton
					type='button'
					data-rh='Gerenciar acesso de operadores'
					onClick={() => {
						setIsOpen(true);
					}}
				>
					<FiUsers />
				</OptionButton>

				<Modal isOpen={isOpen} onRequestClose={handleClose} enableClose>
					<S.Container>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Acesso dos operadores' />
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setOperatorsCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
							<Pagination
								onPageChange={(page) => setOperatorsCurrentPage(page)}
								currentPage={operatorsCurrentPage}
								totalCount={0}
							/>
						</TableStyle.TableHeaderContainer>

						<EmptyContent text='Nenhum operador encontrado.' />

						<S.BttnsContainer>
							<S.SaveButton disabled style={{ width: '14rem' }}>
								Salvar
							</S.SaveButton>

							<CreateOperatorModal
								hasControlAdmin={hasControlAdmin}
								hasFlexAdmin={hasControlAdmin}
								groupCompany={groupCompany}
							/>
						</S.BttnsContainer>
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<>
			<OptionButton
				type='button'
				data-rh='Gerenciar acesso de operadores'
				onClick={() => {
					setIsOpen(true);
				}}
			>
				<FiUsers />
			</OptionButton>

			<Modal isOpen={isOpen} onRequestClose={handleClose} enableClose>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle title='Acesso dos operadores' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setOperatorsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<Pagination
							onPageChange={(page) => setOperatorsCurrentPage(page)}
							currentPage={operatorsCurrentPage}
							totalCount={allOperatorsQuery.data.total_operators}
						/>
					</TableStyle.TableHeaderContainer>
					<TableStyle.Table>
						<TableStyle.TableHeader>
							<TableStyle.TableRow>
								<TableStyle.TableHeaderCell>
									Operador
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>Cargo</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									Benefícios flexíveis
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									Bounty Control
								</TableStyle.TableHeaderCell>
							</TableStyle.TableRow>
						</TableStyle.TableHeader>

						<TableStyle.TableBody>
							{allOperatorsQuery.data.operators.map((operator) => (
								<UserAccess
									key={operator.id}
									operator={operator}
									register={register}
									setValue={setValue}
									hasControlAdmin={hasControlAdmin}
									hasFlexAdmin={hasFlexAdmin}
									watch={watch}
									isCorpwayAdmin={
										operator.corpway_access_level === 'general_admin'
									}
									isFlexAdmin={operator.access_level === 'admin'}
								/>
							))}
						</TableStyle.TableBody>
					</TableStyle.Table>

					<S.BttnsContainer>
						{hasControlAdmin && hasFlexAdmin && (
							<div style={{ maxWidth: '50%' }}>
								<S.WarningText>
									Já existe administradores ativos para essa empresa em ambos os
									produtos
								</S.WarningText>
							</div>
						)}
						<S.SaveButton
							onClick={(e) => {
								handleSubmit(submit)(e);
							}}
							style={{ width: '14rem' }}
						>
							{setOperatorsAccessMutation.isLoading ? (
								<Loader color='#FFF' size={14} />
							) : (
								'Salvar'
							)}
						</S.SaveButton>

						<CreateOperatorModal
							hasControlAdmin={hasControlAdmin}
							hasFlexAdmin={hasFlexAdmin}
							groupCompany={groupCompany}
						/>
					</S.BttnsContainer>
				</S.Container>
			</Modal>
		</>
	);
}
