import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Company, GroupCompany, ProductOrigin } from '../../../../../@types';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import { useAuth } from '../../../../../hooks/useAuth';
import * as S from './styles';
import { SelectGroupCompany } from '../../../../../components/SelectGroupCompany';
import { SelectCompany } from '../../../../../components/SelectCompany';
import { useMutation } from 'react-query';
import {
	createTransaction,
	CreateTransactionArgs,
} from '../../../../../services/queries/Tranfers';
import { FormCurrencyInput } from '../../../../../components/Form/FormStyles';
import PreventTransitionPrompt from '../../../../../components/PreventTransitionPrompt';
import * as FormStyles from '../../../../../components/Form/FormStyles';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '../../../../../componentsV2/ui/InputField';
import SelectInput from '../../../../../componentsV2/ui/SelectInput';

const transferFormSchema = z
	.object({
		value: z.number().refine((e) => e > 0, 'Valor inválido'),
		master_cards_balance: z.number().optional(),
		group_id: z
			.string({ required_error: 'Selecione um grupo corporativo' })
			.optional(),
		companyId: z.string({ required_error: 'Selecione uma empresa' }),
		origin: z.enum(['multiflex', 'corpway']),
	})
	.superRefine((data, ctx) => {
		if (data.value > (data.master_cards_balance ?? 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['value'],
				message: `Saldo cards insuficiente`,
			});
		}
	});

interface CreateTransferForm {
	value: number;
	master_cards_balance?: number;
	group_id?: string;
	companyId?: string;
	origin: 'multiflex' | 'corpway';
}

const formDefaults: CreateTransferForm = {
	value: 0,
	origin: 'multiflex',
	group_id: undefined,
	companyId: undefined,
	master_cards_balance: 0,
};

export function CreateTransfer() {
	const { updateMasterBalance, masterBalance } = useAuth();

	const { handleSubmit, formState, setValue, watch, register, reset } =
		useForm<CreateTransferForm>({
			resolver: zodResolver(transferFormSchema),
			defaultValues: formDefaults,
		});

	const group_id = watch('group_id');
	const companyId = watch('companyId');
	const value = watch('value');

	useEffect(() => {
		setValue('companyId', undefined);
	}, [group_id, setValue]);

	useEffect(() => {
		setValue('master_cards_balance', masterBalance.cards);
	}, [masterBalance.cards, setValue]);

	const createTransactionMutation = useMutation(
		({ value, origin, companyId }: CreateTransactionArgs) => {
			return createTransaction({ value, origin, companyId });
		},
		{
			onSuccess: () => {
				updateMasterBalance.refetch();
				reset(formDefaults);
				toast.info('Transferência realizada com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar realizar a transferência.'
				);
			},
		}
	);

	async function submitTransfer(data: CreateTransferForm) {
		delete data.group_id;
		delete data.master_cards_balance;

		createTransactionMutation.mutate(data as CreateTransactionArgs);
	}

	if (createTransactionMutation.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Criar transferência' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={formState.isDirty} />
			<PageTitle title='Criar transferência' />
			<S.FormContainer>
				<S.Form onSubmit={handleSubmit(submitTransfer)}>
					<FormStyles.Field>
						<FormStyles.Label htmlFor='company'>
							Grupo Corporativo
						</FormStyles.Label>
						<SelectGroupCompany
							selectedGroupId={group_id}
							onGroupCompanySelection={(g) =>
								setValue('group_id', g.id, { shouldDirty: true })
							}
						/>
						{formState.errors.group_id?.message && (
							<ErrorMessage>{formState.errors.group_id?.message}</ErrorMessage>
						)}
					</FormStyles.Field>
					<FormStyles.Field>
						<FormStyles.Label htmlFor='company'>Empresa</FormStyles.Label>
						<SelectCompany
							groupCompanyId={group_id}
							selectedCompanyId={companyId}
							onCompanySelection={(c) => {
								setValue('companyId', c.id, { shouldDirty: true });
							}}
						/>
						{formState.errors.companyId?.message && (
							<ErrorMessage>{formState.errors.companyId?.message}</ErrorMessage>
						)}
					</FormStyles.Field>

					<SelectInput
						id='product'
						label={'Produto'}
						register={register}
						name='origin'
						containerStyle={{ maxWidth: '55%' }}
					>
						<option value='multiflex'>Benefícios Flexíveis</option>
						<option value='corpway'>Bounty Control</option>
					</SelectInput>

					<FormStyles.Field style={{ width: '40%' }}>
						<FormStyles.Label htmlFor='value'>Valor</FormStyles.Label>
						<S.InputValueContainer>
							<FormCurrencyInput
								value={value}
								id='value'
								data-testid='currency-value'
								onValueChange={({ floatValue }) =>
									setValue('value', floatValue, { shouldDirty: true })
								}
							/>
						</S.InputValueContainer>
						{formState.errors.value?.message && (
							<ErrorMessage>{formState.errors.value?.message}</ErrorMessage>
						)}
					</FormStyles.Field>

					<S.SubmitButton type='submit'>Enviar</S.SubmitButton>
				</S.Form>
			</S.FormContainer>
		</S.Container>
	);
}
