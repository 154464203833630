import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchDispute } from '../../../../../services/queries/Disputes';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import Loader from '../../../../../components/Loader';
import * as S from './styles';
import PageTitle from '../../../../../components/PageTitle';
import {
	getDipusteStatusIcon,
	parseDisputeStatusString,
	parseDisputeSwapStatusString,
} from '../../../../../utils/parseDisputeStatus';
import { ProductOriginLabel } from '../../../../../components/ProductOriginLabel';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { cnpjMask, maskCardNumber } from '../../../../../utils/masks';
import { ConfirmationTransfersModal } from './ConfirmationTransfersModal';
import UpdateDisputeModal from './UpdateDisputeModal';

export function DisputeDetails() {
	const { dispute_id } = useParams<{ dispute_id: string }>();
	const defaultCard = { id: '', pan: '' };
	const { data, isLoading, error, refetch } = useQuery(
		['dispute', dispute_id],
		() => fetchDispute(dispute_id!),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível carregar os detalhes da chargeback'
				);
			},
		}
	);

	if (isLoading) {
		return <Loader />;
	}

	if (error || !data) {
		return <div>Erro ao carregar os detalhes da chargeback.</div>;
	}

	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhamento' />
				{getDipusteStatusIcon(data.dispute.dispute_status)}{' '}
				{parseDisputeStatusString(data.dispute.dispute_status)}
			</S.Header>
			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>Empresa</S.InfoTitle>
					<div>{data.dispute.company.name}</div>
					<div>{cnpjMask(data.dispute.company.cnpj)}</div>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Produto</S.InfoTitle>
					<ProductOriginLabel origin={data.dispute.product} />
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Valor</S.InfoTitle>
					{convertCentsToReais(data.dispute.amount)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Início do chargeback</S.InfoTitle>
					{data.dispute.start_date_dispute || '---'}
				</S.InfoContainer>
				{data.dispute.dispute_status === 'finished' && (
					<S.InfoContainer>
						<S.InfoTitle>Repasse para empresa</S.InfoTitle>
						{data.dispute.transfer_date_company}
					</S.InfoContainer>
				)}
				<S.InfoContainer>
					<S.InfoTitle>Status swap</S.InfoTitle>
					{parseDisputeSwapStatusString(data.dispute.status_swap) || '---'}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Cartão</S.InfoTitle>
					{data.dispute.card?.pan
						? maskCardNumber(data.dispute.card.pan)
						: '---'}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>ID da transação</S.InfoTitle>
					{data.dispute.transaction_id || '---'}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Descrição</S.InfoTitle>
					{data.dispute.dispute_description}
				</S.InfoContainer>
			</S.InfoTop>
			<S.ButtonContainer>
				<UpdateDisputeModal
					amount={data.dispute.amount}
					card={data.dispute.card || defaultCard}
					id={data.dispute.id!}
					dispute_description={data.dispute.dispute_description}
					product={data.dispute.product}
					start_date_dispute={data.dispute.start_date_dispute}
					transaction_id={data.dispute.transaction_id}
					company_id={data.dispute.company.id}
					swap_required={data.dispute.swap_required}
					availableProducts={data.company_products}
					dispute_value={
						data.transaction_details?.amount
							? data.transaction_details.amount
							: null
					}
				/>
				{(data.dispute.status_swap === 'finalized' &&
					data.dispute.dispute_status === 'initialized') ||
					(data.dispute.swap_required === false &&
						data.dispute.dispute_status === 'initialized' && (
							<S.InfoContainer>
								<ConfirmationTransfersModal
									dispute_id={dispute_id!}
									company={data.dispute.company}
									value={data.dispute.amount}
									dispute_value={
										data.transaction_details?.amount
											? data.transaction_details.amount
											: null
									}
									product={data.dispute.product}
									onTransferComplete={() => refetch()}
								/>
							</S.InfoContainer>
						))}
			</S.ButtonContainer>
		</S.Container>
	);
}
