import {
	ProductOrigin,
	Transaction,
	Transfer,
	FirstCredit,
} from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import { convertReaisToCents } from '../../utils/CurrencyConvert';
import api from '../api';

export interface TransactionsListReturn {
	transactions: Transaction[];
	totalTransactions: number;
}

export interface CreateTransactionArgs {
	value: number;
	origin: ProductOrigin;
	companyId: string;
}

export interface CreateFirstCreditArgs {
	value: number;
	companyId: string;
	justification: string;
	origin: string;
}

export interface TransfersListReturn {
	transfers: Transfer[];
	totalTransfers: number;
	entrances_totalValue: number;
	exits_totalValue: number;
	totalReports?: number;
}

export interface FirstCreditListReturn {
	transfers: FirstCredit[];
	total: number;
}

export async function getTransactions(
	transactionsCurrentPage: number
): Promise<TransactionsListReturn> {
	const { data } = await api.get(
		`/api/v1/master/transfers/q?page=${transactionsCurrentPage}`
	);

	const transactions = data.transactions;
	const totalTransactions = data.totalTransactions;

	return {
		transactions,
		totalTransactions,
	};
}

export async function getTransfersV2(
	filterParams: FilterParams[],
	page = 1,
	sortColumn: SortColumn | null
): Promise<TransfersListReturn> {
	const { data } = await api.get(`/api/v1/master/transfers/v2/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function getMasterTransfers(
	filterParams: FilterParams[],
	page = 1,
	sortColumn: SortColumn | null
): Promise<FirstCreditListReturn> {
	const { data } = await api.get(`/api/v1/master/new-companies/transfers/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function firstCredit({
	value,
	companyId,
	justification,
	origin,
}: CreateFirstCreditArgs): Promise<void> {
	await api.post(`/api/v1/master/new-companies/transfer/${companyId}`, {
		value: convertReaisToCents(value),
		justification: justification,
		origin: origin,
	});
}

export async function getTransfersV2ToReport(
	filterParams: FilterParams[]
): Promise<TransfersListReturn> {
	const { data } = await api.get(`/api/v1/master/transfers/v2/report/q?`, {
		params: {
			...parseFilterParamsToParams(filterParams),
		},
	});

	return data;
}

export async function createTransaction({
	value,
	origin,
	companyId,
}: CreateTransactionArgs): Promise<void> {
	await api.post(`/api/v1/master/transfers/companies/${companyId}`, {
		value: convertReaisToCents(value),
		origin,
	});
}

export async function getFilteredTransactions(
	transactionsFilterParams: FilterParams[],
	page: number
): Promise<TransactionsListReturn> {
	const { data } = await api.get(
		`/api/v1/master/transfers/filter/q?page=${page}`,
		{
			params: parseFilterParamsToParams(transactionsFilterParams),
		}
	);

	return data;
}
