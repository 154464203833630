import { useForm } from 'react-hook-form';
import { useCompanyForm } from '../../../hooks/useCompanyForm';
import { cepMask, cnpjMask, cpfMask, phoneMask } from '../../../utils/masks';
import { parseDateToInput } from '../../../utils/parseDate';
import Loader from '../../Loader';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../ObrigatoryFieldsIndicator';
import * as FormStyles from '../../Form/FormStyles';
import * as S from './styles';
import {
	KYCFieldsIndication,
	KYCFieldsIndicator,
} from '../../KYCFieldsIndicator';
import { UF_List } from '../../../utils/CheckUF';
import {
	addressesFieldSWAPRegex,
	addressNumberSWAPRegex,
	cepRegex,
	cnpjRegex,
	cpfRegex,
	onlyLettersAndSpacesRegex,
	phoneRegex,
	websiteRegex,
} from '../../../utils/patterns';
import { useGetAddressByCEP } from '../../../hooks/useGetAddressByCEP';
import { SearchCEPButton } from '../../SearchCEPButton';
import { ShareholderFormData } from '../../../contexts/CompanyFormContext';
import { useState } from 'react';
import suitcase from '../../../assets/suitcase.png';
import professional from '../../../assets/professional.png';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputField from '../../../componentsV2/ui/InputField';
import SelectInput from '../../../componentsV2/ui/SelectInput';

export function trimObjectData<T extends Record<string, any>>(object: T): T {
	const trimmedObject: Record<string, any> = { ...object };

	Object.keys(trimmedObject).forEach((key) => {
		const value = trimmedObject[key];

		if (value === null || value === undefined) {
			delete trimmedObject[key];
		} else if (typeof value === 'string') {
			trimmedObject[key] = value.trim();
		} else if (typeof value === 'object' && !Array.isArray(value)) {
			trimmedObject[key] = trimObjectData(value);
		}
	});

	return trimmedObject as T;
}

const commonSchema = z.object({
	email: z.string().min(1, 'Email é obrigatório').email('Email inválido'),
	phone_number: z
		.string()
		.min(1, 'Telefone é obrigatório')
		.regex(phoneRegex, 'Telefone inválido'),
	address: z
		.string()
		.min(1, 'Endereço é obrigatório')
		.max(100, 'Endereço deve ter no máximo 100 caracteres')
		.regex(
			addressesFieldSWAPRegex,
			"Utilize apenas letras, espaços e (.)(,)(')(/)(-)(_) no campo de endereço"
		),
	number: z
		.string()
		.min(1, 'Número é obrigatório')
		.max(10, 'Número deve ter no máximo 10 caracteres')
		.regex(addressNumberSWAPRegex, 'Número inválido'),
	type: z.string().min(1, 'Tipo de sócio é obrigatório.'),
	district: z
		.string()
		.min(1, 'Bairro é obrigatório')
		.max(100, 'Bairro deve ter no máximo 100 caracteres')
		.regex(
			addressesFieldSWAPRegex,
			"Utilize apenas letras, espaços e (.)(,)(')(/)(-)(_) no campo de bairro"
		),
	cep: z
		.string()
		.min(1, 'CEP é obrigatório')
		.max(9, 'CEP deve ter no máximo 9 caracteres')
		.regex(cepRegex, 'CEP inválido'),
	complement: z
		.string()
		.max(50, 'Complemento deve ter no máximo 50 caracteres')
		.regex(
			addressesFieldSWAPRegex,
			"Utilize apenas letras, espaços e (.)(,)(')(/)(-)(_) no campo de complemento"
		)
		.optional()
		.or(z.literal('')),
	city: z
		.string()
		.min(1, 'Cidade é obrigatória')
		.max(50, 'Cidade deve ter no máximo 50 caracteres')
		.regex(onlyLettersAndSpacesRegex, 'Use somente letras e espaços'),
	uf: z.string().min(1, 'UF é obrigatória.'),
});

const pjSchema = z.object({
	legal_name: z
		.string()
		.min(1, 'Razão Social é obrigatória')
		.max(255, 'Razão Social deve ter no máximo 255 caracteres')
		.regex(onlyLettersAndSpacesRegex, 'Use somente letras e espaços'),
	trading_name: z
		.string()
		.min(1, 'Nome Fantasia é obrigatório')
		.max(255, 'Nome Fantasia deve ter no máximo 255 caracteres')
		.regex(onlyLettersAndSpacesRegex, 'Use somente letras e espaços'),
	cnpj: z
		.string()
		.min(1, 'CNPJ é obrigatório')
		.max(18, 'CNPJ deve ter no máximo 18 caracteres')
		.regex(cnpjRegex, 'CNPJ inválido'),
	founding_date: z.string().min(1, 'Data de Fundação é obrigatória'),
	main_activity: z
		.string()
		.min(1, 'Número do CNAE é obrigatório.')
		.max(50, 'Número do CNAE deve ter no máximo 50 caracteres'),
	website: z
		.string()
		.max(255, 'Website deve ter no máximo 255 caracteres')
		.regex(websiteRegex, 'Website inválido')
		.optional()
		.or(z.literal('')),
	type_of_social_contract: z.enum(['PJ']),
});

const pfSchema = z.object({
	full_name: z
		.string()
		.min(1, 'Nome Completo é obrigatório.')
		.max(255, 'Nome Completo deve ter no máximo 255 caracteres')
		.regex(onlyLettersAndSpacesRegex, 'Use somente letras e espaços')
		.refine((e) => e.split(' ').length > 1, 'Informe o nome completo'),
	mother_name: z
		.string()
		.min(1, 'Nome da Mãe é obrigatório.')
		.max(255, 'Nome da Mãe deve ter no máximo 255 caracteres')
		.regex(onlyLettersAndSpacesRegex, 'Use somente letras e espaços'),
	cpf: z
		.string()
		.min(1, 'CPF é obrigatório')
		.max(14, 'CPF deve ter no máximo 14 caracteres')
		.regex(cpfRegex, 'CPF inválido.'),
	birth_date: z
		.string()
		.min(1, 'Data de Nascimento é obrigatória')
		.regex(/^\d{4}-\d{2}-\d{2}$/, 'Formato de data inválido (AAAA-MM-DD).'),
	type_of_social_contract: z.enum(['PF']),
});

export const getValidationSchema = (userType: string) => {
	if (userType === 'PJ') {
		return commonSchema.merge(pjSchema);
	}
	return commonSchema.merge(pfSchema);
};

interface ShareholdersFormProps {
	shareholder: ShareholderFormData;
	goBack: () => void;
}

export function ShareholdersForm({
	shareholder,
	goBack,
}: ShareholdersFormProps) {
	const [userType, setUserType] = useState<'PF' | 'PJ' | undefined>(
		shareholder.type_of_social_contract
	);
	const schema = getValidationSchema(userType || 'PF');
	const {
		company,
		createShareholderToCompanyCreation,
		updateShareholderToCompanyCreation,
		addShareholder,
		updateShareholder,
		loading,
	} = useCompanyForm();

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			...shareholder,
			cpf: cpfMask(shareholder.cpf || ''),
			birth_date: parseDateToInput(shareholder.birth_date || ''),
			founding_date: parseDateToInput(shareholder.founding_date || ''),
			cnpj: cnpjMask(shareholder.cnpj || ''),
			phone_number: phoneMask(shareholder.phone_number || ''),
			cep: cepMask(shareholder.cep || ''),
			uf: shareholder.uf || '',
			type: shareholder.type || '',
			type_of_social_contract: userType,
		},
	});

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	function clearValues(type: 'PF' | 'PJ') {
		if (type === 'PJ') {
			setValue('cpf', '');
			setValue('birth_date', '');
			setValue('mother_name', '');
			setValue('full_name', '');
		} else {
			setValue('cnpj', '');
			setValue('trading_name', '');
			setValue('legal_name', '');
			setValue('founding_date', '');
			setValue('main_activity', '');
			setValue('website', '');
		}
	}

	async function handleSubmitForm(data: ShareholderFormData) {
		if (userType === 'PJ') {
			setUserType('PJ');
		} else {
			setUserType('PF');
		}

		let success;

		if (shareholder.id) {
			data.id = shareholder.id;
			success = await updateShareholder(trimObjectData(data));
		} else if (!shareholder.id && !!company?.id) {
			success = await addShareholder(trimObjectData(data));
		} else {
			// creating/updating a shareholder to a new company creation
			if (!shareholder.cpf && !shareholder.cnpj) {
				// create
				success = createShareholderToCompanyCreation(trimObjectData(data));
			} else {
				success = updateShareholderToCompanyCreation(
					shareholder.cpf ? shareholder.cpf! : shareholder.cnpj!,
					trimObjectData(data)
				);
			}
		}

		if (success) goBack();
	}

	function subtractYears(date: Date, years: number) {
		date.setFullYear(date.getFullYear() - years);
		return date;
	}

	if (loading) {
		return <Loader />;
	}

	const UserSelection = () => {
		return (
			<div
				style={{
					display: 'flex',
					gap: '3rem',
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<S.CardContainer>
					<S.CardHeaderContainer>
						<img src={professional} alt={'Pessoa física'} />
					</S.CardHeaderContainer>
					<S.CardContentContainer>
						<h1>Pessoa física</h1>
						<p>
							Para empresas cujo quadro societário é comporto apenas por Pessoas{' '}
							<br /> Físicas
						</p>
						<S.Divider />
						<S.ButtonContainer
							onClick={() => {
								setUserType('PF');
								setValue('type_of_social_contract', 'PF');
							}}
							color={'#27ADFF'}
						>
							Cadastrar sócio
						</S.ButtonContainer>
					</S.CardContentContainer>
				</S.CardContainer>
				<S.CardContainer>
					<S.CardHeaderContainer>
						<img src={suitcase} alt={'Empresa'} />
					</S.CardHeaderContainer>
					<S.CardContentContainer>
						<h1>Pessoa jurídica</h1>
						<p>
							Para empresas cujo quadro societário tem um ou mais sócios que são
							Pessoas Jurídicas.
						</p>
						<S.Divider />
						<S.ButtonContainer
							onClick={() => {
								setUserType('PJ');
								setValue('type_of_social_contract', 'PJ');
							}}
							color={'#FF0037'}
						>
							Cadastrar empresa
						</S.ButtonContainer>
					</S.CardContentContainer>
				</S.CardContainer>
			</div>
		);
	};

	if (userType === undefined) return <UserSelection />;
	else
		return (
			<FormStyles.Form
				style={{ marginTop: 0 }}
				onSubmit={(e) => {
					e.preventDefault();
					clearValues(userType);
					handleSubmit(handleSubmitForm)();
					e.stopPropagation(); // do not submit the parent form
				}}
				autoComplete='off'
				autoCorrect='off'
				data-testid='shareholder_form_test_id'
			>
				{userType === 'PJ' ? (
					<>
						<FormStyles.FieldGroup>
							<InputField
								maxLength={255}
								register={register}
								name={'legal_name'}
								label={
									<>
										Razão Social <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='legalNameInput_test_id'
								errorMessage={errors.legal_name?.message}
							/>
							<InputField
								maxLength={255}
								data-testid='tradingNameInput_test_id'
								register={register}
								name={'trading_name'}
								label={
									<>
										Nome Fantasia <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								errorMessage={errors.trading_name?.message}
							/>
						</FormStyles.FieldGroup>

						<FormStyles.FieldGroup>
							<InputField
								register={register}
								name='cnpj'
								label={
									<>
										CNPJ <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = cnpjMask(value);
								}}
								data-testid='cnpjInput_test_id'
								errorMessage={errors.cnpj?.message}
							/>
							<InputField
								type='date'
								register={register}
								name='founding_date'
								label={
									<>
										Data de Fundação <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								max={new Date().toISOString().split('T')[0]}
								data-testid='foundingInput_test_id'
								errorMessage={errors.founding_date?.message}
							/>
						</FormStyles.FieldGroup>

						<FormStyles.FieldGroup>
							<InputField
								register={register}
								name='main_activity'
								label={
									<>
										Número do CNAE <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								maxLength={50}
								data-testid='main_activityInput_test_id'
								errorMessage={errors.main_activity?.message}
							/>
							<InputField
								register={register}
								name='website'
								label={
									<>
										Website <KYCFieldsIndicator />
									</>
								}
								maxLength={255}
								placeholder='Ex: www.exemplo.com'
								data-testid='websiteInput_test_id'
								errorMessage={errors.website?.message}
							/>
						</FormStyles.FieldGroup>
					</>
				) : (
					<>
						<FormStyles.FieldGroup>
							<InputField
								maxLength={255}
								register={register}
								name='full_name'
								label={
									<>
										Nome Completo <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='fullNameInput_test_id'
								errorMessage={errors.full_name?.message}
							/>
						</FormStyles.FieldGroup>

						<FormStyles.FieldGroup>
							<InputField
								maxLength={255}
								register={register}
								name='mother_name'
								label={
									<>
										Nome da mãe <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='motherNameInput_test_id'
								errorMessage={errors.mother_name?.message}
							/>
							<InputField
								register={register}
								name='cpf'
								label={
									<>
										CPF <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								placeholder='Ex: 999.999.999-99'
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = cpfMask(value);
								}}
								data-testid='cpfInput_test_id'
								errorMessage={errors.cpf?.message}
								containerStyle={{ width: '46%' }}
							/>
							<InputField
								type='date'
								register={register}
								name='birth_date'
								label={
									<>
										Data de nascimento <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								max={subtractYears(new Date(), 9).toISOString().split('T')[0]}
								data-testid='birthInput_test_id'
								errorMessage={errors.birth_date?.message}
								containerStyle={{ width: '50%' }}
							/>
						</FormStyles.FieldGroup>
					</>
				)}

				<FormStyles.FieldGroup>
					<SelectInput
						label={
							<>
								Tipo <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						register={register}
						name='type'
						errorMessage={errors.type?.message}
						data-testid='typeInput_test_id'
						containerStyle={{ maxWidth: '49%' }}
					>
						<option disabled value=''>
							Selecione uma opção
						</option>
						<option key='partner' value='partner'>
							Sócio
						</option>
						<option key='proxyholder' value='proxyholder'>
							Procurador
						</option>
						<option key='legal_representative' value='legal_representative'>
							Representante legal
						</option>
						<option key='other' value='other'>
							Outro
						</option>
					</SelectInput>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<InputField
						type='email'
						register={register}
						name='email'
						label={
							<>
								Email <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						placeholder='Ex: exemplo@email.com'
						data-testid='emailInput_test_id'
						errorMessage={errors.email?.message}
					/>
					<InputField
						type='tel'
						register={register}
						name='phone_number'
						label={
							<>
								Telefone <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						placeholder='Ex: +55 81 99999-9999'
						onChange={(event) => {
							const { value } = event.target;
							event.target.value = phoneMask(value);
						}}
						data-testid='phoneInput_test_id'
						errorMessage={errors.phone_number?.message}
					/>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<InputField
						register={register}
						name='address'
						label={
							<>
								Endereço <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						maxLength={100}
						data-testid='addressInput_test_id'
						errorMessage={errors.address?.message}
					/>
					<InputField
						register={register}
						name='number'
						label={
							<>
								Número <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						maxLength={10}
						data-testid='numberInput_test_id'
						errorMessage={errors.number?.message}
					/>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<InputField
						register={register}
						name='district'
						label={
							<>
								Bairro <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						maxLength={100}
						data-testid='districtInput_test_id'
						errorMessage={errors.district?.message}
					/>
					<InputField
						register={register}
						name='cep'
						label={
							<>
								CEP <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						placeholder='Ex: 99999-999'
						onChange={(event) => {
							const { value } = event.target;
							event.target.value = cepMask(value);
						}}
						data-testid='cepInput_test_id'
						errorMessage={errors.cep?.message}
					/>
					<SearchCEPButton
						isLoading={isSearchingCEP}
						onClick={() => searchAddressByCEP(getValues('cep'))}
					/>
				</FormStyles.FieldGroup>

				<FormStyles.FieldGroup>
					<InputField
						register={register}
						name='complement'
						label='Complemento'
						maxLength={50}
						data-testid='complementInput_test_id'
						errorMessage={errors.complement?.message}
					/>
					<InputField
						register={register}
						name='city'
						label={
							<>
								Cidade <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						maxLength={50}
						data-testid='cityInput_test_id'
						containerStyle={{ width: '60%' }}
						errorMessage={errors.city?.message}
					/>
					<SelectInput
						label={
							<>
								UF <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</>
						}
						register={register}
						name='uf'
						data-testid='ufInput_test_id'
						errorMessage={errors.uf?.message}
						containerStyle={{ width: '30%' }}
					>
						<option disabled value=''>
							Selecione uma opção
						</option>
						{UF_List.map((uf) => (
							<option key={uf} value={uf}>
								{uf}
							</option>
						))}
					</SelectInput>
				</FormStyles.FieldGroup>

				<ObrigatoryFieldsIndication />
				<KYCFieldsIndication />
				<S.BottomOptionsContainer>
					<S.BackButton type='button' onClick={goBack}>
						Voltar
					</S.BackButton>
					<S.SaveButton type='submit'>Salvar</S.SaveButton>
				</S.BottomOptionsContainer>
			</FormStyles.Form>
		);
}
