import { Register } from '../../@types';
import api from '../api';
import { FilterParams, parseFilterParamsToParams } from '../../components/Filter';

export async function listPreRegister(page: number, filterParams : FilterParams[], step: "1" | "2") {
	const { data } = await api.get<{registers: Register[], total: number}>(`/api/v1/master/register-company/q`, {
		params: {
			page: page,
			step: step,
			...parseFilterParamsToParams(filterParams)
		},
	});

	return data;
}

export async function showPreRegister(register_id: string) {
	const { data } = await api.get<Register>(
		`/api/v1/master/register-company/${register_id}`,
	);

	return data;
}

export interface ApproveRegister {
	approved: boolean;
	trading_name?: string;
	founding_date?: string;
	main_activity?: string;
}

export async function approveRegister(register_id: string, data: ApproveRegister) {
	await api.post<Register>(
		`/api/v1/master/register-company/${register_id}`,
		data,
	);
}
