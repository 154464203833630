export function cpfMask(value: string): string {
	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function cnpjMask(value: string): string {
	if (value.length <= 18) {
		value = value
			.replace(/\D/g, '')
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
	}
	return value.substring(0, 18);
}

export function cepMask(value: string) {
	var re = /^([\d]{5})-?([\d]{3})/;

	return value.replace(/\D/g, '').replace(re, '$1-$2').substring(0, 9);
}

export function crnmMask(value: string) {
	return value
		.replace(/[\W_]+/g, '')
		.toUpperCase()
		.substring(0, 8);
}

export function phoneMask(value: string) {
	// regex for testing the pattern
	// const re = /\+([0-9]+( [0-9]+)+)-([0-9])/i;
	value = value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '+$1 $2')
		.replace(/(\d{2})(\d)/, '$1 $2');

	if (value.length < 16) {
		return value.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return value.replace(/(\d{5})(\d)/, '$1-$2').substring(0, 17);
}

export function companyPhoneMask(value: string) {
	// regex for testing the pattern
	// const re = /\+([0-9]+( [0-9]+)+)-([0-9])/i;
	if (value.length <= 15) {
		value = value
			.replace(/\D/g, '')
			.replace(/(\d)(\d)/, '($1$2) ')
			.replace(/(\d{5})(\d)/, '$1-$2');
	}

	return value
		.replace(/\D/g, '')
		.replace(/(\d)(\d)/, '($1$2) ')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.substring(0, 15);
}

export function maskCardNumber(cardNumber: string) {
	return (
		cardNumber
			.slice(0, -4)
			.replace(/\d/g, '*')
			.replace(/(.{4})/g, '$1 ') + cardNumber.slice(-4)
	);
}

// PARSE TO RAW
export function parseMaskedCPFToRaw(maskedCPF: string) {
	return maskedCPF.replaceAll('.', '').replaceAll('-', '');
}

export function parseMaskedCEPToRaw(maskedCPF: string) {
	return maskedCPF.replaceAll('-', '');
}

export function parseMaskedCRNMToRaw(maskedCRNM: string) {
	return maskedCRNM.replaceAll('-', '');
}

export function parseMaskedPhoneToRaw(maskedPhone: string) {
	return maskedPhone
		.replaceAll(' ', '')
		.replaceAll('+', '')
		.replaceAll('-', '');
}

export function parseMaskedCompanyPhoneToRaw(maskedPhone: string) {
	return maskedPhone
		.replaceAll(' ', '')
		.replaceAll('(', '')
		.replaceAll(')', '')
		.replaceAll('-', '');
}

export function parseMaskedCnpjToRaw(maskedCnpj: string) {
	return maskedCnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '');
}
