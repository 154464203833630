import { FundsOut } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	parseSortColumnToParams,
	SortColumn,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface FundsOutListReturn {
	fundsOut: FundsOut[];
	total_fundsOut: number;
	total_reports?: number;
}

export async function getFundsOut(
	filterParams: FilterParams[],
	page = 1,
	sortColumn: SortColumn | null
): Promise<FundsOutListReturn> {
	const { data } = await api.get(`/api/v1/master/funds-out/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function getFundsOutToReport(
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
): Promise<FundsOutListReturn> {
	const { data } = await api.get(`/api/v1/master/funds-out/report/q`, {
		params: {
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function fetchFundOut(funds_id: string): Promise<FundsOut> {
	const { data } = await api.get(`/api/v1/master/funds-out/${funds_id}`);

	return data;
}
