import { Company } from '../../@types';
import { ProductsFormData } from '../../components/CompanyForm/UpdateProductsModal';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import { CompanyToSelect } from '../../pages/Main/ReportUsers/SegmentationModal/components/CompaniesSelector';
import api from '../api';

export interface CreateCompanyParams {
	groupCompanyId: string;
	companyData: FormData;
}

export interface UpdateBenefitParams {
	benefits: {
		id_baas: string;
		label: string;
		value_default: number;
	}[];
	companyId: string;
}

export interface UpdateCompanyParams {
	companyId: string;
	company: {
		corporate_name: string;
		name: string;
		email: string;
		email_domain: string;
		cep: string;
		address: string;
		number: string;
		complement: string | undefined;
		district: string;
		city: string;
		uf: string;
		first_phone: string;
		second_phone: string | undefined;
		website: string | undefined;
		founding_date: string;
		business_type: string;
		main_activity: string;
	};
}

export interface UpdateCompanyAvatarParams {
	companyId: string;
	avatar: FormData;
}

export interface FilterCompaniesParams {
	groupCompanyId: string;
	companyFiltersParams: FilterParams[];
}
export interface RegistrationDates {
	registrationDate: string;
	preRegistrationDate: string;
}

export async function fetchCompanyRegistrationDates(
	company_id: string,
	cnpj: string
): Promise<RegistrationDates> {
	const { data } = await api.get<RegistrationDates>(
		`/api/v1/master/registration-dates/${company_id}/q?cnpj=${cnpj}`
	);
	return data;
}

export async function fetchCompany(companyId: string) {
	const { data } = await api.get(`/api/v1/master/companies/${companyId}`);

	return data;
}

export async function createCompany({
	groupCompanyId,
	companyData,
}: CreateCompanyParams): Promise<string> {
	await api.post(
		`/api/v1/master/groups-companies/${groupCompanyId}/companies`,
		companyData
	);

	return groupCompanyId;
}

export async function handleUpdateCompanyQuery({
	companyId,
	company,
}: UpdateCompanyParams): Promise<Company> {
	const { data } = await api.put<Company>(
		`/api/v1/companies/${companyId}`,
		company
	);

	return data;
}

export async function handleUpdateCompanyAvatarQuery({
	companyId,
	avatar,
}: UpdateCompanyAvatarParams) {
	const { data } = await api.patch<Company>(
		`/api/v1/master/companies/${companyId}/avatar`,
		avatar
	);

	return data;
}

export async function handleToggleStatus(company: Company): Promise<void> {
	await api.patch(`/api/v1/master/companies/${company!.id}/status`, {
		status: !company?.status,
	});
}

export async function updateBenefits({
	benefits,
	companyId,
}: UpdateBenefitParams): Promise<void> {
	await api.put(`/api/v1/master/companies/${companyId}/benefits`, { benefits });
}

export async function getFilteredCompanies({
	groupCompanyId,
	companyFiltersParams,
}: FilterCompaniesParams): Promise<Company[]> {
	const { data } = await api.get(
		`/api/v1/master/corporate-group/${groupCompanyId}/companies/filter/q`,
		{
			params: parseFilterParamsToParams(companyFiltersParams),
		}
	);

	return data;
}

export async function getCompanyScheduledPaymentsInAFewDays(
	companyId: string
): Promise<boolean> {
	const { data } = await api.get(
		`/api/v1/companies/${companyId}/releases/retrieves`
	);

	return data;
}

export async function getCompaniesSelect(): Promise<Company[]> {
	const { data } = await api.get('/api/v1/master/list-companies');

	return data.companies;
}

export async function updateCompanyProducts(
	companyId: string,
	products: ProductsFormData
): Promise<void> {
	return await api.put(`/api/v1/master/companies/${companyId}/products`, {
		...products,
	});
}

interface AllCompaniesResponse {
	companies: CompanyToSelect[];
	total_companies: number;
}

export async function getAllCompanies(
	filterParams: FilterParams[],
	page = 1
): Promise<AllCompaniesResponse> {
	const { data } = await api.get(`/api/v1/master/companies/all/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
		},
	});

	return data;
}
