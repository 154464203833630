import * as S from '../styles';
import Loader from '../../../../../components/Loader';
import { useQuery } from 'react-query';
import { FilterParams } from '../../../../../components/Filter';
import { toast } from 'react-toastify';
import {
	BillingListReturn,
	getBillingToReport,
} from '../../../../../services/queries/Billing';
import * as Excel from 'exceljs';
import { cnpjMask } from '../../../../../utils/masks';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';

interface ReportProps {
	filterParams: FilterParams[];
}

export function ReportGeneratorBillingExcel({ filterParams }: ReportProps) {
	const fetchBillingToReportExcelQuery = useQuery<BillingListReturn>(
		['billingListToReportExcel', filterParams],
		() => {
			return getBillingToReport(filterParams);
		},
		{
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGenerateXLSX() {
		try {
			let blob: Blob;
			blob = await generateSheetContent();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `relatorio_de_boletos.xlsx`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivos de relatório. Tente novamente'
			);
		}
	}

	async function generateSheetContent() {
		const reportFetching = await fetchBillingToReportExcelQuery.refetch();
		const { billings, totalBillings, totalReports } = reportFetching.data!;

		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet('Relatório de boletos');

		const currentTime = new Date();
		worksheet.addRow([
			`Gerado em ${currentTime.toLocaleDateString()} às ${currentTime.toLocaleTimeString(
				'pt-BR',
				{
					timeZone: 'America/Sao_Paulo',
					hour: '2-digit',
					minute: '2-digit',
				}
			)} no Bounty admin.`,
		]);
		let dateMessage = 'Todo o período';

		const dateFilter = filterParams.find(
			(filter) => filter.name === 'Data' && filter.selected
		);

		if (dateFilter) {
			const formattedStartDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.value.replace(/-/g, '/')));

			const formattedEndDate = Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(dateFilter.second_value!.replace(/-/g, '/')));

			dateMessage = `${formattedStartDate} - ${formattedEndDate}`;
		}

		worksheet.addRow([`Intervalo de busca: ${dateMessage}`]);
		worksheet.addRow([`N° de pedido: ${totalReports}`]);
		worksheet.addRow([]);

		worksheet.addRow([`Total de registros: ${totalBillings}`]);
		worksheet.addRow([]);

		let columns = ['DATA', 'EMPRESA', 'CNPJ', 'PRODUTO', 'VALOR', 'STATUS'];

		// list headers
		worksheet.addRow(columns);

		const columnsIndexes = [1, 2, 3, 4, 5, 6];

		columnsIndexes.forEach((colIndex) => {
			const col = worksheet.getColumn(colIndex);
			col.width = 25;
		});

		if (billings) {
			billings.forEach((billing) => {
				const row = [
					new Date(billing.created_at).toLocaleDateString(),
					billing.company?.name,
					cnpjMask(billing.company?.cnpj ?? 'N/A'),
					billing.origin === 'corpway'
						? 'Bounty Control'
						: 'Benefícios Flexíveis',
					convertCentsToReais(Number(billing.amount)),
					billing.status === 'paid' ? 'PAGO' : 'EMITIDO',
				];
				worksheet.addRow(row);
			});
		}

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	return (
		<>
			<S.ReportButton onClick={handleGenerateXLSX}>
				{fetchBillingToReportExcelQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório XLSX'
				)}
			</S.ReportButton>
		</>
	);
}
