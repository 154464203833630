import styled, { css } from 'styled-components';

export const Container = styled.header`
	background: var(--primary-red);
	height: 6rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem;
	& > div {
		display: flex;
		align-items: center;
		column-gap: 2rem;
		height: 100%;
	}
`;

export const LogoImage = styled.img`
	width: 10rem;
	object-fit: contain;
	padding: 1rem 0;
`;

export const DropdownContainerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;
	margin-top: 0.5rem;
	background-color: var(--white);
	border-radius: 0.8rem;
	min-width: 25rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	& a,
	button {
		&:hover {
			filter: none;
		}
	}
`;

export const DropdownContainer = styled.div`
	${DropdownContainerStyles}
`;

export const DropdownNotificationsContainer = styled.div`
	${DropdownContainerStyles}
	padding: 0;
	background-color: #f5f5f5;
	margin-right: 2rem;
	border-radius: 0;
	padding-right: 0.2rem;
	border: 0.24rem solid #85d6ff;
`;

export const Separator = styled.div`
	height: 0.1rem;
	width: 100%;
	background-color: var(--dark-gray);
	margin: 1rem 0;
`;

interface BalanceContainerProps {
	showBalance: boolean;
}
export const BalanceContainer = styled.div<BalanceContainerProps>`
	font-size: 1.5rem;
	height: 100%;
	display: flex;
	align-items: center;
	background-color: var(--primary-blue);
	color: #3d3e40;
	padding: 0 2rem;
	margin-right: 2rem;

	& > div {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		margin-left: 1.5rem;
		& > div {
			display: flex;
			flex-direction: row;
		}
	}
`;

export const BalanceValueContainer = styled.span<BalanceContainerProps>`
	width: 12rem;
	height: 2rem;
	border-radius: 0.4rem;
	font-weight: bold;
	background-color: ${({ showBalance }) =>
		showBalance ? 'transparent' : '#2196DE'};
	font-size: ${({ showBalance }) => (showBalance ? '1.6rem' : '0px')};
`;

export const ToggleBalanceVisibilityBtn = styled.button`
	background: none;
	display: flex;
	align-items: center;
	margin-right: 1.5rem;
	& svg {
		width: 2rem;
		height: 2rem;
		transition: filter 0.2s;
		color: #3d3e40;
		&:hover {
			filter: opacity(0.7);
		}
	}
`;

export const IconBtn = styled.button`
	display: flex;
	background: none;
	border: none;
	border-radius: 50%;
	color: var(--white);
	font-size: 2.5rem;
	padding: 0.6rem;
	position: relative;
	& span {
		position: absolute;
		font-size: 1.4rem;
		font-weight: bolder;
		right: 0;
		background-color: var(--primary-blue);
		min-width: 2.2rem;
		max-width: 3rem;
		aspect-ratio: 1 / 1;
		padding: 0.2rem;
		border-radius: 50%;
		border: 0.1rem solid var(--white);
		margin-right: -1rem;
		top: 0;
		margin-top: -0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const ProfileBtn = styled.button`
	display: flex;
	background: none;
	border: none;
	color: var(--dark-gray);
	font-size: 2.5rem;
`;
export const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	margin: auto 1rem;
`;

export const TextName = styled.span`
	color: var(--white);
	font-size: 1.4rem;
`;

export const UserImageSmall = styled.img`
	border-radius: 50%;
	height: 4rem;
	width: 4rem;
	background-color: var(--primary-blue);
	margin-left: 0.5rem;
`;

export const UserImage = styled.img`
	/* border: 0.2rem solid var(--primary-blue); */
	border-radius: 50%;
	height: 6rem;
	width: 6rem;
	background-color: var(--primary);
	/* object-fit:contain; */
`;

export const ProfileTextNameDrop = styled.span`
	color: var(--primary);
	font-size: 1.6rem;
	font-weight: 500;
	align-self: center;
	margin-top: 1rem;
`;
export const ProfileTextEmailDrop = styled.span`
	color: var(--primary);
	font-size: 1.2rem;
	align-self: center;
	margin-bottom: 1rem;
`;

export const ProfileOptionButton = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	text-align: start;
	padding: 0.5rem;
	border-radius: 0.4rem;
	color: var(--black);
	font-size: 1.4rem;
	& svg {
		margin-right: 1rem;
	}
`;

export const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
