import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTitle from '../../../../../components/PageTitle';
import {
	getCompanyPartners,
	updateCompanyPartnerStatus,
	UpdateCompanyPartnerStatusRequest,
} from '../../../../../services/queries/Partners';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import * as S from './styles';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import { useForm } from 'react-hook-form';
import { EmptyContent } from '../../../../../components/EmptyContent';
import Loader from '../../../../../components/Loader';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';

interface CompanyPartnersStatusFormData {
	[key: string]: boolean;
}

export function ManageCompanyPartners() {
	const queryClient = useQueryClient();
	const { companyId } = useParams<{ companyId: string }>();
	const { register, handleSubmit, reset } =
		useForm<CompanyPartnersStatusFormData>();

	const getCompanyPartnersQuery = useQuery(
		['fetch-partners', companyId],
		() => getCompanyPartners(companyId!),
		{
			onSuccess: () => {
				reset();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os parceiros da empresa. '
				);
			},
			enabled: !!companyId,
		}
	);

	const updateCompanyPartnersMutation = useMutation(
		(body: UpdateCompanyPartnerStatusRequest) =>
			updateCompanyPartnerStatus(body),
		{
			onSuccess: () => {
				queryClient.resetQueries('fetch-partners');
				toast.info('Parceiros da empresa atualizada com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar os parceiros da empresa. '
				);
			},
		}
	);

	function handleFormSubmit(data: CompanyPartnersStatusFormData) {
		// the format of the data is { check-{id}: boolean }
		const partnersParams = Object.keys(data).map((partnerId) => {
			const id = partnerId.replace('check-', '');
			return { id, status: data[partnerId] };
		});

		updateCompanyPartnersMutation.mutate({
			companyId: companyId!,
			partners_params: partnersParams,
		});
	}

	if (
		getCompanyPartnersQuery.isLoading ||
		!getCompanyPartnersQuery.data ||
		updateCompanyPartnersMutation.isLoading
	)
		return (
			<S.Container>
				<PageTitle title='Parceiros disponíveis' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<PageTitle
				title={`Parceiros disponíveis - ${getCompanyPartnersQuery.data.company_data.name}`}
			/>
			{getCompanyPartnersQuery.data.partners_params.length === 0 ? (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			) : (
				<form onSubmit={handleSubmit(handleFormSubmit)}>
					<TableStyle.Table>
						<TableStyle.TableHeader>
							<TableStyle.TableRow>
								<TableStyle.TableHeaderCell>
									PARCEIRO
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									DESCRIÇÃO
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									CATEGORIA
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>
									ATRIBUIDO
								</TableStyle.TableHeaderCell>
								{/* <TableStyle.TableHeaderCell>ATRIBUIDO DESDE</TableStyle.TableHeaderCell> */}
							</TableStyle.TableRow>
						</TableStyle.TableHeader>

						<TableStyle.TableBody>
							{getCompanyPartnersQuery.data.partners_params.map((partner) => (
								<TableStyle.TableRow key={partner.partner_id}>
									<TableStyle.TableData>
										{partner.partner_name}
									</TableStyle.TableData>
									<TableStyle.TableData>
										{partner.partner_description}
									</TableStyle.TableData>
									<TableStyle.TableData>
										{partner.partner_category}
									</TableStyle.TableData>
									<TableStyle.TableData>
										<TableStyle.StatusContainer
											style={{ justifyContent: 'center' }}
										>
											<ToggleSwitch
												name={`check-${partner.partner_id}`}
												defaultChecked={partner.partner_enabled}
												register={register}
											/>
										</TableStyle.StatusContainer>
									</TableStyle.TableData>
								</TableStyle.TableRow>
							))}
						</TableStyle.TableBody>
					</TableStyle.Table>

					<S.SubmitBtn type='submit'>Salvar</S.SubmitBtn>
				</form>
			)}
		</S.Container>
	);
}
