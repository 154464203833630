import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Company } from '../../@types';
import { cepMask, phoneMask, cnpjMask } from '../../utils/masks';
import { parseDateToInput } from '../../utils/parseDate';
import { ShareholdersModal } from './ShareholdersModal';
import { useCompanyForm } from '../../hooks/useCompanyForm';
import Loader from '../Loader';
import { RiUploadLine } from 'react-icons/ri';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as FormStyle from '../Form/FormStyles';
import { useParams } from 'react-router-dom';
import { KYCFieldsIndication, KYCFieldsIndicator } from '../KYCFieldsIndicator';
import { UF_List } from '../../utils/CheckUF';
import PreventTransitionPrompt from '../PreventTransitionPrompt';
import { toast } from 'react-toastify';
import { useDialogModal } from '../../hooks/useDialogModal';
import { useQuery } from 'react-query';
import { getCompanyScheduledPaymentsInAFewDays } from '../../services/queries/Companies';
import {
	addressesFieldSWAPRegex,
	addressNumberSWAPRegex,
	cepRegex,
	cnpjRegex,
	onlyLettersAndSpacesRegex,
	phoneRegex,
	websiteRegex,
} from '../../utils/patterns';
import { trimObjectData } from '../../utils/trimObjectData';
import { SearchCEPButton } from '../SearchCEPButton';
import { useGetAddressByCEP } from '../../hooks/useGetAddressByCEP';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputField from '../../componentsV2/ui/InputField';
import SelectInput from '../../componentsV2/ui/SelectInput';

const companySchema = z.object({
	name: z
		.string()
		.min(1, 'A Razão Social é obrigatória')
		.max(70, 'A Razão Social deve ter no máximo 70 caracteres'),
	email: z.string().min(1, 'Email é obrigatório').email('Email inválido'),
	corporate_name: z
		.string()
		.min(1, 'Nome Fantasia é obrigatório')
		.max(70, 'O Nome Fantasia deve ter no máximo 70 caracteres'),
	cnpj: z
		.string()
		.min(1, 'CNPJ é obrigatório')
		.regex(cnpjRegex, 'CNPJ inválido'),
	founding_date: z.string().min(1, 'Data de Fundação é obrigatória'),
	business_type: z
		.string()
		.min(1, 'O Segmento Comercial é obrigatório')
		.max(255, 'O Segmento Comercial deve ter no máximo 255 caracteres'),
	main_activity: z
		.string()
		.min(1, 'O Número do CNAE é obrigatório')
		.max(50, 'O Número do CNAE deve ter no máximo 50 caracteres'),
	website: z
		.string()
		.max(255, 'O Website deve ter no máximo 255 caracteres')
		.regex(websiteRegex, 'Website inválido')
		.optional()
		.or(z.literal('')),
	financial_sector_email: z
		.string()
		.min(1, 'Email do Setor Financeiro é obrigatório')
		.email('Email inválido'),
	hr_sector_email: z
		.string()
		.min(1, 'Email do Setor de Recursos Humanos é obrigatório')
		.email('Email inválido'),
	address: z
		.string()
		.min(1, 'Endereço é obrigatório')
		.max(50, 'O Endereço deve ter no máximo 50 caracteres')
		.regex(
			addressesFieldSWAPRegex,
			"Utilize apenas letras, espaços e (.)(,)(')(/)(-)(_) no campo de endereço"
		),
	number: z
		.string()
		.min(1, 'Número é obrigatório')
		.max(10, 'O Número deve ter no máximo 10 caracteres')
		.regex(addressNumberSWAPRegex, 'Utilize apenas letras e números'),
	district: z
		.string()
		.min(1, 'Bairro é obrigatório')
		.max(30, 'O Bairro deve ter no máximo 30 caracteres')
		.regex(
			addressesFieldSWAPRegex,
			"Utilize apenas letras, espaços e (.)(,)(')(/)(-)(_) no campo de bairro"
		),
	cep: z.string().min(1, 'CEP é obrigatório').regex(cepRegex, 'CEP inválido'),
	complement: z
		.string()
		.max(30, 'O Complemento deve ter no máximo 30 caracteres')
		.regex(
			addressesFieldSWAPRegex,
			"Utilize apenas letras, espaços e (.)(,)(')(/)(-)(_) no campo de bairro"
		)
		.optional()
		.or(z.literal('')),
	reference: z
		.string()
		.max(30, 'A Referência deve ter no máximo 30 caracteres')
		.optional()
		.or(z.literal('')),
	city: z
		.string()
		.min(1, 'Cidade é obrigatória')
		.max(30, 'A Cidade deve ter no máximo 30 caracteres')
		.regex(
			onlyLettersAndSpacesRegex,
			'Utilize apenas letras, espaços no campo de cidade'
		),
	uf: z.string().min(1, 'UF é obrigatória'),
	first_phone: z
		.string()
		.min(1, 'Telefone é obrigatório')
		.regex(phoneRegex, 'Telefone inválido'),
	second_phone: z
		.string()
		.regex(phoneRegex, 'Telefone inválido')
		.optional()
		.or(z.literal('')),
});
export const WARNING_MESSAGE_NO_SCHEDULED_PAYMENTS =
	'Olá, usuário. A alteração solicitada exigirá uma nova análise de KYC da companhia, o que pode acarretar em impossibilidade de executar algumas ações, como programar pagamentos. Isso ocorrerá se algum dos dados atualizados estiver incorreto e permanecerá até regularização das informações e aprovação na análise de KYC. Deseja continuar com esta atualização?';
export const WARNING_MESSAGE_SCHEDULED_PAYMENTS =
	'Olá, usuário. Existem solicitações de pagamentos agendadas pela sua empresa para os próximos dias. A alteração solicitada exigirá uma nova análise de KYC da companhia, o que pode acarretar em impossibilidade de executar os pagamentos já programados. Isso ocorrerá se algum dos dados atualizados estiver incorreto e permanecerá até regularização das informações e aprovação na análise de KYC. Deseja continuar com esta atualização?';

interface CompanyFormProps {
	update: boolean;
}

export function CompanyForm({ update }: CompanyFormProps) {
	const {
		loading,
		company,
		toggleStatus,
		submitCompany,
		updateCompany,
		handleUpdateAvatar,
	} = useCompanyForm();
	const [avatarImg, setAvatarImg] = useState<File>();
	const { groupCompanyId } = useParams();
	const { openOptionsDialog } = useDialogModal();
	const { register, handleSubmit, reset, formState, getValues } =
		useForm<Company>({
			resolver: zodResolver(companySchema),
			defaultValues: {
				...company,
				cnpj: cnpjMask(company?.cnpj || ''),
				cep: cepMask(company?.cep || ''),
				first_phone: phoneMask(company?.first_phone || ''),
				second_phone: phoneMask(company?.second_phone || ''),
				founding_date: parseDateToInput(company?.founding_date || ''),
				uf: company?.uf || '',
			},
		});

	const { isDirty, errors } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!(isDirty || avatarImg));
	}, [isDirty, avatarImg]);

	useEffect(() => {
		if (company?.id)
			reset({
				...company,
				cnpj: cnpjMask(company?.cnpj || ''),
				cep: cepMask(company?.cep || ''),
				first_phone: phoneMask(company?.first_phone || ''),
				second_phone: phoneMask(company?.second_phone || ''),
				founding_date: parseDateToInput(company?.founding_date || ''),
				uf: company?.uf || '',
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company]);

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	const fetchCompanyScheduledPaymentsQuery = useQuery(
		'companyScheduledPayments',
		() => {
			return getCompanyScheduledPaymentsInAFewDays(company!.id!);
		},
		{
			enabled: false,
		}
	);

	function onAvatarUpload(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.files!.length === 0) {
			setAvatarImg(undefined);
			return;
		}

		const file = e.target.files![0];
		const fileMb = file.size / 1024 ** 2;
		const avatarMbLimit = 1;
		if (fileMb > avatarMbLimit) {
			toast.error('Não é possível enviar imagens maiores que 1MB.');
			return;
		}

		setAvatarImg(file);
	}

	async function handleUpdateCompany(data: Company) {
		if (avatarImg) {
			await handleUpdateAvatar(avatarImg);
		}

		if (isDirty) {
			await updateCompany(trimObjectData(data));
		}

		toast.info('Informações da empresa atualizadas com sucesso!');
	}

	async function handleSubmitForm(data: Company) {
		if (!update) {
			submitCompany(groupCompanyId!, trimObjectData(data), avatarImg);
		} else {
			if (!isDirty && !avatarImg) {
				toast.error(
					'Modifique alguma informação antes de requisitar a atualização!'
				);
				return;
			}
			// fetch if company has payments scheduled in a few day warning
			const scheduledPaymentsInAFewDays = (
				await fetchCompanyScheduledPaymentsQuery.refetch()
			).data;

			openOptionsDialog(
				scheduledPaymentsInAFewDays
					? WARNING_MESSAGE_SCHEDULED_PAYMENTS
					: WARNING_MESSAGE_NO_SCHEDULED_PAYMENTS,
				'Confirmar',
				async () => await handleUpdateCompany(data),
				'Cancelar',
				() => {}
			);
		}
	}

	if (loading || fetchCompanyScheduledPaymentsQuery.isLoading) {
		return <Loader />;
	}

	return (
		<>
			<PreventTransitionPrompt when={!allowNavigation} />

			<FormStyle.Form
				onSubmit={handleSubmit(handleSubmitForm)}
				autoComplete='off'
				autoCorrect='off'
				data-testid='company_form_test_id'
			>
				<FormStyle.FormContainer>
					{/* COMPANY DATA FIELDSET */}
					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={70}
								register={register}
								name='name'
								label={
									<>
										Razão Social <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='nameInput_test_id'
								errorMessage={errors.name?.message}
							/>
							<InputField
								type='email'
								register={register}
								name='email'
								label={
									<>
										Email <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='emailInput_test_id'
								errorMessage={errors.email?.message}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={70}
								register={register}
								name='corporate_name'
								label={
									<>
										Nome Fantasia <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='fantasyInput_test_id'
								errorMessage={errors.corporate_name?.message}
							/>
							<InputField
								register={register}
								name='cnpj'
								label={
									<>
										CNPJ <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = cnpjMask(value);
								}}
								data-testid='cnpjInput_test_id'
								disabled={update}
								errorMessage={errors.cnpj?.message}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								type='date'
								register={register}
								name='founding_date'
								label={
									<>
										Data de Fundação <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								max={new Date().toISOString().split('T')[0]}
								data-testid='foundingInput_test_id'
								errorMessage={errors.founding_date?.message}
							/>
							<InputField
								maxLength={255}
								register={register}
								name='business_type'
								label={
									<>
										Segmento Comercial <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='businessInput_test_id'
								errorMessage={errors.business_type?.message}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={50}
								register={register}
								name='main_activity'
								label={
									<>
										Número do CNAE <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='activityInput_test_id'
								errorMessage={errors.main_activity?.message}
							/>
							<InputField
								maxLength={255}
								register={register}
								name='website'
								label={
									<>
										Website <KYCFieldsIndicator />
									</>
								}
								placeholder='Ex: www.exemplo.com'
								data-testid='websiteInput_test_id'
								errorMessage={errors.website?.message}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								type='email'
								register={register}
								name='financial_sector_email'
								label={
									<>
										Email do Setor Financeiro <ObrigatoryIndicator />
									</>
								}
								data-testid='financial_sector_emailInput_test_id'
								errorMessage={errors.financial_sector_email?.message}
							/>
							<InputField
								type='email'
								register={register}
								name='hr_sector_email'
								label={
									<>
										Email do Setor de Recursos Humanos <ObrigatoryIndicator />
									</>
								}
								data-testid='hr_sector_emailInput_test_id'
								errorMessage={errors.hr_sector_email?.message}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.ImageUploadContainer>
									<FormStyle.Label>Logo</FormStyle.Label>
									{
										// PREVIEW IMAGEM
										avatarImg !== undefined ? (
											<FormStyle.ImagePreview
												src={URL.createObjectURL(avatarImg)}
											/>
										) : (
											company?.avatar_url && (
												<FormStyle.ImagePreview src={company.avatar_url} />
											)
										)
									}
									<>
										<FormStyle.UploadIcon htmlFor='logo'>
											<RiUploadLine />
										</FormStyle.UploadIcon>
										<input
											type='file'
											id='logo'
											onChange={onAvatarUpload}
											name='logo'
											multiple={false}
											accept='.jpg, .jpeg, .png'
											style={{ display: 'none' }}
											data-testid='imageInput_test_id'
										/>
									</>
								</FormStyle.ImageUploadContainer>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>

					<FormStyle.Divider />

					{/* ADDRESS */}
					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={50}
								register={register}
								name='address'
								label={
									<>
										Endereço <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='addressInput_test_id'
								errorMessage={errors.address?.message}
							/>
							<InputField
								maxLength={10}
								register={register}
								name='number'
								label={
									<>
										Número <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='numberInput_test_id'
								errorMessage={errors.number?.message}
								containerStyle={{ maxWidth: '25%' }}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={30}
								register={register}
								name='district'
								label={
									<>
										Bairro <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='districtInput_test_id'
								errorMessage={errors.district?.message}
							/>
							<InputField
								register={register}
								name='cep'
								label={
									<>
										CEP <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								placeholder='Ex: 99999-999'
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = cepMask(value);
								}}
								containerStyle={{ maxWidth: '25%' }}
								data-testid='cepInput_test_id'
								errorMessage={errors.cep?.message}
							/>
							<SearchCEPButton
								isLoading={isSearchingCEP}
								onClick={() => searchAddressByCEP(getValues().cep)}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={30}
								register={register}
								name='complement'
								label='Complemento'
								data-testid='complementInput_test_id'
								errorMessage={errors.complement?.message}
							/>
							<InputField
								maxLength={30}
								register={register}
								name='reference'
								label='Referência'
								data-testid='referenceInput_test_id'
								errorMessage={errors.reference?.message}
							/>
						</FormStyle.FieldGroup>
						<FormStyle.FieldGroup>
							<InputField
								maxLength={30}
								register={register}
								name='city'
								label={
									<>
										Cidade <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='cityInput_test_id'
								errorMessage={errors.city?.message}
								containerStyle={{ maxWidth: '38%' }}
							/>
							<SelectInput
								register={register}
								name='uf'
								label={
									<>
										UF <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								data-testid='ufInput_test_id'
								errorMessage={errors.uf?.message}
								containerStyle={{ maxWidth: '10%' }}
							>
								<option disabled value=''>
									Selecione uma opção
								</option>
								{UF_List.map((uf) => (
									<option key={uf} value={uf}>
										{uf}
									</option>
								))}
							</SelectInput>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>

					<FormStyle.Divider />

					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<InputField
								type='tel'
								register={register}
								name='first_phone'
								label={
									<>
										Telefone 1 <ObrigatoryIndicator />
										<KYCFieldsIndicator />
									</>
								}
								placeholder='Ex: +99 99 99999-9999'
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = phoneMask(value);
								}}
								data-testid='firstPhoneInput_test_id'
								errorMessage={errors.first_phone?.message}
							/>
							<InputField
								type='tel'
								register={register}
								name='second_phone'
								label='Telefone 2'
								placeholder='Ex: +99 99 99999-9999'
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = phoneMask(value);
								}}
								data-testid='secondPhoneInput_test_id'
								errorMessage={errors.second_phone?.message}
							/>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>

					<FormStyle.Divider />

					<ObrigatoryFieldsIndication />
					<KYCFieldsIndication />

					<FormStyle.ButtonsContainer>
						<FormStyle.LeftButtonsContainer>
							<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
							{update && (
								<>
									<FormStyle.Option
										onClick={(e) => {
											e.preventDefault();
											toggleStatus.mutate();
										}}
									>
										{company?.status ? 'Desativar' : 'Ativar'}
									</FormStyle.Option>
								</>
							)}
						</FormStyle.LeftButtonsContainer>
						<ShareholdersModal />
					</FormStyle.ButtonsContainer>
				</FormStyle.FormContainer>
			</FormStyle.Form>
		</>
	);
}
