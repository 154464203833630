import { Billing } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface BillingListReturn {
	billings: Billing[];
	totalBillings: number;
	totalReports?: number;
}

export async function getBilling(
	billingsCurrentPage: number,
	sortColumn: SortColumn | null
): Promise<BillingListReturn> {
	const { data } = await api.get(`api/v1/master/billing/q`, {
		params: {
			page: billingsCurrentPage,
			...parseSortColumnToParams(sortColumn),
		},
	});

	return {
		billings: data.billings,
		totalBillings: data.totalBillings,
	};
}

export async function getFilteredBilling(
	filterParams: FilterParams[],
	page = 1,
	sortColumn: SortColumn | null
): Promise<BillingListReturn> {
	const { data } = await api.get(`/api/v1/master/billing/filter/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function getBillingToReport(
	filterParams: FilterParams[]
): Promise<BillingListReturn> {
	const { data } = await api.get(`/api/v1/master/billing/report/q?`, {
		params: {
			...parseFilterParamsToParams(filterParams),
		},
	});

	return data;
}

interface GenerateQRCodeResponse {
	amount: number;
	emv: string;
	id: string;
	pma_id: string;
	transaction_identification: string;
}
export async function generateQRCode(amount: number) {
	const { data } = await api.post<GenerateQRCodeResponse>(
		`/api/v1/master/billing/pix`,
		{
			amount,
		}
	);

	return data;
}

interface GenerateBillingResponse {
	amount: number;
	company_id: string;
	status: string;
	id_swap: string;
	id_swap_external: string;
}
export async function generateBilling(amount: number) {
	const { data } = await api.post<GenerateBillingResponse>(
		`/api/v1/master/billing/boleto`,
		{
			amount,
		}
	);
	return data;
}
