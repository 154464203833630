import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const Button = styled.button`
	${OptionsButton}
	align-self: flex-start;
	width: 16rem;
`;

export const ButtonModal = styled.button`
	width: 25rem;
	height: 4.5rem;
	padding: 12px 32px 12px 32px;
	gap: 12px;
	border-radius: 50rem;
	opacity: 0px;
	border: 1px solid #191919;
	background: #fff;
	color: #393939;
	font-weight: 600;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 40rem;
	height: 25rem;
	font-size: 1.4rem;
	border-top: 0.2rem solid var(--light-gray);
	align-items: center;
	padding: 2rem 0;
	gap: 1rem;
`;

export const FieldGroup = styled.div`
	display: flex;
	width: 100%;
`;

export const Title = styled.h1`
	font-size: 2rem;
	font-weight: bold;
	color: inherit;
	display: flex;
	align-items: center;
	& > img:first-child {
		margin-right: 1rem;
	}
`;

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 0 0.5rem 1.2rem 0.5rem;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}

	> span {
		color: var(--primary-red);
		margin-top: 0.5rem;
	}
`;

export const Label = styled.label`
	font-size: 1.4rem;
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
`;

export const Input = styled.input`
	width: 100%;
	height: 3rem;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 1);
	border-radius: 0.4rem;
	border: none;
	padding: 0 1rem;

	&::placeholder {
		color: var(--dark-gray);
	}
`;
