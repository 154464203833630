import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 53rem;
	height: 33rem;
	font-size: 1.4rem;
	padding: 5rem;
	border-radius: 2rem;
`;

export const SubContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;
	opacity: 0px;
`;

export const FieldGroup = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const Label = styled.label`
	font-size: 1.4rem;
	margin-bottom: 0.5rem;
	color: #393939;
	font-weight: 400;
`;

export const Title = styled.h1`
	font-size: 2.4rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	color: inherit;
	display: flex;
	align-items: center;
	& > img:first-child {
		margin-right: 1rem;
	}
`;

export const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	background-color: var(--white);
	padding-top: 2rem;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 2rem;
	margin-top: 2rem;
`;

export const CancelOption = styled.button`
	${OptionsButton}
	border: 1px solid #929292;
	background: #ffffff;
	width: 20rem;
	border-radius: 5rem;
	color: #393939;
`;
export const TransactionButton = styled.button`
	${OptionsButton}
	width: 20rem;
	border-radius: 5rem;
	background: var(--primary-blue);
	border: 1px solid #929292;
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	width: 20rem;
	border-radius: 5rem;
	background: var(--primary-blue);
	border: 1px solid #929292;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 3rem;
	margin-top: 3rem;
`;

export const InfoRow = styled.div`
	display: flex;
	justify-content: space-between;

	span {
		font-weight: normal;
	}

	strong {
		font-weight: bold;
	}
`;
