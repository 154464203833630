import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { useSetHistoryNonMatchCallbackLocation } from '../../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useFundsOutStore } from '../../../../stores/useFundsOutStore';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import {
	FundsOutListReturn,
	getFundsOut,
} from '../../../../services/queries/FundsOut';
import PageTitle from '../../../../components/PageTitle';
import Loader from '../../../../components/Loader';
import * as S from './styles';
import * as TableStyle from '../../../../components/Table/TableStyles';
import { FundsOut } from '../../../../@types';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Filter } from '../../../../components/Filter';
import { Pagination } from '../../../../components/Pagination';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { formatDateDDMMYYY } from '../../../../utils/parseDate';
import CompanyCard from '../../../../components/CompanyCard';
import { MdNotes } from 'react-icons/md';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { parseFundsOutStatus } from './utils/parseFundsOutData';
import { ReportGeneratorFundsOutPDF } from './ReportGeneratorPDF';

export function FundsOuts() {
	const queryClient = useQueryClient();
	const [fundsOutCurrentPage, setFundsOutCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();

	const [filtersParams, setFiltersParams, resetFilters] = useFundsOutStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useSetHistoryNonMatchCallbackLocation('funds-out', resetFilters);

	const fetchFundsOutQuery = useQuery<FundsOutListReturn, Error>(
		['fundsOutList', filtersParams, fundsOutCurrentPage, currentSortColumn],
		() => {
			return getFundsOut(filtersParams, fundsOutCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de TEDs. '
				);
			},
		}
	);

	function refreshPage() {
		setFundsOutCurrentPage(1);
		queryClient.resetQueries('fundsOutList');
	}

	function generateRow(fundsOut: FundsOut) {
		return (
			<TableStyle.TableRow key={fundsOut.id}>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					<S.BoldText>{formatDateDDMMYYY(fundsOut.created_at)}</S.BoldText>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{fundsOut.company ? (
						<CompanyCard
							company_name={fundsOut.company.name}
							cnpj={fundsOut.company.cnpj}
							avatar_url={fundsOut.company.avatar_url}
						/>
					) : (
						'N/A'
					)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '20rem' }}>
					{fundsOut.description}
				</TableStyle.TableData>

				<TableStyle.TableData>
					{parseFundsOutStatus(fundsOut.status)}
				</TableStyle.TableData>

				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{convertCentsToReais(Number(fundsOut.amount))}
				</TableStyle.TableData>

				<TableStyle.TableData>
					<TableStyle.OptionLink to={`/funds-out/${fundsOut.id}`}>
						<MdNotes />
					</TableStyle.OptionLink>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(fundsOut: FundsOut[]) {
		if (!fundsOut.length) {
			return (
				<>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title='Histórico de TEDs'
								totalRecords={fetchFundsOutQuery.data?.total_fundsOut}
							/>
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setFundsOutCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TableHeaderContainer>

					<EmptyContent text='Histórico de TEDs vazio.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Histórico de TEDs'
							totalRecords={fetchFundsOutQuery.data!.total_fundsOut}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setFundsOutCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
					</TableStyle.TitleWrapper>
					<Pagination
						onPageChange={(page) => setFundsOutCurrentPage(page)}
						currentPage={fundsOutCurrentPage}
						totalCount={
							fetchFundsOutQuery.data
								? fetchFundsOutQuery.data.total_fundsOut
								: 1
						}
					/>
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'description'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('description')}
								/>
								DESCRIÇÃO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'status'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('status')}
								/>
								STATUS
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount')}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>DETALHES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{fundsOut.map((fundOut) => generateRow(fundOut))}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		!fetchFundsOutQuery.data ||
		fetchFundsOutQuery.isLoading ||
		fetchFundsOutQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Histórico de TEDs' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(fetchFundsOutQuery.data.fundsOut)}

			<S.ButtonsContainer>
				{filtersParams.find((f) => f.value) &&
					!!fetchFundsOutQuery.data.fundsOut.length && (
						<ReportGeneratorFundsOutPDF
							filterParams={filtersParams}
							sortColumn={currentSortColumn}
						/>
					)}
			</S.ButtonsContainer>
		</S.Container>
	);
}
