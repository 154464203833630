import { StyleSheet } from '@react-pdf/renderer';

export const fundsOutReportStyle = StyleSheet.create({
	page: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		columnGap: 10,
		marginBottom: 15,
		paddingHorizontal: 25,
		paddingTop: 25,
	},
	logo: {
		width: '65%',
		objectFit: 'contain',
		marginRight: 40,
	},
	headerInfo: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
	},
	headerInfoLabel: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 9,
	},
	headerInfoData: {
		display: 'flex',
		flexDirection: 'column',
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 9,
		fontWeight: 600,
	},

	subHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingHorizontal: 25,
		paddingVertical: 20,
		backgroundColor: '#F0F3F8',
	},
	titleContainer: {
		width: '40%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: 15,
	},
	reportTitleContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	reportTitle: {
		color: '#393939',
		fontFamily: 'Poppins',
		fontSize: 16,
		fontWeight: 600,
	},
	reportLogo: {
		width: 35,
		objectFit: 'contain',
	},
	subheaderDataContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		columnGap: 15,
		rowGap: 12,
		marginLeft: '50px',
	},
	subheaderInfo: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: 3,
	},
	subheaderLabel: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 10,
		fontWeight: 600,
	},
	subheaderData: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 10,
		maxWidth: '90%',
	},

	tableContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		paddingHorizontal: 25,
	},
	tableHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		columnGap: 10,
		marginTop: 10,
	},
	columnTitleContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		columnGap: 5,
	},
	columnTitle: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 11,
		fontWeight: 500,
	},
	tableRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		columnGap: 10,
		marginTop: 8,
	},
	amountContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	columnData: {
		width: '100%',
		maxHeight: '100px',
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 11,
		overflow: 'hidden',
	},
});
