import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Company } from '../../../../../@types';
import * as S from './styles';
import PageTitle from '../../../../../components/PageTitle';
import { SelectCompany } from '../../../../../components/SelectCompany';
import { useForm } from 'react-hook-form';
import { ObrigatoryIndicator } from '../../../../../components/ObrigatoryFieldsIndicator';
import { toast } from 'react-toastify';
import { createDispute } from '../../../../../services/queries/Disputes';
import { useNavigate } from 'react-router-dom';
import { convertReaisToCents } from '../../../../../utils/CurrencyConvert';
import { SelectCardModal } from './SelectCardModal';
import { CardTransactionDisputeDTO } from './CardTransactionDisputeDTO';
import { ConfirmationSwapRequiredModal } from './ConfirmationSwapRequiredModal';
import { maskCardNumber } from '../../../../../utils/masks';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';

export type LocalProductOrigin = 'multiflex' | 'corpway' | '';

export function CreateDispute() {
	const [value, setInputValue] = useState(0);
	const [isOpen, setOpen] = useState(false);
	const [isCardModal, setCardModal] = useState(false);

	const [selectedProduct, setSelectedProduct] = useState<
		LocalProductOrigin | ''
	>('');
	const [availableProducts, setAvailableProducts] = useState<
		LocalProductOrigin[]
	>([]);

	const navigate = useNavigate();
	const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
	const { register, setValue, handleSubmit, watch, getValues } =
		useForm<CardTransactionDisputeDTO>({
			defaultValues: {
				company_id: '',
				product: '',
				card_id: '',
				amount: 0,
				start_date_dispute: '',
				dispute_description: '',
				transaction_id: '',
				swap_required: '',
			},
		});

	const [selectedCard, setSelectedCard] = useState<{
		id: string;
		pan: string;
	} | null>(null);

	function handleSelectCompany(company: Company) {
		if (company?.id) {
			setSelectedCompany(company);
			setValue('company_id', company.id);
			const products: LocalProductOrigin[] = [];

			if (company.company_products?.product_flexible_benefits_enabled) {
				products.push('multiflex');
			}
			if (company.company_products?.product_corporate_expenses_enabled) {
				products.push('corpway');
			}
			if (products.length === 0) {
				toast.error('Nenhum produto disponível para a empresa selecionada.');
			}
			setAvailableProducts(products);
			setSelectedProduct(products[0] || '');
			setValue('product', products[0] || '');
		}
	}

	const swapRequired = watch('swap_required');

	useEffect(() => {
		setValue('product', selectedProduct || '');
	}, [selectedProduct, setValue]);

	useEffect(() => {
		setValue('amount', value);
	}, [value, setValue]);

	useEffect(() => {
		setSelectedCard(null);
		setValue('card_id', '');
	}, [selectedProduct, selectedCompany, setValue]);

	useEffect(() => {
		if (selectedCompany) {
			setSelectedProduct('');
			setValue('product', '');
			setSelectedCard(null);
			setValue('card_id', '');
		}
	}, [selectedCompany, setValue]);

	const mutation = useMutation(createDispute, {
		onSuccess: () => {
			toast.success('Chargeback criada com sucesso!');
			navigate('/disputes');
		},
		onError: (error: any) => {
			showErrorMessage(error as Error, `Erro ao criar chargeback. `);
		},
	});

	function formatDateToDDMMYYYY(date: string): string {
		const [year, month, day] = date.split('-');
		return `${day}/${month}/${year}`;
	}

	const onSubmit = (data: CardTransactionDisputeDTO) => {
		if (swapRequired === 'false' && data.amount > 35) {
			setOpen(true);
		} else {
			submitForm(data);
		}
	};

	const submitForm = (data: CardTransactionDisputeDTO) => {
		if (!data.swap_required) {
			toast.error('Por favor, selecione se vale ou não a disputa.');
			return;
		}

		if (data.swap_required === 'true' && !data.transaction_id) {
			toast.error('Por favor, insira o ID da transação.');
			return;
		}

		if (!data.company_id) {
			toast.error('Por favor, selecione uma empresa.');
			return;
		}
		if (!data.product) {
			toast.error('Por favor, selecione um produto.');
			return;
		}

		if (data.amount <= 0) {
			toast.error('O valor deve ser maior que 0.');
			return;
		}
		const disputeData = {
			company_id: selectedCompany?.id || '',
			product: data.product,
			amount: convertReaisToCents(data.amount),
			dispute_description: data.dispute_description,
			card_id: data.card_id || null,
			start_date_dispute: data.start_date_dispute
				? formatDateToDDMMYYYY(data.start_date_dispute)
				: null,
			transaction_id: data.transaction_id || null,
			swap_required: swapRequired === 'true' ? true : false,
		};

		mutation.mutate(disputeData);
	};

	const onSelectCard = (card: { id: string; pan: string }) => {
		setValue('card_id', card.id);
		setSelectedCard(card);
		toast.success(`Cartão com PAN ****${card.pan} selecionado com sucesso!`);
	};

	return (
		<>
			<S.Container>
				<PageTitle title='Criar chargeback' />

				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.FormContainer>
						<S.FieldSet>
							<S.FieldGroup
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									gap: 0,
								}}
							>
								<S.Field>
									<S.Label htmlFor='company'>
										Empresa <ObrigatoryIndicator />
									</S.Label>
									<SelectCompany
										selectedCompanyId={selectedCompany?.id}
										onCompanySelection={handleSelectCompany}
										style={{
											width: '25rem',
											height: '4.5rem',
											padding: '12px 32px 12px 32px',
											gap: '12px',
											borderRadius: '50rem',
											opacity: '0px',
											border: '1px solid #191919',
											background: '#fff',
											color: '#393939',
											fontWeight: 600,
											alignContent: 'center',
											justifyContent: 'center',
										}}
									/>
								</S.Field>

								<S.Field
									style={{ width: '100%', margin: '0 0rem 1.2rem 2rem' }}
								>
									<S.Label htmlFor='product'>
										Produto
										<ObrigatoryIndicator />
									</S.Label>
									<S.SelectInput
										id='product'
										value={selectedProduct || ''}
										{...register('product')}
										onChange={(e) =>
											setSelectedProduct(e.target.value as LocalProductOrigin)
										}
										disabled={!selectedCompany}
									>
										<option value=''>Selecione uma opção</option>
										{availableProducts.map((product) => (
											<option key={product} value={product}>
												{product === 'multiflex'
													? 'Benefícios Flexíveis'
													: product === 'corpway'
													? 'Bounty Control'
													: 'Nenhum produto disponível'}
											</option>
										))}
									</S.SelectInput>
								</S.Field>

								<S.Field style={{ width: '80%', margin: '0 11rem 1.2rem 0' }}>
									<S.Label>Cartão</S.Label>
									<S.ButtonModal
										onClick={() => setCardModal(true)}
										disabled={!selectedProduct}
									>
										{selectedCard
											? `${maskCardNumber(selectedCard.pan)}`
											: 'Selecionar cartão'}
									</S.ButtonModal>
									<SelectCardModal
										product={selectedProduct}
										onSelectCard={onSelectCard}
										selectedCard={selectedCard}
										company_id={selectedCompany?.id || ''}
										disable={!selectedCompany}
										isOpenModal={isCardModal}
										onClose={() => setCardModal(false)}
									/>
								</S.Field>
							</S.FieldGroup>

							<S.FieldGroup style={{ gap: '8rem' }}>
								<S.Field style={{ width: '50%' }}>
									<S.Label>
										Descrição do chargeback <ObrigatoryIndicator />
									</S.Label>
									<S.InputTextArea
										{...register('dispute_description')}
										name='dispute_description'
										required
									/>
								</S.Field>
								<div
									style={{
										flexDirection: 'column',
										width: '50%',
										padding: 0,
									}}
								>
									<S.Field
										style={{ width: '70%', margin: '0 0.5rem 1.2rem 0.5rem' }}
									>
										<S.Label htmlFor='swap_required'>
											Vale a disputa
											<ObrigatoryIndicator />
										</S.Label>
										<S.SelectInput
											id='swap_required'
											{...register('swap_required')}
										>
											<option value=''>Selecione uma opção</option>
											<option value='true'>Sim</option>
											<option value='false'>Não</option>
										</S.SelectInput>
									</S.Field>
									<S.Field
										style={{ width: '70%', margin: '0 0.5rem 1.2rem 0.5rem' }}
									>
										<S.Label htmlFor='start_date_dispute'>
											Data do início do chargeback
										</S.Label>
										<S.Input
											type='date'
											{...register('start_date_dispute')}
											name='start_date_dispute'
											max={new Date().toISOString().split('T')[0]}
											id='start_date_dispute'
											data-testid='startDateInput_test_id'
										/>
									</S.Field>
									<S.Field style={{ width: '70%' }}>
										<S.Label htmlFor='value'>
											Valor <ObrigatoryIndicator />
										</S.Label>
										<S.CurrencyInputStyled
											value={value}
											onValueChange={({ floatValue }) => {
												setInputValue(floatValue || 0);
											}}
											showCurrencySymbol={false}
											required
										/>
									</S.Field>
									<S.Field style={{ width: '70%' }}>
										<S.Label htmlFor='transaction_id'>
											ID da transação{' '}
											{swapRequired === 'true' && <ObrigatoryIndicator />}
										</S.Label>
										<S.Input
											type='text'
											{...register('transaction_id')}
											id='transaction_id'
										/>
									</S.Field>
									<S.SubmitButton type='submit' disabled={mutation.isLoading}>
										{mutation.isLoading ? 'Registrando...' : 'Registrar'}
									</S.SubmitButton>
								</div>
							</S.FieldGroup>
						</S.FieldSet>
					</S.FormContainer>
				</S.Form>
			</S.Container>
			{isOpen && (
				<ConfirmationSwapRequiredModal
					onConfirm={() => submitForm(getValues())}
					onCancel={() => setOpen(false)}
				/>
			)}
		</>
	);
}
