import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const SubmitBtn = styled.button`
	${OptionsButton}
	float: right;
`;
