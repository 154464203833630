import { useState } from 'react';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import * as S from './styles';
import { useForm } from 'react-hook-form';
import PageTitle from '../../../components/PageTitle';
import Loader from '../../../components/Loader';
import * as FormStyles from '../../../components/Form/FormStyles';
import { SegmentationModal } from './SegmentationModal';
import { FaCheckCircle } from 'react-icons/fa';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import {
	ReportUsersBody,
	reportUsers,
} from '../../../services/queries/Notifications';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { GenericItem } from './SegmentationModal/components/SelectedItemsList';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export type SegmentationOptions = 'all_users' | 'group' | 'company' | 'custom';

const reportUsersSchema = z.object({
	title: z
		.string()
		.nonempty('O título é obrigatório')
		.max(50, 'O título deve ter no máximo 50 caracteres'),
	message: z
		.string()
		.nonempty('A mensagem é obrigatória')
		.max(150, 'A mensagem deve ter no máximo 150 caracteres'),
});

type FormData = {
	title: string;
	message: string;
};

const segmentationOptionString = {
	all_users: {
		title: 'Todos os usuários',
		description: 'Todos os usuários do sistema serão notificados.',
	},
	group: {
		title: 'Grupo corporativo',
		description:
			'Todos os usuários das empresas dos grupos selecionados serão notificados.',
	},
	company: {
		title: `Empresa`,
		description:
			'Todos os usuários das empresas selecionadas serão notificados.',
	},
	custom: {
		title: 'Customizado',
		description:
			'Apenas o grupo de usuários manualmente selecionados serão notificados.',
	},
};

export function ReportUsers() {
	const [itemsToReport, setItemsToReport] = useState<GenericItem[]>([]);
	const [reportOperators, setReportOperators] = useState(true);
	const [reportCollaborators, setReportCollaborators] = useState(true);
	const [segmentationOption, setSegmentationOption] =
		useState<'all_users' | 'group' | 'company' | 'custom'>('all_users');

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		reset,
		watch,
	} = useForm<FormData>({
		resolver: zodResolver(reportUsersSchema),
		defaultValues: {
			title: '',
			message: '',
		},
	});

	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	const reportUsersQuery = useMutation(
		(body: ReportUsersBody) => reportUsers(body),
		{
			onSuccess: () => {
				setAllowNavigation(true);
				toast.info(
					'Operação concluída. Em breve todos os usuários designados serão notificados.'
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar concluir a operação. '
				);
			},
		}
	);

	function handleSubmitForm(data: FormData) {
		reportUsersQuery.mutate({
			title: data.title.trim(),
			message: data.message.trim(),
			segmentation: segmentationOption,
			recipients: itemsToReport,
			operators: reportOperators,
			collaborators: reportCollaborators,
		});

		reset();
	}

	function handleSegmentationOptionSelection(
		option: 'all_users' | 'group' | 'company' | 'custom',
		selectedItems: GenericItem[],
		reportOperators: boolean,
		reportCollaborators: boolean
	) {
		setSegmentationOption(option);
		if (option !== 'all_users') {
			setItemsToReport(selectedItems);
		}

		setReportOperators(reportOperators);
		setReportCollaborators(reportCollaborators);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={!allowNavigation} />

			<PageTitle title="Notificar usuários" />

			<S.FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
				<S.Field>
					<FormStyles.Label htmlFor="title">Título</FormStyles.Label>
					<FormStyles.Input
						id="title"
						{...register('title')}
						maxLength={50}
					/>
					<span>{watch('title').length} / 50</span>
					<div style={{ textAlign: 'left'}}>
						{errors.title && (
							<span style={{ color: 'red', fontSize: '1.3rem' }}>
								{errors.title.message}
							</span>
						)}
					</div>
				</S.Field>

				<S.Field>
					<S.FieldHeader>
						<FormStyles.Label>Corpo da mensagem</FormStyles.Label>
					</S.FieldHeader>
					<FormStyles.InputTextArea
						id="message"
						{...register('message')}
						maxLength={150}
					/>
				
						<span>{watch('message').length} / 150</span>
						<div style={{ textAlign: 'left'}}>
							{errors.message && (
								<span style={{ color: 'red', fontSize: '1.3rem'}}>
									{errors.message.message}
								</span>
							)}
						</div>
				</S.Field>

				<S.Field>
					<SegmentationModal
						onChangeSegmentation={handleSegmentationOptionSelection}
					/>

					<S.SegmentationOptionContainer>
						<FaCheckCircle color="#1CCA0D" />
						<>
							<S.SegmentationOptionTitle>
								{segmentationOptionString[segmentationOption].title}
							</S.SegmentationOptionTitle>
							<span>
								{segmentationOptionString[segmentationOption].description}
							</span>
						</>
					</S.SegmentationOptionContainer>
				</S.Field>

				<S.SubmitForm type="submit" disabled={reportUsersQuery.isLoading}>
					{reportUsersQuery.isLoading ? (
						<Loader size={20} color="var(--white)" />
					) : (
						'Enviar'
					)}
				</S.SubmitForm>
			</S.FormContainer>
		</S.Container>
	);
}
