import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 2rem;
`;

export const CreateButton = styled(Link)`
	${OptionsButton}
	font-size: 1.4rem;
`;

export const StatusContainer = styled.div`
	display: flex;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
	}
`;
