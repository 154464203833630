import * as S from './styles';
import Modal from '../../../../../components/Modal';
import { MdWarningAmber } from 'react-icons/md';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	message: string;
}

const ForbiddenModal = ({ isOpen, onClose, message }: Props) => (
	<Modal isOpen={isOpen} enableClose={false} onRequestClose={onClose}>
		<S.Container>
			<S.SubContainer>
				<S.FieldGroup
					style={{
						flexDirection: 'column',
						alignItems: 'center',
						gap: '2rem',
					}}
				>
					<S.IconContainer>
						<MdWarningAmber color='red' size={30} />
					</S.IconContainer>
					<S.Title>Valor Excedente</S.Title>
					<S.Label>{message}</S.Label>
				</S.FieldGroup>
			</S.SubContainer>
			<S.CancelContainer>
				<S.CancelOption onClick={onClose}>Cancelar</S.CancelOption>
			</S.CancelContainer>
		</S.Container>
	</Modal>
);

export default ForbiddenModal;
