import { MdWarningAmber } from 'react-icons/md';
import Modal from '../../../../../../components/Modal';
import * as S from './styles';

interface Props {
	onConfirm: () => void;
	onCancel: () => void;
}

export function ConfirmationSwapRequiredModal({ onConfirm, onCancel }: Props) {
	return (
		<Modal isOpen={true} enableClose={false} onRequestClose={onCancel}>
			<S.Container>
				<S.SubContainer>
					<S.FieldGroup
						style={{
							flexDirection: 'column',
							alignItems: 'center',
							gap: '2rem',
						}}
					>
						<S.IconContainer>
							<MdWarningAmber color='red' size={30} />
						</S.IconContainer>
						<S.Title>Criar chargeback</S.Title>
						<S.Label>
							O valor é maior que 35 reais e{' '}
							<span style={{ fontWeight: 600 }}>"Não vale a disputa"</span> foi
							selecionado. Deseja continuar?
						</S.Label>
					</S.FieldGroup>
				</S.SubContainer>
				<S.BodyContainer></S.BodyContainer>
				<S.ButtonsContainer>
					<S.CancelOption onClick={onCancel}>Cancelar</S.CancelOption>
					<S.TransactionButton onClick={onConfirm}>
						Confirmar
					</S.TransactionButton>
				</S.ButtonsContainer>
			</S.Container>
		</Modal>
	);
}
