import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import {
	getPartners,
	updatePartnerStatus,
} from '../../../../services/queries/Partners';
import * as TableStyle from '../../../../components/Table/TableStyles';
import * as S from './styles';
import { EmptyContent } from '../../../../components/EmptyContent';
import {
	FaCheck,
	FaCheckCircle,
	FaExclamationCircle,
	FaTimes,
} from 'react-icons/fa';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';

interface TogglePartnerStatusParams {
	partnerId: string;
	newStatus: boolean;
}
export function Partners() {
	const queryClient = useQueryClient();
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	const getPartnersQuery = useQuery(['fetch-partners'], getPartners, {
		onError: (err) => {
			showErrorMessage(err as Error, 'Não foi possível buscar os parceiros. ');
		},
	});

	const updatePartnerStatusMutation = useMutation(
		({ partnerId, newStatus }: TogglePartnerStatusParams) =>
			updatePartnerStatus(partnerId, newStatus),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetch-partners');
				toast.info('Status do parceiro atualizado com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível mudar o status do parceiro. '
				);
			},
		}
	);

	function handleTogglePartnerStatus(partnerId: string, status: boolean) {
		updatePartnerStatusMutation.mutate({ partnerId, newStatus: !status });
	}

	function getEnabledIcon(enabled: boolean) {
		return enabled ? (
			<FaCheckCircle color='#1CCA0D' />
		) : (
			<FaExclamationCircle color='#FB1313' />
		);
	}

	if (
		getPartnersQuery.isLoading ||
		!getPartnersQuery.data ||
		updatePartnerStatusMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Parceiros disponíveis' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Parceiros disponíveis' />

			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'partner_name'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('partner_name')}
							/>
							PARCEIRO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'partner_description'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('partner_description')}
							/>
							DESCRIÇÃO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>CATEGORIA</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{sortList(getPartnersQuery.data).map((partner) => (
						<TableStyle.TableRow key={partner.id}>
							<TableStyle.TableData>
								{partner.partner_name}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{partner.partner_description}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{partner.partner_category}
							</TableStyle.TableData>
							<TableStyle.TableData>
								<TableStyle.StatusContainer>
									{getEnabledIcon(partner.enabled)}
									<span>{partner.enabled ? 'Ativado' : 'Desativado'}</span>
								</TableStyle.StatusContainer>
							</TableStyle.TableData>
							<TableStyle.TableData>
								<S.TogglePartnerStatusBtn
									onClick={() =>
										handleTogglePartnerStatus(partner.id, partner.enabled)
									}
									data-rh={`${
										partner.enabled ? 'Desativar' : 'Ativar'
									} parceiro`}
									data-testid={`${partner.enabled ? 'disable' : 'enable'}-${
										partner.id
									}`}
								>
									{partner.enabled ? <FaTimes /> : <FaCheck />}
								</S.TogglePartnerStatusBtn>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>

			{getPartnersQuery.data.length === 0 && (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			)}

			<S.PartnerStatsLink to='/partners/stats'>
				Ver quantitativos
			</S.PartnerStatsLink>
		</S.Container>
	);
}
