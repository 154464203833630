import { useParams } from 'react-router';
import { Chargeback } from '../../../../../@types';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import CompanyCard from '../../../../../components/CompanyCard';
import { parseDate } from '../../../../../utils/parseDate';
import * as S from './styles';
import { useQuery } from 'react-query';
import { fetchChargeback } from '../../../../../services/queries/Chargebacks';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { ProductOriginLabel } from '../../../../../components/ProductOriginLabel';

export default function ChargebackDetails() {
	const { id } = useParams<{ id: string }>();

	const fetchChargebackQuery = useQuery<Chargeback, Error>(
		['fetchChargeback', id],
		() => fetchChargeback(id!),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o estorno. '
				);
			},
		}
	);

	if (fetchChargebackQuery.isLoading || !fetchChargebackQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do estorno' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhes do estorno' />
			</S.Header>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>Empresa</S.InfoTitle>
					<CompanyCard
						company_name={fetchChargebackQuery.data!.company.name}
						avatar_url={fetchChargebackQuery.data!.company.avatar_url}
						cnpj={fetchChargebackQuery.data!.company.cnpj}
					/>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Valor</S.InfoTitle>
					{convertCentsToReais(Number(fetchChargebackQuery.data!.value))}
				</S.InfoContainer>
				{fetchChargebackQuery.data!.reversed_value !== null && (
					<S.InfoContainer>
						<S.InfoTitle>Valor estornado</S.InfoTitle>

						{convertCentsToReais(
							Number(fetchChargebackQuery.data!.reversed_value)
						)}
					</S.InfoContainer>
				)}
			</S.InfoTop>

			<S.InfoContainer>
				<S.InfoTitle>Produto</S.InfoTitle>
				<ProductOriginLabel origin={fetchChargebackQuery.data!.origin} />
			</S.InfoContainer>

			<S.InfoContainer>
				<S.InfoTitle>Justificativa</S.InfoTitle>
				{fetchChargebackQuery.data!.justification}
			</S.InfoContainer>

			<S.InfoContainer>
				<S.InfoTitle>Data</S.InfoTitle>
				{parseDate(fetchChargebackQuery.data!.created_at, 2)}
			</S.InfoContainer>
		</S.Container>
	);
}
