import { CardTransactionDispute as Disputes } from '../../../../@types';
import * as S from './styles';
import * as TableStyle from '../../../../components/Table/TableStyles';
import PageTitle from '../../../../components/PageTitle';
import { EmptyContent } from '../../../../components/EmptyContent';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Filter, FilterParams } from '../../../../components/Filter';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { MdNotes } from 'react-icons/md';
import { getDisputesByStatus } from '../../../../services/queries/Disputes';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { Pagination } from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import { parseDisputeSwapStatusString } from '../../../../utils/parseDisputeStatus';
import { useDisputesListStore } from '../../../../stores/useDisputesListStore';

interface DisputesTableProps {
	tableName: string;
	status: 'initialized' | 'finished';
	filtersParams: FilterParams[];
	currentPage: number;
	onPageChange: (page: number) => void;
	updateFilters: (filters: FilterParams[]) => void;
}

function DisputesTable({
	tableName,
	status,
	filtersParams,
	currentPage,
	onPageChange,
	updateFilters,
}: DisputesTableProps) {
	const queryClient = useQueryClient();

	const disputesQuery = useQuery(
		[`${status}DisputesList`, filtersParams, currentPage],
		() => getDisputesByStatus(status, filtersParams, currentPage, 10),
		{
			onError: (error: any) => {
				showErrorMessage(
					error,
					`Não foi possível buscar as disputas ${tableName.toLowerCase()}.`
				);
			},
			keepPreviousData: true,
		}
	);

	const reloadPage = () => {
		onPageChange(1);
		queryClient.resetQueries(`${status}DisputesList`);
	};

	const generateRow = (dispute: Disputes) => (
		<TableStyle.TableRow key={dispute.id}>
			<TableStyle.TableData>{dispute.company.name}</TableStyle.TableData>
			<TableStyle.TableData>
				<span
					style={{
						color:
							dispute.product === 'corpway'
								? 'var(--primary-blue)'
								: 'var(--primary-red)',
					}}
				>
					{dispute.product === 'corpway'
						? 'Bounty Control'
						: 'Benefícios Flexíveis'}
				</span>
			</TableStyle.TableData>
			<TableStyle.TableData>
				{convertCentsToReais(Number(dispute.amount))}
			</TableStyle.TableData>
			<TableStyle.TableData>
				{dispute.start_date_dispute ?? '---'}
			</TableStyle.TableData>
			{status === 'initialized' ? (
				<TableStyle.TableData>
					{parseDisputeSwapStatusString(dispute.status_swap) ?? '---'}
				</TableStyle.TableData>
			) : (
				<TableStyle.TableData>
					{dispute.transfer_date_company}
				</TableStyle.TableData>
			)}
			<TableStyle.OptionLink
				to={`/disputes/${dispute.id}`}
				data-rh='Ir para chargeback'
			>
				<MdNotes />
			</TableStyle.OptionLink>
		</TableStyle.TableRow>
	);

	if (disputesQuery.isLoading || !disputesQuery.data?.disputes.length) {
		return (
			<>
				<TableStyle.TitleWrapper>
					<PageTitle title={tableName} />
					<Filter
						filterParams={filtersParams}
						onFiltersChanged={updateFilters}
					/>
					<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
				</TableStyle.TitleWrapper>
				{disputesQuery.isLoading ? (
					<Loader />
				) : (
					<EmptyContent text='Não há disputas a serem exibidas.' />
				)}
			</>
		);
	}

	return (
		<>
			<TableStyle.TitleWrapper>
				<PageTitle
					title={tableName}
					totalRecords={disputesQuery.data.totalCount}
				/>
				<Filter filterParams={filtersParams} onFiltersChanged={updateFilters} />
				<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
			</TableStyle.TitleWrapper>
			<Pagination
				onPageChange={onPageChange}
				currentPage={currentPage}
				totalCount={disputesQuery.data.totalCount}
			/>
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>Empresa</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>Produto</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>Valor</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							Início do chargeback
						</TableStyle.TableHeaderCell>
						{status === 'initialized' ? (
							<TableStyle.TableHeaderCell>
								Status do swap
							</TableStyle.TableHeaderCell>
						) : (
							<TableStyle.TableHeaderCell>
								Repasse para a empresa
							</TableStyle.TableHeaderCell>
						)}
						<TableStyle.TableHeaderCell>Detalhes</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>
				<TableStyle.TableBody>
					{disputesQuery.data.disputes.map((dispute: Disputes) =>
						generateRow(dispute)
					)}
				</TableStyle.TableBody>
			</TableStyle.Table>
		</>
	);
}

export function CardTransactionDispute() {
	const [
		filtersParamsInitialized,
		setFiltersParamsInitialized,
		filtersParamsFinalized,
		setFiltersParamsFinalized,
		resetFilters,
	] = useDisputesListStore((state) => [
		state.filterParamsInitialized,
		state.setFilterParamsInitialized,
		state.filterParamsFinalized,
		state.setFilterParamsFinalized,
		state.resetFilters,
	]);

	const [initializedCurrentPage, setInitializedCurrentPage] = useState(1);
	const [finalizedCurrentPage, setFinalizedCurrentPage] = useState(1);

	return (
		<S.Container>
			<PageTitle title='Histórico de chargebacks de transações' />
			<div style={{ marginBottom: '2rem' }}></div>
			<DisputesTable
				tableName='Inicializadas'
				status='initialized'
				filtersParams={filtersParamsInitialized}
				currentPage={initializedCurrentPage}
				onPageChange={setInitializedCurrentPage}
				updateFilters={setFiltersParamsInitialized}
			/>
			<S.ButtonsContainer>
				<S.CreateDispute to='/disputes/create'>
					Criar chargeback
				</S.CreateDispute>
			</S.ButtonsContainer>
			<DisputesTable
				tableName='Finalizadas'
				status='finished'
				filtersParams={filtersParamsFinalized}
				currentPage={finalizedCurrentPage}
				onPageChange={setFinalizedCurrentPage}
				updateFilters={setFiltersParamsFinalized}
			/>
		</S.Container>
	);
}
