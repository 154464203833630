import { FaCheckCircle, FaQuestionCircle, FaRegClock } from 'react-icons/fa';

export function getDipusteStatusIcon(status: string, withError?: boolean) {
	if (status === 'finished' && !withError)
		return <FaCheckCircle color='#1CCA0D' />;

	if (status === 'initialized') return <FaRegClock color='#E0E414' />;

	// if (status === 'initialized') return <FaRegClock color='#C6C6C6' />;

	return <FaQuestionCircle />;
}

export function parseDisputeStatusString(status: string, withError?: boolean) {
	if (status === 'finished') {
		return 'Finalizado';
	}

	if (status === 'initialized') return 'Inicializado';

	return '?????';
}

export function parseDisputeSwapStatusString(status: string) {
	if (status === 'accepted') return 'Aceito';
	if (status === 'open') return 'Aberto';
	if (status === 'operational_error') return 'Erro operacional';
	if (status === 'denied') return 'Negado';
	if (status === 'pending') return 'Pendente';
	if (status === 'pre_arb') return 'Próxima etapa';
	if (status === 'recovery') return 'Recuperação';
	if (status === 'rejected') return 'Rejeitado';
	if (status === 'reversed') return 'Invertido';
	if (status === 'finalized') return 'Finalizado';
}
