import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const StatusContainer = styled.div`
	display: flex;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-bottom: 2rem;
`;
export const ReportButton = styled.button`
	${OptionsButton}
	width: 15rem;
	font-size: 1.4rem;
`;

export const CreateDispute = styled(Link)`
	${OptionsButton}
	width: fit-content;
	font-size: 1.4rem;
`;

export const TotalValuesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: -2rem;
	margin-bottom: 2rem;
	row-gap: 1rem;
`;

export const TotalValue = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 1.5rem;
`;
export const TotalValueLabel = styled.span`
	font-size: 1.7rem;
`;
export const TotalValueNumber = styled.h3`
	font-size: 1.9rem;
`;
export const BoldText = styled.span`
	font-weight: bold;
`;
