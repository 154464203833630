import { useEffect } from 'react';
import { z } from 'zod';
import PageTitle from '../../../../../components/PageTitle';
import * as FormStyles from '../../../../../components/Form/FormStyles';
import * as S from './styles';
import Loader from '../../../../../components/Loader';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../hooks/useAuth';
import { useMutation } from 'react-query';
import {
	ChargebackBodyQuery,
	createChargeback,
} from '../../../../../services/queries/Chargebacks';
import PreventTransitionPrompt from '../../../../../components/PreventTransitionPrompt';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import SelectInput from '../../../../../componentsV2/ui/SelectInput';
import { convertReaisToCents } from '../../../../../utils/CurrencyConvert';
import TextAreaInput from '../../../../../componentsV2/ui/TextAreaInput';
import { ErrorMessage } from '../../../../../componentsV2/ui/InputField';
import { SelectCompany } from '../../../../../components/SelectCompany';
import { SelectGroupCompany } from '../../../../../components/SelectGroupCompany';

const chargebackSchema = z
	.object({
		group_id: z
			.string({ required_error: 'Grupo corporativo é obrigatório' })
			.optional(),
		company_id: z
			.string({ required_error: 'Empresa é obrigatória' })
			.min(1, 'Empresa é obrigatória'),
		origin: z.enum(['multiflex', 'corpway'], {
			required_error: 'Grupo corporativo é obrigatório',
		}),
		value: z
			.number({ invalid_type_error: 'Informe um valor' })
			.refine((e) => e > 0, 'Valor abaixo do mínimo permitido'),
		justification: z
			.string({ required_error: 'A justificativa é obrigatória' })
			.min(1, 'A justificativa é obrigatória')
			.trim(),
		multiflex_balance: z.number().optional(),
		corpway_balance: z.number().optional(),
	})
	.superRefine((data, ctx) => {
		if (!data.group_id) return;
		if (data.origin === 'corpway') {
			if (data.value > (data.corpway_balance ?? 0)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['value'],
					message: `Saldo control insuficiente`,
				});
			}
		} else {
			if (data.value > (data.multiflex_balance ?? 0)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['value'],
					message: `Saldo benefícios insuficiente`,
				});
			}
		}
	});

interface CreateChargebackForm {
	group_id?: string;
	company_id?: string;
	origin: 'multiflex' | 'corpway';
	value: number;
	justification: string;
	corpway_balance?: number;
	multiflex_balance?: number;
}

export function CreateChargeback() {
	const navigate = useNavigate();
	const { updateMasterBalance } = useAuth();
	const { openConfirmDialog } = useDialogModal();

	const { formState, setValue, register, watch, handleSubmit } =
		useForm<CreateChargebackForm>({
			resolver: zodResolver(chargebackSchema),
			defaultValues: {
				origin: 'multiflex',
				value: 0,
				justification: '',
			},
		});

	const selected_group = watch('group_id');
	const selected_company = watch('company_id');
	const value = watch('value');

	useEffect(() => {
		setValue('company_id', undefined);
	}, [selected_group, setValue]);

	const createChargebackQuery = useMutation(
		(body: ChargebackBodyQuery) => {
			return createChargeback(body);
		},
		{
			onSuccess: () => {
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o estorno. '
				);
			},
		}
	);

	function showSuccessMessage() {
		updateMasterBalance.refetch();
		openConfirmDialog(
			'A solicitação de estorno foi criada',
			'Entendi',
			() => {}
		);
		navigate('/chargebacks');
	}

	function submitForm(data: CreateChargebackForm) {
		data.value = convertReaisToCents(value);
		delete data.corpway_balance;
		delete data.multiflex_balance;
		delete data.group_id;

		createChargebackQuery.mutate(data as ChargebackBodyQuery);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={!formState.isDirty} />

			<PageTitle title='Solicitar estorno' />
			<S.Form onSubmit={handleSubmit(submitForm)} data-testid='form'>
				<S.FormContainer>
					<FormStyles.Field>
						<FormStyles.Label>Grupo corporativo</FormStyles.Label>
						<SelectGroupCompany
							selectedGroupId={selected_group}
							onGroupCompanySelection={(g) =>
								setValue('group_id', g.id, { shouldDirty: true })
							}
						/>
						{formState.errors.group_id?.message && (
							<ErrorMessage>{formState.errors.group_id?.message}</ErrorMessage>
						)}
					</FormStyles.Field>

					<FormStyles.Field>
						<FormStyles.Label>Empresa</FormStyles.Label>
						<SelectCompany
							groupCompanyId={selected_group}
							selectedCompanyId={selected_company}
							onCompanySelection={(c) => {
								setValue('company_id', c.id, { shouldDirty: true });
								setValue('corpway_balance', c.corpway_balance ?? 0);
								setValue('multiflex_balance', c.multiflex_balance ?? 0);
							}}
						/>
						{formState.errors.company_id?.message && (
							<ErrorMessage>
								{formState.errors.company_id?.message}
							</ErrorMessage>
						)}
					</FormStyles.Field>

					<SelectInput
						label={'Produto'}
						id={'product'}
						register={register}
						name='origin'
						containerStyle={{ maxWidth: '60%' }}
					>
						<option value='multiflex'>Benefícios Flexíveis</option>
						<option value='corpway'>Bounty Control</option>
					</SelectInput>

					<FormStyles.Field>
						<FormStyles.Label>Valor</FormStyles.Label>
						<FormStyles.FormCurrencyInput
							style={{ width: 'fit-content' }}
							value={value}
							data-testid='value-input'
							onValueChange={({ floatValue }) =>
								setValue('value', floatValue, { shouldDirty: true })
							}
						/>
						{formState.errors.value?.message && (
							<ErrorMessage>{formState.errors.value?.message}</ErrorMessage>
						)}
					</FormStyles.Field>
				</S.FormContainer>

				<TextAreaInput
					label={'Justificativa'}
					register={register}
					name={'justification'}
					data-testid='justification-input'
					errorMessage={formState.errors.justification?.message}
				/>

				<S.Submit type='submit' disabled={createChargebackQuery.isLoading}>
					{!createChargebackQuery.isLoading ? (
						'Enviar'
					) : (
						<Loader size={20} color='var(--white)' />
					)}
				</S.Submit>
			</S.Form>
		</S.Container>
	);
}
