import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import CompanyCard from '../../../../../components/CompanyCard';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import {
	getUnderageDocument,
	reviewUnderageDocument,
} from '../../../../../services/queries/UnderageDocuments';
import {
	getStatusIcon,
	parseUnderageDocumentStatus,
} from '../../../../../utils/parseUnderageDocumentStatus';
import * as S from './styles';
import { StatusContainer } from '../../../../../components/Table/TableStyles';
import { cpfMask, phoneMask } from '../../../../../utils/masks';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';

export function UnderageDocumentReview() {
	const { id } = useParams();
	const navigate = useNavigate();
	const { openConfirmDialog, openOptionsDialog } = useDialogModal();

	const fetchUnderageDocumentQuery = useQuery(
		['underageDocumentDetails', id],
		() => {
			return getUnderageDocument(id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o colaborador de menor. '
				);
			},
		}
	);

	const reviewUnderageDocumentQuery = useMutation(
		(review: 'disapproved' | 'approved') => {
			return reviewUnderageDocument(id!, review);
		},
		{
			onSuccess: () => {
				openConfirmDialog(`O documento foi revisado!`, 'Entendi', () => {});
				navigate('/underage-documents');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar revisar o documento. '
				);
			},
		}
	);

	function handleSendFeedback(review: 'disapproved' | 'approved') {
		openOptionsDialog(
			`Tem certeza que deseja ${
				review === 'disapproved' ? 'desaprovar' : 'aprovar'
			} o documento?`,
			'Confirmar',
			() => {
				review === 'disapproved'
					? reviewUnderageDocumentQuery.mutate('disapproved')
					: reviewUnderageDocumentQuery.mutate('approved');
			},
			'Cancelar',
			() => {},
			true
		);
	}

	if (
		fetchUnderageDocumentQuery.isLoading ||
		reviewUnderageDocumentQuery.isLoading ||
		!fetchUnderageDocumentQuery.data
	)
		return (
			<S.Container>
				<PageTitle title='Detalhes do Colaborador menor de idade' />
				<Loader />
			</S.Container>
		);
	return (
		<S.Container>
			<PageTitle title='Detalhes do Colaborador menor de idade' />

			<S.InfoContentContainer>
				<S.InfoContainer>
					<S.InfoTitle>Empresa</S.InfoTitle>
					<CompanyCard
						company_name={fetchUnderageDocumentQuery.data.company.name}
						avatar_url={fetchUnderageDocumentQuery.data.company.avatar_url}
						cnpj={fetchUnderageDocumentQuery.data.company.cnpj}
					/>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Colaborador</S.InfoTitle>
					<S.FieldsContainer>
						<S.Field>
							<S.FieldTitle>Nome</S.FieldTitle>
							{fetchUnderageDocumentQuery.data.collaborator.name}
						</S.Field>
						<S.Field>
							<S.FieldTitle>Email</S.FieldTitle>
							{fetchUnderageDocumentQuery.data.collaborator.email}
						</S.Field>
					</S.FieldsContainer>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Responsável</S.InfoTitle>
					<S.FieldsContainer>
						<S.Field>
							<S.FieldTitle>Nome completo</S.FieldTitle>
							{fetchUnderageDocumentQuery.data.responsible?.full_name}
						</S.Field>
						<S.Field>
							<S.FieldTitle>CPF</S.FieldTitle>
							{fetchUnderageDocumentQuery.data.responsible &&
								cpfMask(fetchUnderageDocumentQuery.data.responsible?.cpf)}
						</S.Field>
						<S.Field>
							<S.FieldTitle>Telefone</S.FieldTitle>
							{fetchUnderageDocumentQuery.data.responsible &&
								phoneMask(fetchUnderageDocumentQuery.data.responsible?.phone)}
						</S.Field>
						<S.Field>
							<S.FieldTitle>Parentesco</S.FieldTitle>
							{fetchUnderageDocumentQuery.data.responsible?.relatedness_degree}
						</S.Field>
					</S.FieldsContainer>
				</S.InfoContainer>
			</S.InfoContentContainer>

			<S.InfoContentContainer>
				<S.InfoContainer>
					<S.InfoTitle>DOCUMENTO</S.InfoTitle>
					<S.DocumentLink
						href={fetchUnderageDocumentQuery.data.document}
						target='_blank'
						rel='noopener noreferrer'
						download
					>
						Ver documento
					</S.DocumentLink>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Status</S.InfoTitle>
					<StatusContainer>
						{getStatusIcon(fetchUnderageDocumentQuery.data.status)}
						<span>
							{parseUnderageDocumentStatus(
								fetchUnderageDocumentQuery.data.status
							)}
						</span>
					</StatusContainer>
				</S.InfoContainer>
			</S.InfoContentContainer>

			{fetchUnderageDocumentQuery.data.status === 'requested' && (
				<S.OptionsContainer>
					<S.ReproveOption onClick={() => handleSendFeedback('disapproved')}>
						Reprovar
					</S.ReproveOption>
					<S.ApproveOption onClick={() => handleSendFeedback('approved')}>
						Aprovar
					</S.ApproveOption>
				</S.OptionsContainer>
			)}
		</S.Container>
	);
}
