import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FundsOut } from '../../../../../@types';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import CompanyCard from '../../../../../components/CompanyCard';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { parseDate } from '../../../../../utils/parseDate';
import * as S from './styles';
import { fetchFundOut } from '../../../../../services/queries/FundsOut';
import { parseFundsOutStatus } from '../utils/parseFundsOutData';
import { cnpjMask, cpfMask } from '../../../../../utils/masks';

export default function FundsOutDetails() {
	const { id } = useParams<{ id: string }>();

	const fetchFundOutQuery = useQuery<FundsOut, Error>(
		['fetchFundOut', id],
		() => fetchFundOut(id!),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Ocorreu um problema ao buscar o TED. ');
			},
		}
	);

	if (fetchFundOutQuery.isLoading || !fetchFundOutQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do TED' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhes do TED' />
			</S.Header>

			<S.DetailsContainer>
				<S.GridContainer>
					<S.InfoContainer>
						<S.InfoTitle>Empresa</S.InfoTitle>
						<CompanyCard
							company_name={fetchFundOutQuery.data!.company.name}
							avatar_url={fetchFundOutQuery.data!.company.avatar_url}
							cnpj={fetchFundOutQuery.data!.company.cnpj}
						/>
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Valor</S.InfoTitle>
						{convertCentsToReais(Number(fetchFundOutQuery.data!.amount))}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Descrição</S.InfoTitle>
						{fetchFundOutQuery.data!.description}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Data</S.InfoTitle>
						{parseDate(fetchFundOutQuery.data!.created_at, 2)}
					</S.InfoContainer>

					{!!fetchFundOutQuery.data.scheduled_date && (
						<S.InfoContainer>
							<S.InfoTitle>Data agendada</S.InfoTitle>
							{parseDate(fetchFundOutQuery.data!.scheduled_date, 2)}
						</S.InfoContainer>
					)}

					<S.InfoContainer>
						<S.InfoTitle>Status</S.InfoTitle>
						{parseFundsOutStatus(fetchFundOutQuery.data!.status)}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Operador</S.InfoTitle>
						{fetchFundOutQuery.data.operator.name}
					</S.InfoContainer>
				</S.GridContainer>
			</S.DetailsContainer>

			<S.BeneficiaryContainer>
				<S.InfoContainer>
					<S.BeneficiaryTitle>Beneficiário</S.BeneficiaryTitle>
				</S.InfoContainer>

				<S.GridContainer>
					<S.InfoContainer>
						<S.InfoTitle>Nome</S.InfoTitle>
						{fetchFundOutQuery.data!.beneficiary.name}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Tipo</S.InfoTitle>
						{fetchFundOutQuery.data.beneficiary.type.toUpperCase()}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>
							{fetchFundOutQuery.data.beneficiary.type === 'pj'
								? 'CNPJ'
								: 'CPF'}
						</S.InfoTitle>
						{fetchFundOutQuery.data.beneficiary.type === 'pj'
							? cnpjMask(fetchFundOutQuery.data.beneficiary.document)
							: cpfMask(fetchFundOutQuery.data.beneficiary.document)}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Banco</S.InfoTitle>
						{fetchFundOutQuery.data!.beneficiary.bank_code} -{' '}
						{fetchFundOutQuery.data!.beneficiary.bank_name}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Agência</S.InfoTitle>
						{fetchFundOutQuery.data.beneficiary.branch}
					</S.InfoContainer>

					<S.InfoContainer>
						<S.InfoTitle>Conta</S.InfoTitle>
						{fetchFundOutQuery.data.beneficiary.account_number}
					</S.InfoContainer>
				</S.GridContainer>
			</S.BeneficiaryContainer>
		</S.Container>
	);
}
