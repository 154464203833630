import { useState } from 'react';
import { toast } from 'react-toastify';
import { Company, ProductOrigin } from '../../../../../@types';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import { useAuth } from '../../../../../hooks/useAuth';
import * as S from './styles';
import { useMutation, useQuery } from 'react-query';
import {
	CreateFirstCreditArgs,
	firstCredit,
} from '../../../../../services/queries/Tranfers';
import { FormCurrencyInput } from '../../../../../components/Form/FormStyles';
import PreventTransitionPrompt from '../../../../../components/PreventTransitionPrompt';
import * as FormStyles from '../../../../../components/Form/FormStyles';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { getCompaniesSelect } from '../../../../../services/queries/Companies';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { cnpjMask } from '../../../../../utils/masks';
import Modal from '../../../../../components/Modal';
import { useNavigate } from 'react-router-dom';

export function FirstTransfer() {
	const { masterBalance, updateMasterBalance } = useAuth();
	const navigate = useNavigate();

	const [value, setValue] = useState(0);
	const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
	const [justification, setJustification] = useState('');
	const [selectedProduct, setSelectedProduct] =
		useState<ProductOrigin>('multiflex');

	const [allowNavigation, setAllowNavigation] = useState(true);

	const createTransactionMutation = useMutation(
		({ value, companyId, justification, origin }: CreateFirstCreditArgs) => {
			return firstCredit({ value, companyId, justification, origin });
		},
		{
			onSuccess: () => {
				updateMasterBalance.refetch();
				toast.info('Transferência realizada com sucesso');
				navigate(-1);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar realizar a transferência. '
				);
			},
		}
	);

	async function handleSubmitTransfer(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		if (!selectedCompany) {
			toast.error('Selecione uma empresa');
			return;
		}
		if (value <= 0) {
			toast.error('Valor inválido');
			return;
		}
		if (!justification) {
			toast.error('Forneça uma justificativa');
			return;
		}
		if (masterBalance.cards < value) {
			toast.error('Saldo insuficiente');
			return;
		}

		createTransactionMutation.mutate({
			value,
			companyId: selectedCompany.id!,
			justification: justification,
			origin: selectedProduct,
		});
	}

	function handleSelectCompany(company: Company) {
		setSelectedCompany(company);
	}

	if (createTransactionMutation.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Criar transferência' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<PreventTransitionPrompt when={!allowNavigation} />

			<PageTitle title='Criar transferência' />

			<S.FormContainer>
				<S.Form onSubmit={handleSubmitTransfer}>
					<FormStyles.Field>
						<FormStyles.Label htmlFor='company'>Empresa:</FormStyles.Label>
						<SelectCompanyGeneral
							selectedCompany={selectedCompany}
							onCompanySelection={handleSelectCompany}
						/>
					</FormStyles.Field>

					<FormStyles.Field style={{ width: '40%' }}>
						<FormStyles.Label htmlFor='value'>Valor:</FormStyles.Label>

						<S.InputValueContainer>
							<FormCurrencyInput
								value={value}
								onValueChange={({ floatValue }) => {
									setValue(floatValue);
								}}
								required
							/>
						</S.InputValueContainer>
					</FormStyles.Field>
					<FormStyles.Field style={{ width: 'fit-content' }}>
						<FormStyles.Label htmlFor='product'>Produto:</FormStyles.Label>

						<FormStyles.SelectInput
							id='product'
							value={selectedProduct}
							onChange={(e) =>
								setSelectedProduct(e.target.value as ProductOrigin)
							}
						>
							<option value='multiflex'>Benefícios Flexíveis</option>
							<option value='corpway'>Bounty Control</option>
						</FormStyles.SelectInput>
					</FormStyles.Field>
					<FormStyles.Field style={{ width: '80%' }}>
						<FormStyles.Label htmlFor='justificativa'>
							Justificativa:
						</FormStyles.Label>
						<FormStyles.Input
							id='justificativa'
							value={justification}
							onChange={(e) => setJustification(e.target.value)}
							placeholder='Digite a justificativa para a transferência'
							required
						/>
					</FormStyles.Field>
					<FormStyles.Field style={{ width: '80%' }}></FormStyles.Field>

					<S.SubmitButton type='submit'>Enviar</S.SubmitButton>
				</S.Form>
			</S.FormContainer>
		</S.Container>
	);
}

interface SelectCompanyGeneralProps {
	selectedCompany: Company | null;
	onCompanySelection: (c: Company) => void;
}

export function SelectCompanyGeneral({
	selectedCompany,
	onCompanySelection,
}: SelectCompanyGeneralProps) {
	const [isOpen, setIsOpen] = useState(false);

	const fetchCompaniesQuery = useQuery(
		['fetchCompanies'],
		() => {
			return getCompaniesSelect();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar as empresas. '
				);
			},
			enabled: true,
			refetchOnWindowFocus: false,
		}
	);

	const filteredCompanies =
		fetchCompaniesQuery.data
			?.filter((company) => company.id_baas_account_corpway !== null)
			.sort(
				(a, b) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			) || [];

	function getNoContentIndicator() {
		if (fetchCompaniesQuery.isLoading || fetchCompaniesQuery.isFetching)
			return <Loader />;

		if (!filteredCompanies)
			return <EmptyContent text='Nenhuma empresa disponível' />;

		return null;
	}

	return (
		<>
			<S.OpenCompaniesModalButton
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
			>
				{selectedCompany ? (
					<S.CompanyInfoName
						style={{
							margin: '0 auto',
						}}
						data-rh={
							!selectedCompany
								? undefined
								: `${selectedCompany.name} - ${cnpjMask(selectedCompany.cnpj)}`
						}
					>
						{selectedCompany.name} - {cnpjMask(selectedCompany.cnpj)}
					</S.CompanyInfoName>
				) : (
					'Selecionar Empresa'
				)}
			</S.OpenCompaniesModalButton>
			{/* COMPANIES MODAL */}
			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					setIsOpen(false);
				}}
				enableClose
			>
				<S.CompanyContainer>
					{filteredCompanies.map((company) => (
						<S.CompanyOption
							onClick={() => {
								onCompanySelection(company);
								setIsOpen(false);
							}}
							key={company.id}
						>
							<img src={company.avatar_url} alt={company.name} />
							<S.CompanyInfo>
								<span>
									{company.name} - {cnpjMask(company.cnpj)}
								</span>
							</S.CompanyInfo>
						</S.CompanyOption>
					))}
					{getNoContentIndicator()}
				</S.CompanyContainer>
			</Modal>
		</>
	);
}
