export function parseCurrencyToBRLStandard(valueToBeParsed: number) {
	const numberFormatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		currencyDisplay: 'symbol',
	});
	return numberFormatter.format(valueToBeParsed);
}

export function parseCurrencyStrToNumber(currencyStr: string) {
	return Number(currencyStr.replaceAll('.', '').replace(',', '.'));
}

export function parseCurrencyStrToNumberToTest(currencyStr: string) {
	return Number(currencyStr.replace(/\./g, '').replace(',', '.'));
}
