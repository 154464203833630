import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { TransferProductOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filterParamsInitialized: FilterParams[];
	setFilterParamsInitialized: (f: FilterParams[]) => void;
	filterParamsFinalized: FilterParams[];
	setFilterParamsFinalized: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Empresa',
		filter: 'company_id',
		value: '',
		type: 'company_id',
		selected: false,
	},
	{
		name: 'Produto',
		filter: 'product',
		value: '',
		type: 'select',
		selectOptions: TransferProductOptionsFilters,
		selected: false,
	},
];

const useDisputesListStore = create<Store>()((set) => ({
	filterParamsInitialized: filtersParams,
	setFilterParamsInitialized: (f) => set({ filterParamsInitialized: f }),
	filterParamsFinalized: filtersParams,
	setFilterParamsFinalized: (f) => set({ filterParamsFinalized: f }),
	resetFilters: () =>
		set({
			filterParamsInitialized: filtersParams,
			filterParamsFinalized: filtersParams,
		}),
}));

export { useDisputesListStore };
