import { useState } from 'react';
import * as S from './styles';
import Modal from '../../../../../../components/Modal';
import { useMutation } from 'react-query';
import { fetchCardsByProductAndPan } from '../../../../../../services/queries/Disputes';
import { LocalProductOrigin } from '..';

interface CardType {
	id: string;
	pan: string;
}

interface Props {
	product: LocalProductOrigin;
	onSelectCard: (card: { id: string; pan: string }) => void;
	selectedCard: { id: string; pan: string } | null;
	disable?: boolean;
	company_id: string;
	isOpenModal: boolean;
	onClose: () => void;
}

export function SelectCardModal({
	product,
	onSelectCard,
	selectedCard,
	company_id,
	disable,
	isOpenModal,
	onClose,
}: Props) {
	const [selectedPan, setSelectedPan] = useState('');
	const [error, setError] = useState<string | null>(null);
	const [hasSearched, setHasSearched] = useState(false);

	const { mutate: searchCard, isLoading: isSearching } = useMutation(
		fetchCardsByProductAndPan,
		{
			onSuccess: (data: CardType) => {
				onSelectCard({ id: data.id, pan: data.pan });
				onClose();
				setError(null);
			},
			onError: (error: any) => {
				console.error('Erro ao buscar cartão:', error);
				setError('Cartão não encontrado com os últimos 4 dígitos fornecidos.');
				setHasSearched(true);
			},
		}
	);

	const handlePanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.slice(0, 4);
		if (/^\d{0,4}$/.test(value)) {
			setSelectedPan(value);
			setHasSearched(false);
			setError(null);
		}
	};

	const handlePanSearch = () => {
		if (selectedPan.length === 4) {
			searchCard({ company_id, product, pan: selectedPan });
		} else {
			setError('Por favor, insira exatamente 4 dígitos.');
			setHasSearched(true);
		}
	};

	const handleCloseModal = () => {
		setSelectedPan('');
		setError(null);
		setHasSearched(false);
		onClose();
	};

	return (
		<>
			<Modal
				isOpen={isOpenModal}
				enableClose={true}
				onRequestClose={handleCloseModal}
				key={Date.now()}
			>
				<S.Container>
					<S.FieldGroup>
						<S.Title>Buscar cartão</S.Title>
					</S.FieldGroup>

					<S.FieldGroup>
						<S.Field>
							<S.Input
								value={selectedPan}
								onChange={handlePanChange}
								placeholder='Digite os 4 últimos dígitos'
								maxLength={4}
								type='text'
								required
								pattern='\d{4}'
								title='Digite os últimos 4 dígitos'
								autoFocus
							/>
						</S.Field>
					</S.FieldGroup>

					<S.Button
						type='button'
						onClick={handlePanSearch}
						disabled={isSearching || selectedPan.length !== 4}
					>
						{isSearching ? 'Buscando...' : 'Buscar'}
					</S.Button>

					{isSearching && <p>Buscando cartão...</p>}

					{error && <p style={{ color: 'red' }}>{error}</p>}

					{!isSearching && hasSearched && !error && (
						<p>Cartão não encontrado com os últimos 4 dígitos: {selectedPan}</p>
					)}
				</S.Container>
			</Modal>
		</>
	);
}
