import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Razão social',
		filter: 'legal_name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'N. colaboradores',
		filter: 'number_of_collaborators',
		value: '',
		type: 'select',
		selectOptions: [
			{ text: '01-10', value: '01-10' },
			{ text: '11-50', value: '11-50' },
			{ text: '51-100', value: '51-100' },
			{ text: '101-200', value: '101-200' },
			{ text: '201-500', value: '201-500' },
			{ text: '501-1000', value: '501-1000' },
			{ text: 'Acima de 1000', value: '1000+' },
		],
		selected: false,
	},
	{
		name: 'Status',
		filter: 'registration_status',
		value: '',
		type: 'select',
		selectOptions: [
			{ text: 'Dados recebidos', value: 'step-one' },
			{ text: 'Dados aprovados', value: 'step-one-approved' },
			{ text: 'Dados reprovados', value: 'step-one-reproved' }
		],
		selected: false,
	}
];

const useCompaniesPreRegisterStepOneListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useCompaniesPreRegisterStepOneListStore };
