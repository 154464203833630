import * as S from './styles';
import * as TableStyle from '../../../components/Table/TableStyles';
import PageTitle from '../../../components/PageTitle';
import { MdNotes, MdHistory } from 'react-icons/md';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { listPreRegister } from '../../../services/queries/PreRegister';
import Loader from '../../../components/Loader';
import { preRegisterStatusToText } from '../../../utils/preRegister';
import { useState } from 'react';
import { Register } from '../../../@types';
import { Pagination } from '../../../components/Pagination';
import History from './Details/History';
import { useCompaniesPreRegisterStepOneListStore } from '../../../stores/useCompaniesPreRegisterStepOneListStore';
import { Filter } from '../../../components/Filter';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { useCompaniesPreRegisterStepTwoListStore } from '../../../stores/useCompaniesPreRegisterStepTwoListStore';
import { HiOutlineInformationCircle } from 'react-icons/hi';

export function PreRegisterCompanies() {
	const [selectedPreRegister, setSelectedPreRegister] = useState<
		Register | undefined
	>();
	const [selectedStepTwoRegister, setSelectedStepTwoRegister] = useState<
		Register | undefined
	>();
	const [stepOneCurrentPage, setStepOneCurrentPage] = useState(1);
	const [stepTwoCurrentPage, setStepTwoCurrentPage] = useState(1);
	const [stepOneFiltersParams, setStepOneFiltersParams, resetStepOneFilters] =
		useCompaniesPreRegisterStepOneListStore((state) => [
			state.filtersParams,
			state.setFiltersParams,
			state.resetFilters,
		]);

	const [stepTwoFiltersParams, setStepTwoFiltersParams, resetStepTwoFilters] =
		useCompaniesPreRegisterStepTwoListStore((state) => [
			state.filtersParams,
			state.setFiltersParams,
			state.resetFilters,
		]);

	useSetHistoryNonMatchCallbackLocation('pre-register', resetStepOneFilters);
	useSetHistoryNonMatchCallbackLocation('pre-register', resetStepTwoFilters);

	const getPreRegisterStep1 = useQuery(
		['pre-register-step-one', stepOneFiltersParams, stepOneCurrentPage],
		() => listPreRegister(stepOneCurrentPage, stepOneFiltersParams, '1'),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os pre registros. '
				);
			},
		}
	);

	const getPreRegisterStep2 = useQuery(
		['pre-register-step-two', stepTwoFiltersParams, stepTwoCurrentPage],
		() => listPreRegister(stepTwoCurrentPage, stepTwoFiltersParams, '2'),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os pre registros. '
				);
			},
		}
	);

	if (getPreRegisterStep1.isLoading || getPreRegisterStep2.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Cadastro de empresas' />
				<Loader />
			</S.Container>
		);
	}

	function hideRegisterHistory() {
		setSelectedPreRegister(undefined);
	}

	function hideStepTwoRegisterHistory() {
		setSelectedStepTwoRegister(undefined);
	}

	return (
		<S.Container>
			<S.Row>
				<S.Column>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title='Pré-Cadastro - Etapa 1'
								totalRecords={getPreRegisterStep1.data?.total ?? 0}
							/>
							<Filter
								filterParams={stepOneFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									setStepOneCurrentPage(1);
									setStepOneFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<Pagination
							onPageChange={(page) => {
								setStepOneCurrentPage(page);
							}}
							currentPage={stepOneCurrentPage}
							totalCount={getPreRegisterStep1.data?.total ?? 1}
						/>
					</TableStyle.TableHeaderContainer>

					<TableStyle.Table style={{ marginBottom: '0' }}>
						<TableStyle.TableHeader>
							<TableStyle.TableRow>
								<TableStyle.TableHeaderCell>Nome</TableStyle.TableHeaderCell>

								<TableStyle.TableHeaderCell>
									N. Colaboradores
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell style={{ textAlign: 'center' }}>
									STATUS
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell
									style={{ maxWidth: '8rem', textAlign: 'center' }}
								>
									Dados
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell
									style={{ maxWidth: '8rem', textAlign: 'center' }}
								>
									Histórico
								</TableStyle.TableHeaderCell>
							</TableStyle.TableRow>
						</TableStyle.TableHeader>

						<TableStyle.TableBody>
							{getPreRegisterStep1.data?.registers.map((data, index) => (
								<TableStyle.TableRow key={index}>
									<TableStyle.TableData style={{ maxWidth: '24rem' }}>
										{data.name}
									</TableStyle.TableData>
									<TableStyle.TableData style={{ maxWidth: '24rem' }}>
										{data.number_of_collaborators}
									</TableStyle.TableData>
									<TableStyle.TableData
										style={{
											maxWidth: '24rem',
											textAlign: 'center',
											color: preRegisterStatusToText(data.registration_status)
												.color,
										}}
									>
										{preRegisterStatusToText(data.registration_status).message}
										<HiOutlineInformationCircle
											style={{ transform: 'translateY(-5px)' }}
											data-rh={
												preRegisterStatusToText(data.registration_status)
													.description
											}
										/>
									</TableStyle.TableData>
									<TableStyle.TableData
										style={{ maxWidth: '8rem', textAlign: 'center' }}
									>
										<TableStyle.OptionsContainer style={{ width: '100%' }}>
											<TableStyle.OptionLink
												data-rh='Detalhar empresa'
												to={`/pre-register/${data.id}`}
											>
												<MdNotes />
											</TableStyle.OptionLink>
										</TableStyle.OptionsContainer>
									</TableStyle.TableData>
									<TableStyle.TableData
										style={{ maxWidth: '8rem', textAlign: 'center' }}
									>
										<TableStyle.OptionsContainer style={{ width: '100%' }}>
											<TableStyle.OptionButton
												data-rh='Histórico de alterações'
												onClick={() => setSelectedPreRegister(data)}
											>
												<MdHistory />
											</TableStyle.OptionButton>
										</TableStyle.OptionsContainer>
									</TableStyle.TableData>
								</TableStyle.TableRow>
							))}
						</TableStyle.TableBody>
					</TableStyle.Table>
				</S.Column>
				<History
					register={selectedPreRegister}
					timeline={selectedPreRegister?.history}
					isVisible={selectedPreRegister !== undefined}
					setIsVisible={hideRegisterHistory}
				/>
			</S.Row>

			<S.Row style={{ marginTop: '4rem' }}>
				<S.Column>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title='Cadastro - Etapa 2'
								totalRecords={getPreRegisterStep2.data?.total ?? 0}
							/>
							<Filter
								filterParams={stepTwoFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									setStepTwoCurrentPage(1);
									setStepTwoFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<Pagination
							onPageChange={(page) => {
								setStepTwoCurrentPage(page);
							}}
							currentPage={stepTwoCurrentPage}
							totalCount={getPreRegisterStep2.data?.total ?? 1}
						/>
					</TableStyle.TableHeaderContainer>

					<TableStyle.Table style={{ marginBottom: '0' }}>
						<TableStyle.TableHeader>
							<TableStyle.TableRow>
								<TableStyle.TableHeaderCell>
									Razão Social
								</TableStyle.TableHeaderCell>

								<TableStyle.TableHeaderCell>
									N. Colaboradores
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell style={{ textAlign: 'center' }}>
									STATUS
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell
									style={{ maxWidth: '8rem', textAlign: 'center' }}
								>
									Dados
								</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell
									style={{ maxWidth: '8rem', textAlign: 'center' }}
								>
									Histórico
								</TableStyle.TableHeaderCell>
							</TableStyle.TableRow>
						</TableStyle.TableHeader>

						<TableStyle.TableBody>
							{getPreRegisterStep2.data?.registers.map((data, index) => (
								<TableStyle.TableRow key={index}>
									<TableStyle.TableData style={{ maxWidth: '24rem' }}>
										{data.legal_name}
									</TableStyle.TableData>
									<TableStyle.TableData style={{ maxWidth: '24rem' }}>
										{data.number_of_collaborators}
									</TableStyle.TableData>
									<TableStyle.TableData
										style={{
											maxWidth: '24rem',
											textAlign: 'center',
											color: preRegisterStatusToText(data.registration_status)
												.color,
										}}
									>
										{preRegisterStatusToText(data.registration_status).message}
										<HiOutlineInformationCircle
											style={{ transform: 'translateY(-5px)' }}
											data-rh={
												preRegisterStatusToText(data.registration_status)
													.description
											}
										/>
									</TableStyle.TableData>
									<TableStyle.TableData
										style={{ maxWidth: '24rem', textAlign: 'center' }}
									>
										<TableStyle.OptionsContainer style={{ width: '100%' }}>
											<TableStyle.OptionLink
												data-rh='Detalhar empresa'
												to={`/pre-register/${data.id}`}
											>
												<MdNotes />
											</TableStyle.OptionLink>
										</TableStyle.OptionsContainer>
									</TableStyle.TableData>
									<TableStyle.TableData>
										<TableStyle.OptionsContainer style={{ width: '100%' }}>
											<TableStyle.OptionButton
												data-rh='Histórico de alterações'
												onClick={() => setSelectedStepTwoRegister(data)}
											>
												<MdHistory />
											</TableStyle.OptionButton>
										</TableStyle.OptionsContainer>
									</TableStyle.TableData>
								</TableStyle.TableRow>
							))}
						</TableStyle.TableBody>
					</TableStyle.Table>
				</S.Column>
				<History
					register={selectedStepTwoRegister}
					timeline={selectedStepTwoRegister?.history}
					isVisible={selectedStepTwoRegister !== undefined}
					setIsVisible={hideStepTwoRegisterHistory}
				/>
			</S.Row>
		</S.Container>
	);
}
