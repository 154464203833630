import styled from 'styled-components';
import { Option } from '../../../../components/Form/FormStyles';
import { OptionsButton } from '../../../../components/Button';
import { CurrencyInput } from '../../../../components/CurrencyInput';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;
	margin-top: 2rem;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin-bottom: 2rem;
	column-gap: 0.5rem;
`;

export const RecipientEmailContainer = styled.div`
	display: flex;
	flex-direction: column;
	& > label {
		font-weight: normal;
		display: flex;
		gap: 0.4rem;
		margin-bottom: 0.5rem;
	}
`;

export const SubmitOptionsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1rem;
	column-gap: 0.5rem;
	& > span {
		font-size: 1.4rem;
	}
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	width: 15rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ClearButton = styled(Option)`
	height: 3rem;
	width: 3rem;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	& svg {
		width: 2rem;
		height: 2rem;
	}
`;

export const GeneratedPaymentsContainer = styled.div`
	width: 100%;
`;

export const BillingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;

	& > span {
		font-size: 1.6rem;
		font-weight: 600;
	}
`;

export const DownloadBillingButton = styled.a`
	${OptionsButton}
	width: 10rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const QRCodeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const QRCodeFigure = styled.figure`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const QRCodeImg = styled.img``;

export const CopyQRCodeBtn = styled.button`
	${OptionsButton}
	width: 15rem;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const QRCodeButtonsContainer = styled.div`
	display: flex;
	gap: 1.5rem;
`;

export const QRcodeCaption = styled.figcaption`
	margin-bottom: 1rem;
	color: var(--dark-gray);
	font-size: 1.5rem;
`;

export const Caption = styled.span`
	margin-top: 4rem;
	& > span {
		font-size: 1.5rem;
	}
`;

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 0.5rem 1.2rem 0.5rem;
	font-size: 1.4rem;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
`;

export const Label = styled.label`
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
	position: relative;
`;

export const FormCurrencyInput = styled(CurrencyInput)`
	width: 100%;
	max-width: 20rem;
	min-width: 10rem;
	height: 3rem;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: none;
	padding: 0 1rem;
`;
