import * as S from './styles';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import { RiCloseLine } from 'react-icons/ri';
import { parseCurrencyToBRLStandard } from '../../../../utils/parseCurrency';
import qrcode from 'qrcode';
import { convertReaisToCents } from '../../../../utils/CurrencyConvert';
import {
	generateBilling,
	generateQRCode,
} from '../../../../services/queries/Billing';
import PageTitle from '../../../../components/PageTitle';

export async function generateQRCodeImage(emv: string) {
	const img = await qrcode.toDataURL(emv);
	return img;
}

export default function GenerateBilling() {
	const [value, setValue] = useState(0);
	const [qrCodeImg, setQRCodeImg] = useState('');
	const [billingPDFURL, setBillingPDFURL] = useState('');
	const lastValue = useRef<number>(0);
	// const recipientEmail = EMAILS[recipientEmailOption as keyof typeof EMAILS];

	const generateQRCodeMutation = useMutation(
		async (amount: number) => generateQRCode(amount),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o QRCode. Tente novamente.'
				);
			},
			onSuccess: async (data) => {
				setQRCodeImg(await generateQRCodeImage(data.emv));
			},
		}
	);

	const generateBillingMutation = useMutation(
		async (amount: number) => generateBilling(amount),
		{
			onSuccess: () => {
				toast.info(
					'O boleto ainda não está disponível. O destinatário será notificado quando ele estiver disponível e receberá via email o pdf.'
				);
			},
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o boleto. Tente novamente.'
				);
			},
		}
	);

	function handleChangeValue(value: number) {
		setValue(value);
	}

	const isDisabled =
		generateQRCodeMutation.isLoading || generateBillingMutation.isLoading;

	function handleSubmit(type: 'billing' | 'pix') {
		if (value <= 0) {
			toast.error('Valor abaixo do mínimo');
			return;
		}

		setQRCodeImg('');
		setBillingPDFURL('');

		const amountInCents = convertReaisToCents(value);
		lastValue.current = value;
		if (type === 'pix') {
			generateQRCodeMutation.mutate(amountInCents);
		} else {
			generateBillingMutation.mutate(amountInCents);
		}
	}

	function handleCopyQRCode() {
		navigator.clipboard.writeText(generateQRCodeMutation.data!.emv);

		toast.info('Código PIX copiado!', {
			autoClose: 3500,
		});
	}

	function resetPage() {
		setValue(0);
		setQRCodeImg('');
		setBillingPDFURL('');
	}

	return (
		<S.Container>
			<PageTitle title='Adicionar saldo a carteira do MASTER' />

			<S.Content>
				<S.FormContainer>
					<S.Field>
						<S.Label>Valor</S.Label>

						<S.FormCurrencyInput
							onValueChange={({ floatValue }) => handleChangeValue(floatValue)}
							value={value}
							disabled={isDisabled}
						/>
					</S.Field>

					<S.SubmitOptionsContainer>
						<S.SubmitButton
							disabled={isDisabled}
							onClick={() => handleSubmit('pix')}
						>
							{generateQRCodeMutation.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'GERAR PIX'
							)}
						</S.SubmitButton>

						<span> ou </span>

						<S.SubmitButton
							onClick={() => handleSubmit('billing')}
							disabled={isDisabled}
						>
							{generateBillingMutation.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'GERAR BOLETO'
							)}
						</S.SubmitButton>

						{(qrCodeImg || billingPDFURL) && (
							<S.ClearButton
								data-rh='Limpar'
								type='button'
								onClick={resetPage}
								data-testid='clear-page'
							>
								<RiCloseLine />
							</S.ClearButton>
						)}
					</S.SubmitOptionsContainer>
				</S.FormContainer>

				<S.GeneratedPaymentsContainer>
					{!!qrCodeImg && (
						<S.QRCodeContainer>
							<S.QRCodeFigure>
								<S.QRCodeImg src={qrCodeImg} alt='QRcode gerado' />
								<S.QRcodeCaption>
									Solicitação de pagamento* gerado por QR Code no valor de{' '}
									{parseCurrencyToBRLStandard(lastValue.current)}
								</S.QRcodeCaption>
								<S.QRcodeCaption>
									Você também pode acessar o QR Code através do email
									cadastrado.
								</S.QRcodeCaption>
							</S.QRCodeFigure>

							<S.QRCodeButtonsContainer>
								<S.CopyQRCodeBtn onClick={handleCopyQRCode}>
									Copiar código
								</S.CopyQRCodeBtn>
							</S.QRCodeButtonsContainer>
							<S.Caption>
								<span>*</span>Pagamento efetuado via Pix
							</S.Caption>
						</S.QRCodeContainer>
					)}
				</S.GeneratedPaymentsContainer>
			</S.Content>
		</S.Container>
	);
}
