import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import api from '../api';

export interface CreateDisputeParams {
	company_id: string;
	product: string;
	card_id: string | null;
	amount: number;
	start_date_dispute: string | null;
	dispute_description: string;
	transaction_id: string | null;
	swap_required: boolean;
}

export async function createDispute(
	params: CreateDisputeParams
): Promise<void> {
	const response = await api.post('/api/v1/master/card-dispute', params);
	return response.data;
}

export async function getDisputesByStatus(
	status: string,
	filters: FilterParams[],
	page: number = 1,
	limit: number = 10
) {
	const { data } = await api.get('/api/v1/master/card-disputes', {
		params: {
			status,
			page,
			limit,
			...parseFilterParamsToParams(filters),
		},
	});
	return data;
}

export async function fetchDispute(id: string) {
	const { data } = await api.get(`/api/v1/master/card-disputes/${id}`);
	return data;
}

export type TransactionDisputeParams = {
	dispute_id: string;
	company_id: string;
};

export async function createTransactionDisputeAmountToCompany(
	params: TransactionDisputeParams
) {
	const { dispute_id, company_id } = params;

	const { data } = await api.post(
		`/api/v1/master/card-disputes/${dispute_id}/transaction`,
		{
			company_id,
		}
	);

	return data;
}

type fetchCardsByProductAndPanParams = {
	product: string;
	pan: string;
	company_id: string;
};

export async function fetchCardsByProductAndPan({
	product,
	pan,
	company_id,
}: fetchCardsByProductAndPanParams): Promise<any> {
	const { data } = await api.post(
		`/api/v1/master/card-dispute/card/${company_id}/${product}`,
		{
			pan,
		}
	);

	return data;
}

export interface UpdateDisputeParams {
	id: string;
	product?: string;
	card_id?: string | null;
	amount?: number;
	start_date_dispute?: string | null;
	dispute_description?: string;
	transaction_id?: string | null;
	swap_required?: boolean;
}

export async function updateDispute(params: UpdateDisputeParams): Promise<any> {
	const { id, ...updateData } = params;

	try {
		const response = await api.put(
			`/api/v1/master/card-dispute/update/${id}`,
			updateData
		);
		return response.data;
	} catch (error: any) {
		console.error(
			'Erro ao atualizar disputa:',
			error.response ? error.response.data : error.message
		);
		throw error;
	}
}
