import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import * as S from './styles';
import * as FormStyle from '../../../../components/Form/FormStyles';
import { useMutation } from 'react-query';
import {
	createGroupCompany,
	GroupCompanyCreationBodyQuery,
} from '../../../../services/queries/GroupCompany';
import { trimObjectData } from '../../../../utils/trimObjectData';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputField from '../../../../componentsV2/ui/InputField';

const schema = z.object({
	name: z.string().min(1, { message: 'O campo titulo deve ser preenchido' }),
});

interface GroupCompanyCreationModalProps {
	onGroupCompanyCreate(): void;
}

interface FormData {
	name: string;
}

export function GroupCompanyCreationModal({
	onGroupCompanyCreate,
}: GroupCompanyCreationModalProps) {
	const [modalOpen, setModalOpen] = useState(false);

	const createGroupCompanyQuery = useMutation(
		(bodyData: GroupCompanyCreationBodyQuery) => {
			return createGroupCompany(bodyData);
		},
		{
			onSuccess: () => {
				onGroupCompanyCreate();
				toast.info('Grupo corporativo criado com sucesso.');
				setModalOpen(false);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível criar o grupo corporativo. '
				);
				setModalOpen(false);
			},
		}
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(schema),
		defaultValues: {
			name: '',
		},
	});

	async function handleSubmitGroupCompanyCreation(data: FormData) {
		createGroupCompanyQuery.mutate(
			trimObjectData({
				name: data.name,
			})
		);
	}

	return (
		<>
			<S.AddGroupCompany
				onClick={() => {
					setModalOpen(true);
				}}
			>
				Adicionar grupo corporativo
			</S.AddGroupCompany>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false);
				}}
				enableClose
			>
				<FormStyle.FormContainer>
					<FormStyle.FieldGroup>
						<InputField
							label={'Título do grupo corporativo'}
							type='text'
							name={'name'}
							register={register}
							placeholder='Título'
							errorMessage={errors.name?.message}
						/>
					</FormStyle.FieldGroup>
					<FormStyle.ButtonsContainer>
						<S.CancelOption
							onClick={() => {
								setModalOpen(false);
							}}
						>
							Cancelar
						</S.CancelOption>
						<FormStyle.FormButton
							onClick={handleSubmit(handleSubmitGroupCompanyCreation)}
						>
							Salvar
						</FormStyle.FormButton>
					</FormStyle.ButtonsContainer>
				</FormStyle.FormContainer>
			</Modal>
		</>
	);
}
