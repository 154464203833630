import { useState } from 'react';
import { useQuery } from 'react-query';
import { Company } from '../../@types';
import { fetchGroupCompany } from '../../services/queries/GroupCompany';
import { cnpjMask } from '../../utils/masks';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import Modal from '../Modal';
import * as S from './styles';
import { convertCentsToReais } from '../../utils/CurrencyConvert';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { getCompaniesSelect } from '../../services/queries/Companies';
import { CSSProperties } from 'styled-components';

export interface SelectCompanyProps {
	groupCompanyId?: string | null;
	onCompanySelection: (c: Company) => void;
	disabled?: boolean;
	style?: CSSProperties;
	selectedCompanyId?: string;
}
export function SelectCompany({
	groupCompanyId = null,
	onCompanySelection,
	disabled = false,
	style,
	selectedCompanyId,
}: SelectCompanyProps) {
	const [isOpen, setIsOpen] = useState(false);
	let queryKey: string | string[];
	let queryFn: () => Promise<any>;

	if (groupCompanyId !== null && groupCompanyId !== undefined) {
		queryKey = ['fetchGroupCompany', groupCompanyId];
		queryFn = () => fetchGroupCompany(groupCompanyId);
	} else {
		queryKey = 'companiesSelect';
		queryFn = getCompaniesSelect;
	}

	const { data, isLoading } = useQuery(queryKey, queryFn, {
		onError: (err) => {
			showErrorMessage(err as Error, 'Não foi possível buscar as empresas.');
		},
		enabled: true,
		refetchOnWindowFocus: false,
	});

	function getNoContentIndicator() {
		if (isLoading) return <Loader />;

		if (Array.isArray(data) && data.length === 0) {
			return <EmptyContent text='Nenhuma empresa disponível' />;
		} else if (
			data &&
			'companies' in data &&
			data.companies.activeCompanies.length === 0
		) {
			return <EmptyContent text='Nenhuma empresa disponível' />;
		}
		return null;
	}

	const company = (
		(Array.isArray(data) ? data : data?.companies?.activeCompanies) as Company[]
	)?.find((e) => e.id === selectedCompanyId);

	return (
		<>
			<S.OpenCompaniesModalButton
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
				disabled={disabled}
				style={style}
			>
				{company ? (
					<S.CompanyInfoName
						style={{
							margin: '0 auto',
						}}
						data-rh={
							!company
								? undefined
								: `${company.name} - ${cnpjMask(company.cnpj)}`
						}
					>
						{company.name} - {cnpjMask(company.cnpj)}
					</S.CompanyInfoName>
				) : (
					'Selecionar Empresa'
				)}
			</S.OpenCompaniesModalButton>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					setIsOpen(false);
				}}
				enableClose
			>
				<S.CompanyContainer>
					{Array.isArray(data)
						? data.map((company) => (
								<S.CompanyOption
									onClick={() => {
										onCompanySelection(company);
										setIsOpen(false);
									}}
									key={company.id!}
								>
									<img src={company.avatar_url} alt={company.name} />
									<S.CompanyInfo>
										<span>
											{company.name} - {cnpjMask(company.cnpj)}
										</span>
									</S.CompanyInfo>
								</S.CompanyOption>
						  ))
						: data?.companies?.activeCompanies.map((company: Company) => (
								<S.CompanyOption
									onClick={() => {
										onCompanySelection(company);
										setIsOpen(false);
									}}
									key={company.id!}
								>
									<img src={company.avatar_url} alt={company.name} />
									<S.CompanyInfo>
										<span>
											{company.name} - {cnpjMask(company.cnpj)}
										</span>
										<S.CompanyBalanceContainer>
											<span>
												Saldo Benefícios Flexíveis:{' '}
												{convertCentsToReais(company.multiflex_balance)}
											</span>
											<span>
												Saldo Bounty Control:{' '}
												{convertCentsToReais(company.corpway_balance)}
											</span>
										</S.CompanyBalanceContainer>
									</S.CompanyInfo>
								</S.CompanyOption>
						  ))}

					{getNoContentIndicator()}
				</S.CompanyContainer>
			</Modal>
		</>
	);
}
