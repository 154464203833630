import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	height: 30rem;
	border-radius: 2rem;
	padding: 4rem;
	padding-top: 1rem;
	gap: 1rem;
`;

export const SubContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 17rem;
`;

export const FieldGroup = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 6rem;
	height: 6rem;
	background-color: #ffebf0;
	border-radius: 50px;
`;

export const Label = styled.label`
	font-size: 1.4rem;
	margin-bottom: 0.5rem;
	color: #393939;
	font-weight: 400;
`;

export const Title = styled.h1`
	font-size: 2.4rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	color: inherit;
	display: flex;
	align-items: center;
	& > img:first-child {
		margin-right: 1rem;
	}
`;

export const CancelContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 3rem;
`;

export const CancelOption = styled.button`
	${OptionsButton}
	border: 1px solid #929292;
	background: #ffffff;
	width: 20rem;
	border-radius: 5rem;
	color: #393939;
`;
