import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const HeaderContainer = styled.div`
	display: flex;
	max-width: 100%;
	justify-content: space-between;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 1.2rem;
	row-gap: 0.5rem;
	margin-top: 2rem;	
		& > h1 {
				margin-bottom: 1rem;
				margin-top: 1rem;
				font-size: 20px;
		}
`

export const Details = styled.details`
    & > summary {
        padding: 1rem  0 1rem 0;
        border-bottom: 1px solid var(--light-gray);
        cursor: pointer;
        list-style: none;
    }

    & svg {
        color: var(--primary-blue);
        width: 3rem;
        height: 3rem;
        transition: all 0.5s ease-in-out;
    }

    &[open] svg {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
    }

    &[open] summary {
        border-bottom: 1px solid var(--light-gray);
				margin-bottom: 1rem;
    }
`;

export const SectionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
