import { Header } from '../../components/Header';
import avatarSVG from '../../assets/avatar.svg';
import MainRoutes from '../../routes/MainRoutes';
import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';
import { SidebarLink } from '../../components/SidebarLink';
import { useLocation } from 'react-router-dom';

import { BiSpreadsheet, BiStoreAlt, BiTransfer } from 'react-icons/bi';
import { RiExchangeDollarLine, RiNumbersFill } from 'react-icons/ri';
import {
	FaCcMastercard,
	FaCreditCard,
	FaDollarSign,
	FaHandsHelping,
	FaInfoCircle,
	FaUserFriends,
} from 'react-icons/fa';
import { useQuery } from 'react-query';
import { getPartners } from '../../services/queries/Partners';
import { SidebarDropdown } from '../../components/SidebarDropdown';
import { BsBox } from 'react-icons/bs';

export function Main() {
	const { user } = useAuth();
	const { pathname } = useLocation();

	const getPartnersQuery = useQuery(['fetch-partners'], getPartners);

	return (
		<>
			<Header name={'MASTER'} email={user.email} avatar={avatarSVG} />
			<S.Container>
				<S.MainContent style={{ display: 'flex', minHeight: '100vh' }}>
					<S.Sidebar>
						<SidebarLink
							icon={BiSpreadsheet}
							title='Grupos corporativos'
							path={`/group-companies`}
							currentPage={pathname === '/group-companies'}
						/>
						<SidebarLink
							icon={BiStoreAlt}
							title='Cadastro de empresas'
							path={`/pre-register`}
							currentPage={pathname === '/pre-register'}
						/>
						<SidebarDropdown
							title='Financeiro'
							icon={FaCreditCard}
							subMenuItems={[
								{
									path: '/transfers',
									title: 'Histórico de transferências',
									icon: BiTransfer,
									strictPathCompare: pathname.includes('/transfers'),
								},
								{
									path: '/funds-out',
									title: 'Histórico de TEDs',
									icon: BiTransfer,
									strictPathCompare: pathname.includes('/funds-out'),
								},
								{
									path: '/billing',
									title: 'Boletos',
									icon: FaDollarSign,
									strictPathCompare: pathname.includes('/billing'),
								},
								{
									path: '/disputes',
									title: 'Chargebacks de transações',
									icon: BiTransfer,
									strictPathCompare: pathname.includes('/disputes'),
								},
								{
									path: '/chargebacks',
									title: 'Estornos de saldo das empresas',
									icon: RiExchangeDollarLine,
									strictPathCompare: pathname.includes('/chargebacks'),
								},
								{
									path: '/new-companies',
									title: 'Crédito inicial',
									icon: FaCreditCard,
									strictPathCompare: pathname.includes('/new-companies'),
								},
								{
									path: '/recharge-master',
									title: 'Adicionar saldo',
									icon: FaCreditCard,
									strictPathCompare: pathname.includes('/recharge-master'),
								},
							]}
						/>
						<SidebarDropdown
							title='Cartões'
							icon={FaCreditCard}
							subMenuItems={[
								{
									path: '/corpway-cards',
									title: 'Virtuais',
									icon: FaCcMastercard,
									product: 'control',
									strictPathCompare: pathname.includes('/corpway-cards'),
								},
								{
									path: '/cards',
									title: 'Remessas',
									icon: FaCreditCard,
									product: 'multiflex',
									strictPathCompare:
										pathname.includes('/cards/stats') ||
										pathname.includes('/cards/stock'), // don't consider stats route as part of /cards list route
								},
								{
									path: '/cards/stats',
									title: 'Segunda via',
									product: 'multiflex',
									icon: RiNumbersFill,
								},
								{
									path: '/cards/stock',
									title: 'Estoque',
									product: 'multiflex',
									icon: BsBox,
								},
							]}
						/>

						<SidebarLink
							icon={FaInfoCircle}
							title='Notificar Usuários'
							path={'/notify-users'}
							currentPage={pathname.includes('notify-users')}
						/>
						<S.CustomLabelStyled label='multiflex'>
							{' '}
							Benefícios Flexíveis{' '}
						</S.CustomLabelStyled>

						<SidebarLink
							icon={FaUserFriends}
							title='Colaboradores menores'
							path={`/underage-documents`}
							currentPage={pathname.includes('underage-documents')}
						/>
						{/* conditionally hide */}
						{!!getPartnersQuery.data && getPartnersQuery.data.length > 0 && (
							<SidebarLink
								icon={FaHandsHelping}
								title='Gerenciamento de Parceiros'
								path={`/partners`}
								currentPage={pathname.includes('partners')}
							/>
						)}
					</S.Sidebar>

					<S.PagesContainer>
						<MainRoutes />
					</S.PagesContainer>
				</S.MainContent>
			</S.Container>
		</>
	);
}
