import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MainContent = styled.div`
	min-height: 100vh;
`;

export const Sidebar = styled.aside`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 30rem;
	padding: 1rem;
	border-right: 1px solid var(--light-gray);
`;

export const PagesContainer = styled.div`
	flex: 1;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
`;

export const CustomLabelStyled = styled.div<{ label?: string }>`
	font-size: 1.8rem;
	margin-bottom: 0.625rem;
	margin-top: 0.625rem;
	justify-content: center;
	color: ${(props) =>
		props.label === 'corpway' ? 'var(--primary-blue)' : 'var(--primary-red)'};
	filter: ${(props) =>
		props.label === 'corpway' ? 'brightness(70%)' : 'none'};
`;
