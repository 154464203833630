import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';
import { CurrencyInput } from '../../../../../components/CurrencyInput';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;
export const FormContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 90rem;
	height: 100%;
	font-size: 1.4rem;
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 0.2rem solid var(--light-gray);
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	width: 25rem;
	margin-top: 2rem;
	height: 4.6rem;
	border-radius: 5rem;
	padding: 12px 24px 12px 24px;
	gap: 10px;
	border: 1px solid #929292;
	opacity: 0px;
`;

export const FieldSet = styled.fieldset`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	border: none;
`;

export const FieldGroup = styled.div`
	display: flex;
	width: 100%;
	gap: 0px;
	justify-content: space-between;
	opacity: 0px;
`;

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 0 0.5rem 1.2rem 0.5rem;

	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}

	> span {
		color: var(--primary-red);
		margin-top: 0.5rem;
	}
`;

export const SelectInput = styled.select`
	width: 25rem;
	height: 4.6rem;
	padding: 12px 20px 12px 20px;
	gap: 10px;
	border-radius: 6px;
	opacity: 0px;
	background: #f0f4f9;
	border: none;
`;

export const Label = styled.label`
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	color: #393939;
`;

export const ButtonModal = styled.button`
	width: 25rem;
	height: 4.5rem;
	padding: 12px 32px 12px 32px;
	gap: 12px;
	border-radius: 50rem;
	opacity: 0px;
	border: 1px solid #191919;
	background: #fff;
	color: #393939;
	font-weight: 600;
`;

export const Input = styled.input`
	height: 4.6rem;
	color: var(--dark-gray);
	border-radius: 6px;
	border: none;
	width: 250px;
	padding: 12px 20px 12px 20px;
	gap: 10px;
	opacity: 0px;
	background: #f0f4f9;

	&::placeholder {
		color: var(--dark-gray);
	}
`;

export const InputTextArea = styled.textarea`
	resize: none;
	width: 52.5rem;
	height: 29.2rem;
	padding: 12px 20px 12px 20px;
	gap: 10px;
	border-radius: 6px;
	opacity: 0px;
	background: #f0f4f9;
	border: none;
`;

export const CurrencyInputStyled = styled(CurrencyInput)`
	height: 4.6rem;
	color: var(--dark-gray);
	border-radius: 6px;
	border: none;
	width: 250px;
	padding: 12px 20px 12px 20px;
	gap: 10px;
	opacity: 0px;
	background: #f0f4f9;
`;
