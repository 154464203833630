import { useState } from 'react';
import Modal from '../../../../../../components/Modal';
import * as S from './styles';
import * as warning from '../../CreateDispute/ConfirmationSwapRequiredModal/styles';
import { Company } from '../../../../../../@types';
import { convertCentsToReais } from '../../../../../../utils/CurrencyConvert';
import { cnpjMask } from '../../../../../../utils/masks';
import { useMutation } from 'react-query';
import {
	createTransactionDisputeAmountToCompany,
	TransactionDisputeParams,
} from '../../../../../../services/queries/Disputes';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { toast } from 'react-toastify';
import Loader from '../../../../../../components/Loader';
import { MdWarningAmber } from 'react-icons/md';
import ForbiddenModal from '../../ForbiddenModal';

interface ConfirmationTransfersModalProps {
	company: Company;
	dispute_id: string;
	product: string;
	value: number;
	dispute_value: number | null;
	onTransferComplete: () => void;
}

export function ConfirmationTransfersModal({
	company,
	dispute_id,
	product,
	value,
	dispute_value,
	onTransferComplete,
}: ConfirmationTransfersModalProps) {
	const [isOpen, setOpen] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [showForbiddenModal, setShowForbiddenModal] = useState(false);
	const [isConfirmed, setIsConfirmed] = useState(false);

	const { mutate, isLoading } = useMutation(
		(params: TransactionDisputeParams) => {
			return createTransactionDisputeAmountToCompany(params);
		},
		{
			onSuccess: () => {
				toast.info('Transferência realizada com sucesso');
				setOpen(false);
				onTransferComplete();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar realizar a transferência.'
				);
			},
		}
	);
	function handleTransferClick() {
		const numericValue = Number(value);
		const numericDisputeValue = Number(dispute_value);

		if (dispute_value === null || isNaN(numericDisputeValue)) {
			handleSubmit();
			return;
		}

		if (numericValue < numericDisputeValue) {
			setShowWarningModal(true);
		} else if (numericValue === numericDisputeValue) {
			handleSubmit();
		} else {
			setShowForbiddenModal(true);
		}
	}

	async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
		e?.preventDefault();

		mutate({
			dispute_id,
			company_id: company.id!,
		});
	}

	function handleWarningConfirm() {
		setShowWarningModal(false);
		setOpen(true);
		setIsConfirmed(true);
	}

	return (
		<>
			<S.SubmitButton onClick={handleTransferClick} disabled={isLoading}>
				{isLoading ? <Loader size={16} /> : 'Transferir'}
			</S.SubmitButton>

			{showWarningModal && (
				<Modal
					isOpen={showWarningModal}
					enableClose={false}
					onRequestClose={() => setShowWarningModal(false)}
				>
					<warning.Container>
						<warning.SubContainer>
							<warning.FieldGroup
								style={{
									flexDirection: 'column',
									alignItems: 'center',
									gap: '2rem',
								}}
							>
								<warning.IconContainer>
									<MdWarningAmber color='red' size={30} />
								</warning.IconContainer>
								<S.Title>Atenção!</S.Title>
								<S.Label>
									O valor original da transação é de{' '}
									{convertCentsToReais(dispute_value!)}, você está transferindo{' '}
									{convertCentsToReais(value)}. Deseja continuar?
								</S.Label>
							</warning.FieldGroup>
						</warning.SubContainer>
						<S.ButtonsContainer>
							<S.CancelOption onClick={() => setShowWarningModal(false)}>
								Cancelar
							</S.CancelOption>
							<S.TransactionButton onClick={handleWarningConfirm}>
								Confirmar
							</S.TransactionButton>
						</S.ButtonsContainer>
					</warning.Container>
				</Modal>
			)}

			<ForbiddenModal
				isOpen={showForbiddenModal}
				onClose={() => setShowForbiddenModal(false)}
				message={`O valor da transação não pode ser maior do que o valor da chargeback (${convertCentsToReais(
					dispute_value!
				)}).`}
			/>

			<Modal
				isOpen={isOpen}
				enableClose={false}
				onRequestClose={() => setOpen(false)}
			>
				<S.Container>
					<S.SubContainer>
						<S.FieldGroup>
							<S.Title
								style={{
									textAlign: 'center',
									flexDirection: 'column',
									gap: '2rem',
								}}
							>
								Realizar transação
								<span
									style={{
										display: 'block',
										margin: '0 auto',
										width: '10rem',
										borderBottom: '0.4rem solid var(--primary-blue)',
									}}
								/>
							</S.Title>
						</S.FieldGroup>

						<S.InfoContainer>
							<S.InfoRow>
								<span style={{ fontSize: '1.6rem' }}>
									<strong>Empresa:</strong> {company.name}
								</span>
								<span style={{ fontSize: '1.6rem' }}>
									<strong>Produto:</strong> {product}
								</span>
							</S.InfoRow>
							<S.InfoRow>
								<span style={{ fontSize: '1.6rem' }}>
									<strong>CNPJ:</strong> {cnpjMask(company.cnpj)}
								</span>
								<span style={{ fontSize: '1.6rem' }}>
									<strong>Valor:</strong> {convertCentsToReais(value)}
								</span>
							</S.InfoRow>
						</S.InfoContainer>
					</S.SubContainer>

					<form onSubmit={handleSubmit}>
						<S.ButtonsContainer>
							<S.CancelOption onClick={() => setOpen(false)}>
								Cancelar
							</S.CancelOption>
							<S.TransactionButton type='submit' disabled={isLoading}>
								{isLoading ? <Loader size={16} /> : 'Confirmar'}
							</S.TransactionButton>
						</S.ButtonsContainer>
					</form>
				</S.Container>
			</Modal>
		</>
	);
}
