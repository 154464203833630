import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FirstCredit } from '../../../../@types';
import {
	FirstCreditListReturn,
	getMasterTransfers,
} from '../../../../services/queries/Tranfers';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as TableStyle from '../../../../components/Table/TableStyles';

import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import PageTitle from '../../../../components/PageTitle';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Pagination } from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import * as S from './styles';
import CompanyCard from '../../../../components/CompanyCard';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { Filter, FilterParams } from '../../../../components/Filter';
import { TransferProductOptionsFilters } from '../../../../components/Filter/utils/SelectFilters';
import { ProductOriginLabel } from '../../../../components/ProductOriginLabel';
import { parseDate } from '../../../../utils/parseDate';

export function NewCompaniesTransfers() {
	const queryClient = useQueryClient();
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [transfersCurrentPage, setTransfersCurrentPage] = useState(1);
	const [showingTable, setShowingTable] = useState(false);
	const [filtersParams, setFiltersParams] = useState<FilterParams[]>([
		{
			name: 'Data',
			filter: 'date',
			value: '',
			type: 'date_interval',
			selected: false,
		},
		{
			name: 'Empresa',
			filter: 'company_name',
			value: '',
			type: 'company',
			selected: false,
		},
		{
			name: 'Produto',
			filter: 'origin',
			value: '',
			type: 'select',
			selectOptions: TransferProductOptionsFilters,
			selected: false,
		},
	]);

	function refreshPage() {
		setTransfersCurrentPage(1);
		queryClient.resetQueries('masterTransfersList');
	}

	const fetchTransfersQuery = useQuery<FirstCreditListReturn, Error>(
		[
			'masterTransfersList',
			transfersCurrentPage,
			currentSortColumn,
			filtersParams,
		],
		() => {
			return getMasterTransfers(
				filtersParams,
				transfersCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de transferências. '
				);
			},
		}
	);

	function generateRow(transfer: FirstCredit) {
		return (
			<TableStyle.TableRow key={transfer.id}>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{parseDate(transfer.created_at, 1)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{transfer.company ? (
						<CompanyCard
							company_name={transfer.company.name}
							cnpj={transfer.company.cnpj}
							avatar_url={transfer.company.avatar_url}
						/>
					) : (
						'N/A'
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>{transfer.justification}</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{convertCentsToReais(Number(transfer.amount))}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<ProductOriginLabel origin={transfer.product} />
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(transfers: FirstCredit[]) {
		if (!transfers.length) {
			return (
				<>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title='Histórico de transferências de crédito inicial'
								totalRecords={fetchTransfersQuery.data?.total}
							/>
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setTransfersCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TableHeaderContainer>

					<EmptyContent text='Sem transferências de crédito inicial.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Histórico de transferências de crédito inicial'
							totalRecords={fetchTransfersQuery.data?.total}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setTransfersCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{showingTable ? (
							<TableStyle.SeeLess onClick={() => setShowingTable(false)}>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore onClick={() => setShowingTable(true)}>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					{showingTable && (
						<Pagination
							onPageChange={(page) => setTransfersCurrentPage(page)}
							currentPage={transfersCurrentPage}
							totalCount={
								fetchTransfersQuery.data ? fetchTransfersQuery.data.total : 1
							}
						/>
					)}
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								Justificativa
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount')}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'origin'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('origin')}
								/>
								PRODUTO
							</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable
							? transfers.map((transfer) => generateRow(transfer))
							: generateRow(transfers[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		!fetchTransfersQuery.data ||
		fetchTransfersQuery.isLoading ||
		fetchTransfersQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Histórico de créditos iniciais' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(fetchTransfersQuery.data.transfers)}

			<S.ButtonsContainer>
				<S.CreateTranfer to='/new-companies/credit'>
					Criar transferência
				</S.CreateTranfer>
			</S.ButtonsContainer>
		</S.Container>
	);
}
