import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin: 2rem 0;
`;

export const InfoRow = styled.div`
	display: flex;
	column-gap: 6rem;
	& + & {
		margin-bottom: 2rem;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	margin-bottom: 3rem;
	width: fit-content;
`;

export const GroupCompanyContainer = styled.div`
	display: flex;
	column-gap: 1rem;
	width: 30rem;
	margin: 2rem 0;
`;

export const TableTitleContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 3rem;
	margin-bottom: 1rem;
`;

export const MonthInput = styled.input`
	height: 3rem;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 1);
	border-radius: 0.4rem;
	border: none;
	padding: 0 1rem;
`;

export const TitleInfoContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	text-transform: uppercase;
`;

export const IconContainer = styled.div`
	display: flex;
	align-self: center;
	> svg {
		color: var(--dark-gray);
		width: 2rem;
		height: 2rem;
	}
`;

export const TableContainer = styled.div`
	max-height: 30rem;
	overflow-y: auto;
	& > table {
		margin-bottom: 0;
	}
`;

export const spreadSheetButton = styled.button`
	${OptionsButton}
	height: 100%;
	padding: 0.5rem 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18rem;
`;
