import { create } from 'zustand';
import { FilterParams, SelectOption } from '../components/Filter';

const fundsOutDescriptionOptions: SelectOption[] = [
	{ text: 'CRÉDITO EM CONTA CORRENTE', value: 'CRÉDITO EM CONTA CORRENTE' },
	{
		text: 'PAGAMENTO DE ALUGUEL/CONDOMINIOS',
		value: 'PAGAMENTO DE ALUGUEL/CONDOMINIOS',
	},
	{
		text: 'PAGAMENTO DE DUPLICATAS/TITULOS',
		value: 'PAGAMENTO DE DUPLICATAS/TITULOS',
	},
	{ text: 'PAGAMENTO DE DIVIDENDOS', value: 'PAGAMENTO DE DIVIDENDOS' },
	{
		text: 'PAGAMENTO DE MENSALIDADES ESCOLARES',
		value: 'PAGAMENTO DE MENSALIDADES ESCOLARES',
	},
	{ text: 'PAGAMENTO DE SALÁRIOS', value: 'PAGAMENTO DE SALÁRIOS' },
	{ text: 'PAGAMENTO A FORNECEDORES', value: 'PAGAMENTO A FORNECEDORES' },
	{
		text: 'PAGAMENTO DE ARRECADAÇÃO/PAGTO TRIBUTOS',
		value: 'PAGAMENTO DE ARRECADAÇÃO/PAGTO TRIBUTOS',
	},
	{
		text: 'TRANSFERÊNCIA INTERNACIONAL EM REAIS',
		value: 'TRANSFERÊNCIA INTERNACIONAL EM REAIS',
	},
	{ text: 'TED PARA A POUPANÇA', value: 'TED PARA A POUPANÇA' },
	{ text: 'TED PARA DEPOSITO JUDICIAL', value: 'TED PARA DEPOSITO JUDICIAL' },
	{ text: 'PENSÃO ALIMENTICIA', value: 'PENSÃO ALIMENTICIA' },
	{
		text: 'RESTITUIÇÃO DE IMPOSTO DE RENDA',
		value: 'RESTITUIÇÃO DE IMPOSTO DE RENDA',
	},
	{ text: 'PAGAMENTO DE BOLSA AUXILIO', value: 'PAGAMENTO DE BOLSA AUXILIO' },
	{ text: 'REMUNERAÇÃO A COOPERADO', value: 'REMUNERAÇÃO A COOPERADO' },
	{ text: 'PAGAMENTOS DE HONORÁRIOS', value: 'PAGAMENTOS DE HONORÁRIOS' },
	{
		text: 'PAGTO PREBENDA (REMUN.PADRES/SACERDOTES)',
		value: 'PAGTO PREBENDA (REMUN.PADRES/SACERDOTES)',
	},
	{
		text: 'PAGAMENTO DE VALE TRANSPORTE',
		value: 'PAGAMENTO DE VALE TRANSPORTE',
	},
	{ text: 'SIMPLES NACIONAL', value: 'SIMPLES NACIONAL' },
	{ text: 'RESTITUIÇÃO DE TRIBUTOS-RFB', value: 'RESTITUIÇÃO DE TRIBUTOS-RFB' },
	{
		text: 'REPASSE VALORES CONVÊNIO CENTRALIZADO',
		value: 'REPASSE VALORES CONVÊNIO CENTRALIZADO',
	},
	{
		text: 'REEMBOLSO DESP. OPERAÇÕES RENDA FIXA/VAR',
		value: 'REEMBOLSO DESP. OPERAÇÕES RENDA FIXA/VAR',
	},
	{ text: 'LEI ROUANET - PATROCINIO', value: 'LEI ROUANET - PATROCINIO' },
	{ text: 'LEI ROUANET - DOAÇÃO', value: 'LEI ROUANET - DOAÇÃO' },
	{
		text: 'PGTO MULTA BACEN ATRASO IMPORTAÇÃO',
		value: 'PGTO MULTA BACEN ATRASO IMPORTAÇÃO',
	},
	{ text: 'REPASSE VALORES P/ FUNDEB', value: 'REPASSE VALORES P/ FUNDEB' },
	{
		text: 'TRANSF.C/C INST. NÀO BANC. P/ CTA LIQUID',
		value: 'TRANSF.C/C INST. NÀO BANC. P/ CTA LIQUID',
	},
	{
		text: 'PAGAMENTO DE RESCISÃO CONTRATO TRABALHO',
		value: 'PAGAMENTO DE RESCISÃO CONTRATO TRABALHO',
	},
	{
		text: 'CRÉDITO INVEST. CLIENTE IF CREDITADA',
		value: 'CRÉDITO INVEST. CLIENTE IF CREDITADA',
	},
	{
		text: 'DÉBITO INVEST. CLIENTE IF CREDITADA',
		value: 'DÉBITO INVEST. CLIENTE IF CREDITADA',
	},
	{
		text: 'PAG. DE OPERAÇÕES DE CRÉDITO POR CLIENTE',
		value: 'PAG. DE OPERAÇÕES DE CRÉDITO POR CLIENTE',
	},
];

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Intervalo de datas',
		filter: 'date_interval',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Descrição',
		filter: 'description',
		value: '',
		type: 'select',
		selectOptions: fundsOutDescriptionOptions,
		selected: false,
	},
	{
		name: 'Status',
		filter: 'status',
		value: '',
		type: 'select',
		selectOptions: [
			{ text: 'Pago', value: 'paid' },
			{ text: 'Processando', value: 'processing' },
			{ text: 'Não concluído', value: 'error' },
			{ text: 'Cancelado', value: 'cancelled' },
			{ text: 'Pendente', value: 'pending' },
			{ text: 'Agendado', value: 'scheduled' },
		],
		selected: false,
	},
	{
		name: 'Empresa',
		filter: 'company_id',
		value: '',
		type: 'company_id',
		selected: false,
	},
	{
		name: 'Nome do beneficiário',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Tipo de beneficiário',
		filter: 'type',
		value: '',
		type: 'select',
		selectOptions: [
			{ text: 'PJ', value: 'pj' },
			{ text: 'PF', value: 'pf' },
		],
		selected: false,
	},
	{
		name: 'Documento do beneficiário',
		filter: 'document',
		value: '',
		type: 'document',
		selected: false,
	},
	{
		name: 'Operador',
		filter: 'operator',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useFundsOutStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useFundsOutStore };
