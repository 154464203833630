import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import {
	fetchCardBatch,
	getCardsStats,
	reviewCardBatch,
} from '../../../../services/queries/CardsBatchs';
import {
	getStatusIcon,
	parseCardStatusString,
	parseStatusString,
} from '../../../../utils/parseCardBatchStatus';
import * as TableStyles from '../../../../components/Table/TableStyles';
import * as S from './styles';
import UserCard from '../../../../components/UserCard';
import { AddressModal } from './AddressModal';
import CompanyCard from '../../../../components/CompanyCard';
import * as XLSX from 'xlsx';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { TrackingModal } from '../../../../components/TrackingModal';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { Option } from '../../../../components/Form/FormStyles';
import { parseDeliveryAddressType } from '../utils/parseCardBatchInfo';
import { FaExclamationCircle } from 'react-icons/fa';

type CardBatchSpreadsheet = {
	order_id: string;
	external_id: string;
	name: string;
	street: string;
	number: string;
	complement: string;
	reference: string;
	neighborhood: string;
	city: string;
	state: string;
	postal_code: string;
	phone_number: string;
	email: string;
	amount_of_cards: number;
};

export function CardBatchDetails() {
	const { id } = useParams();
	const navigate = useNavigate();
	const { openOptionsDialog } = useDialogModal();

	const fetchCardBatchQuery = useQuery(
		['fetchCardBatch', id],
		() => fetchCardBatch(id!),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar a solicitação de envio de remessa de cartões. '
				);
			},
		}
	);

	const fetchCardStatsQuery = useQuery('card-stats', getCardsStats, {
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível buscar o quantitativo de cartões. '
			);
		},
	});

	const reviewCardBatchQuery = useMutation(
		(review: 'canceled' | 'processing' | 'reproved') => {
			return reviewCardBatch(id!, review);
		},
		{
			onSuccess: (data) => {
				if (data.status === 'processing') {
					toast.info('Remessa de cartões aprovada!');
				} else if (data.status === 'reproved') {
					toast.info('Remessa de cartões reprovada!');
				}
				navigate('/cards');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar revisar a remessa de cartões. '
				);
			},
		}
	);

	function handleReproveBatch() {
		openOptionsDialog(
			'Tem certeza que deseja reprovar a solicitação de envio?',
			'Confirmar',
			() => reviewCardBatchQuery.mutate('reproved'),
			'Cancelar',
			() => {}
		);
	}

	const checkAvailableCardsQuantity = () => {
		// fetched data is needed to check
		if (!fetchCardBatchQuery.data || !fetchCardStatsQuery.data) return false;

		return (
			fetchCardBatchQuery.data.card_qtde <=
			fetchCardStatsQuery.data.bounty_card_stats.quantity_embossed
		);
	};

	function checkCardBatchAddress() {
		const cardBatch = fetchCardBatchQuery.data!;
		let isValid = true;

		if (cardBatch.delivery_address_type === 'collaborators') {
			cardBatch.orders.forEach((order) => {
				const address = order.collaborator.address!;

				if (address.reference && address.reference.length > 30) {
					toast.error(
						`O campo "Referência" do colaborador ${order.collaborator.first_name} ${order.collaborator.last_name} possui mais de 30 caracteres.`
					);
					isValid = false;
					return false;
				}
			});
		} else {
			const address = cardBatch.batch_address!;

			if (address.reference && address.reference.length > 30) {
				toast.error(
					`O campo de "Referência" da entrega possui mais de 30 caracteres.`
				);
				return false;
			}
		}

		return isValid;
	}

	function handleApproveCardBatch() {
		if (!checkCardBatchAddress()) {
			return;
		}

		if (!checkAvailableCardsQuantity()) {
			toast.error(
				'A quantidade de cartões para envio na remessa é maior que a quantidade de cartões prontos disponíveis.'
			);
			return;
		}

		openOptionsDialog(
			'Tem certeza que deseja abrir processamento da remessa de cartões?',
			'Confirmar',
			() => reviewCardBatchQuery.mutate('processing'),
			'Cancelar',
			() => {},
			true
		);
	}

	function generateSpreadsheet() {
		const cardBatch = fetchCardBatchQuery.data!;

		const jsonSpreadsheet: CardBatchSpreadsheet[] = [];

		if (cardBatch.delivery_address_type === 'collaborators') {
			cardBatch.orders.forEach((order) => {
				const address = cardBatch.batch_address ?? order.collaborator.address!;

				jsonSpreadsheet.push({
					order_id: order.order_id,
					external_id: '',
					name: `${order.collaborator.first_name} ${order.collaborator.last_name}`,
					street: address.address,
					number: address.number,
					complement: address.complement ?? '',
					reference: address.reference ?? '',
					neighborhood: address.district,
					city: address.city,
					state: address.uf,
					postal_code: address.cep,
					phone_number: order.collaborator.phone,
					email: order.collaborator.email,
					amount_of_cards: 1,
				});
			});
		} else {
			// custom or company
			const address = cardBatch.batch_address!;

			jsonSpreadsheet.push({
				order_id: cardBatch.order_id,
				external_id: '',
				name: cardBatch.company.name,
				street: address.address,
				number: address.number,
				complement: address.complement ?? '',
				reference: address.reference ?? '',
				neighborhood: address.district,
				city: address.city,
				state: address.uf,
				postal_code: address.cep,
				phone_number: cardBatch.company.phone,
				email: cardBatch.company.email,
				amount_of_cards: cardBatch.card_qtde,
			});
		}

		const cardBatchSheet = XLSX.utils.json_to_sheet(jsonSpreadsheet);
		const cardBatchWorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			cardBatchWorkBook,
			cardBatchSheet,
			'Remessa Cartões'
		);

		XLSX.writeFile(
			cardBatchWorkBook,
			`lote_cartoes_${cardBatch.order_id}.xlsx`
		);
	}

	function getCollaboratorName(firstName: string, lastName: string) {
		return `${firstName} ${lastName}`;
	}

	if (!fetchCardBatchQuery.data || reviewCardBatchQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do envio solicitado' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhes do envio solicitado' />
				{getStatusIcon(fetchCardBatchQuery.data.status)}
				{parseStatusString(fetchCardBatchQuery.data.status)}
				{fetchCardBatchQuery.data.max_qtde_exceeded && (
					<>
						<FaExclamationCircle color='#FF5900' />
						Limite de cartões por pedido excedido
					</>
				)}
			</S.Header>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>CÓDIGO DA SOLICITAÇÃO</S.InfoTitle>
					{fetchCardBatchQuery.data.order_id}
				</S.InfoContainer>
				{fetchCardBatchQuery.data.delivery_address_type !== 'collaborators' && (
					<S.InfoContainer>
						<S.InfoTitle>ID DA SOLICITAÇÃO</S.InfoTitle>
						{fetchCardBatchQuery.data.request_id !== null
							? fetchCardBatchQuery.data.request_id
							: '-'}
					</S.InfoContainer>
				)}
				<S.InfoContainer>
					<S.InfoTitle>DATA DA SOLICITAÇÃO</S.InfoTitle>
					{new Date(fetchCardBatchQuery.data.created_at).toLocaleDateString()}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>QNTD DE CARTÕES</S.InfoTitle>
					{fetchCardBatchQuery.data.card_qtde}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>ENDEREÇO DE ENTREGA</S.InfoTitle>
					{parseDeliveryAddressType(
						fetchCardBatchQuery.data.delivery_address_type
					)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Empresa</S.InfoTitle>
					<CompanyCard
						company_name={fetchCardBatchQuery.data.company.name}
						cnpj={fetchCardBatchQuery.data.company.cnpj}
						avatar_url={undefined}
					/>
				</S.InfoContainer>
				{fetchCardBatchQuery.data.delivery_address_type !== 'collaborators' && (
					<>
						<S.InfoContainer>
							<S.InfoTitle>Destino</S.InfoTitle>
							<AddressModal address={fetchCardBatchQuery.data.batch_address!} />
						</S.InfoContainer>
						<S.InfoContainer>
							<S.InfoTitle>Tracking</S.InfoTitle>
							<TrackingModal
								cardBatchId={fetchCardBatchQuery.data.id}
								orderId={fetchCardBatchQuery.data.order_id}
								companyId={fetchCardBatchQuery.data.company.id}
								trackLink={fetchCardBatchQuery.data.track_link!}
							/>
						</S.InfoContainer>
					</>
				)}
			</S.InfoTop>

			{fetchCardBatchQuery.data.delivery_address_type === 'collaborators' && (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								DESTINATÁRIO
							</TableStyles.TableHeaderCell>

							<TableStyles.TableHeaderCell>DESTINO</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								TRACKING
							</TableStyles.TableHeaderCell>

							<TableStyles.TableHeaderCell>CÓDIGO</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								ID DA SOLICITAÇÃO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>STATUS</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchCardBatchQuery.data.orders.map((order) => (
							<TableStyles.TableRow key={order.id}>
								<TableStyles.TableData>
									<UserCard
										name={
											getCollaboratorName(
												order.collaborator.first_name!,
												order.collaborator.last_name!
											).length > 25
												? getCollaboratorName(
														order.collaborator.first_name!,
														order.collaborator.last_name!
												  ).substring(0, 24) + '...'
												: getCollaboratorName(
														order.collaborator.first_name!,
														order.collaborator.last_name!
												  )
										}
										dataRhName={getCollaboratorName(
											order.collaborator.first_name!,
											order.collaborator.last_name!
										)}
										dataRhOffice={
											order.collaborator.email!.length > 22
												? order.collaborator.email!.slice(0, 21)
												: ''
										}
										office={order.collaborator.email!}
										avatar_url={order.collaborator.avatar_url}
									/>
								</TableStyles.TableData>

								<TableStyles.TableData>
									<AddressModal
										address={order.collaborator.address!}
										name={`${order.collaborator.first_name} ${order.collaborator.last_name}`}
									/>
								</TableStyles.TableData>

								<TableStyles.TableData>
									<TrackingModal
										cardBatchId={fetchCardBatchQuery.data.id}
										cardBatchItemId={order.id}
										orderId={order.order_id}
										trackLink={order.track_link}
									/>
								</TableStyles.TableData>

								<TableStyles.TableData>{order.order_id}</TableStyles.TableData>

								<TableStyles.TableData>
									{order.request_id !== null ? order.request_id : '-'}
								</TableStyles.TableData>

								<TableStyles.TableData>
									<TableStyles.StatusContainer>
										{getStatusIcon(
											order.status,
											order.delivered_extra_info === 'with_error'
										)}
										<span>
											{parseCardStatusString(
												order.status,
												order.delivered_extra_info === 'with_error'
											)}
										</span>
									</TableStyles.StatusContainer>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			)}
			<S.FooterOptions>
				<Option onClick={generateSpreadsheet}>Gerar planilha</Option>
				<div>
					{fetchCardBatchQuery.data.status === 'pending' && (
						<S.SecondaryBtn onClick={handleReproveBatch}>
							Reprovar
						</S.SecondaryBtn>
					)}
					{fetchCardBatchQuery.data.status === 'pending' && (
						<S.MainBtn onClick={handleApproveCardBatch}>Aprovar</S.MainBtn>
					)}
				</div>
			</S.FooterOptions>
		</S.Container>
	);
}
